import { StyledBreadcrumb, StyledSubtitle, StyledTitle } from "../TraininerForm.style";

const FormStepHeader = () => {
    return (
        <StyledBreadcrumb>
            <StyledTitle>Become an ItGalaxy IT Producer</StyledTitle>
            <StyledSubtitle>Join a community of ItGalaxy Producer</StyledSubtitle>
        </StyledBreadcrumb>
    );
};

export default FormStepHeader; 
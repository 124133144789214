import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  max-width: 600px; // Define a max width for the form
  margin: 0 auto; // Center the form
  padding: 30px;
  background-color: #ffffff; // Background color for the form
  border-radius: 8px; // Rounded corners
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Subtle shadow
`;

export const TitleForm = styled.h2`
  font-size: 28px;
  padding-left: 450px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;

  hr {
    margin: 10px auto;
    width: 50%; // Width of the divider
    height: 2px;
    background-color: #007bff; // Color for the divider
    border: none;
    border-radius: 5px;
  }
`;

export const TitleStyle = styled.div`
display:flex;
padding-top: 20px;
`;



export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; // Center the button horizontally
  align-items: center; // Center the button vertically if needed

 `;

export const ButtonSubmit = styled.button`
  background-color: black;
  color: white;
  height: 50px;
  width: 300px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05); // Slightly increase size on hover
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Focus outline effect
  }

  &:disabled {
    background-color: #ccc; // Color for disabled state
    cursor: not-allowed; // Not-allowed cursor for disabled button
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
  margin-left:10px;
  margin-right:3px;
  background-color: #f8f9fa; // Light background for input containers
  padding: 15px;
  border-radius: 8px; // Rounded corners for input containers
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); // Light shadow effect
  width : 100%;
  `;

export const InputLabel = styled.label`
  display: block;
  font-size: 16px; // Font size for labels
  font-weight: bold;
  color: #555; // Label color
  margin-bottom: 8px; // Space below label
`;

export const GenericInputField = styled.input`
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    text-align: left; // Align text to the left
    direction: ltr; // Ensure LTR direction
    transition: border-color 0.3s ease;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        outline: none;
    }
`;

export const LocationStyle = styled.div`
 display: flex;
`;

export const LocationStyleModal = styled.div`
 display: flex;
 margin-left: 600px;
`;

export const TextStyleModal = styled.div`
  font-size: 25px; /* Font size for the message */
  font-family: Inter;
  margin-bottom: 20px; /* Space below the text */
`;




export const ButtonSubmitStyle = styled.button`
  background-color: black;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-left: 30px;
  &:hover {
    transform: scale(1.05); // Slightly increase size on hover
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Focus outline effect
  }
  &:disabled {
    background-color: #ccc; // Color for disabled state
    cursor: not-allowed; // Not-allowed cursor for disabled button
  }`;

export const customStylesSearchModal = {
  option: (provided, state) => ({
    ...provided,
    height: '50px',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DCDCDC',
    color: 'black',
    fontFamily: 'Bold',
    fontSize: '15px',
    margin: '4px 4px ',
    cursor: 'pointer',
    ':active': {
      backgroundColor: '#14171F',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: '50px',
    overflowY: 'scroll',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'white',
    fontFamily: 'Inter',
    grap: '11px',
    fontSize: '15px',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    width: '200px',
    height: '30px',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#14171F',
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '15px',
    margin: '4px 0',
    cursor: 'pointer',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'white',
    fontWeight: 'bold',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    width: '150px',
    height: '30px',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#14171F',
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '15px',
    margin: '4px 0',
    cursor: 'pointer'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    alignItems: 'center',
    overflowY: 'scroll',
    borderRadius: '14px',
    maxHeight: '200px',
  }),
};
export const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
`;

export const ButtonSubmitDisable = styled.button`
  background-color: gray;
  color: black;
  height: 50px;
  width: 300px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05); // Slightly increase size on hover
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Focus outline effect
  }

  &:disabled {
    background-color: #ccc; // Color for disabled state
    cursor: not-allowed; // Not-allowed cursor for disabled button
  }
`;

export const ContainerStyleCompose = styled.div`
  width: 100%;
  height: 100vh;


`
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import { CDBSidebarMenuItem } from "cdbreact";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import chatBotIcon from "../../assets/images/chatbox.png";
import { useJoinusContext } from "../allContext/joinusContext";
import Register from "../Authentification/modals/register";
import { ChooseOptionChat } from "../ChooseOptionChat/ChooseOptionChat";
import { GenericFormChatbot } from "../GenericFormChatbot/GenericFormChatbot";
import Header from "../Header/Header";
import {
  ButtonGroupsContainer,
  ButtonGroupsContainerHome,
  FlexCenteredContainer,
  FontSize20PxContainer,
  FontSize20PxGrayContainer,
  FontSize25Px495057Color,
  FontSize30PXContainer,
  FontSize40PxContainer,
  InlineBlockContainer,
  InlineBlockW45PContainer,
  InlineFlexContainer,
  MarginBottom20PxContainer,
  Padding10PxContainer,
  ProjectTypeCardsContainer,
  SelectedItemContainer,
  UserTypeCardsContainer,
  styleJoin
} from "./JoinUs.style";

export const JoinUs = (props) => {

  const handleModalRegister = () => {
    window.location.href = `/about-us`;
  }

  const [selected, setSelected] = useState(false);
  const [type, setType] = useState("Business");
  const [selectedOption, setSelectedOption] = useState("Business");
  const { redirect } = useJoinusContext();


  const renderContentChat = () => {
    switch (selectedOption) {

      // ENTREPRISE
      case "Compose your Team":
        return (
          <GenericFormChatbot
            items={[
              {
                img: chatBotIcon,
                title:
                  "I guide you through the steps of finding the best profiles for creating your team to realize your business",
                question:
                  "Let's start with the title of your project. What is it?",
                name: "description",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Interesting, now describe your project in detail",
                question:
                  "What are you trying to achieve? What are the objectives of this project?",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Great! Now let's move on to the specific skills and expertise you need to complete this project",
                question:
                  "To achieve the best results, it is recommended to list 3 to 5 key skills",
                name: "skills",
              },
            ]}
            selected={selected}
            setSelected={setSelected}
            selectedOption={selectedOption}
          />
        );
      case "Share a Jobs":
        return (
          <GenericFormChatbot
            items={[
              {
                img: chatBotIcon,
                title:
                  "I guide you through the steps of finding the best profiles for creating your team to realize your business",
                question:
                  "Let's start with the title of your project. What is it?",
                name: "description",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Interesting, now describe your project in detail",
                question:
                  "What are you trying to achieve? What are the objectives of this project ?",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Great! Now let's move on to the specific skills and expertise you need to complete this project",
                question:
                  "To achieve the best results, it is recommended to list 3 to 5 key skills",
                name: "skills",
              },
            ]}
            selected={selected}
            setSelected={setSelected}
            selectedOption={selectedOption}

          />);
      case "Share your project":
        return (
          <GenericFormChatbot
            items={[
              {
                img: chatBotIcon,
                title:
                  "I guide you through the steps of finding the best profiles for creating your team to realize your business",
                question:
                  "Let's start with the title of your project. What is it?",
                name: "description",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Interesting, now describe your project in detail",
                question:
                  "What are you trying to achieve? What are the objectives of this project?",
                placeholder: "",
              },
              {
                img: chatBotIcon,
                title:
                  "Great! Now let's move on to the specific skills and expertise you need to complete this project",
                question:
                  "To achieve the best results, it is recommended to list 3 to 5 key skills",
                name: "skills",
              },
            ]}
            selected={selected}
            setSelected={setSelected}
            selectedOption={selectedOption}

          />);

      case "IT Marketplace":
      case "Cloud Marketplace":
      case "Onlines videos training":
        return (
          <Register
            openModalRegister={true}
            setOpenModalRegister={true}
            openModalLoginDefault={false}
            handleModalRegister={handleModalRegister}
            switchBetweenModals={true}
            proxy={"products"}
            freelance={false}
            recruiter={true}
            buisness={true}
            defaultkey={"buisness"}
          />);
      case "Apply for a jobs":
        return (
          <Register
            openModalRegister={true}
            setOpenModalRegister={true}
            openModalLoginDefault={false}
            handleModalRegister={handleModalRegister}
            switchBetweenModals={true}
            proxy={"projects"}
            freelance={false}
            recruiter={false}
            buisness={true}
            defaultkey={"buisness"}
          />);

      case "Create profile with IA":
        return (
          <Register
            openModalRegister={true}
            setOpenModalRegister={true}
            openModalLoginDefault={false}
            handleModalRegister={handleModalRegister}
            switchBetweenModals={true}
            proxy={"profilCandidat"}
            freelance={true}
            recruiter={false}
            buisness={false}
            defaultkey={"freelance"}
          />);

      case "Share your videos training":
      case "Share some services":
        return (
          <Register
            openModalRegister={true}
            setOpenModalRegister={true}
            openModalLoginDefault={false}
            handleModalRegister={handleModalRegister}
            switchBetweenModals={true}
            proxy={"shareproduct"}
            freelance={true}
            recruiter={false}
            buisness={false}
            defaultkey={"freelance"}
          />);

      case "Create your own company":
        return (
          <Register
            openModalRegister={true}
            setOpenModalRegister={true}
            openModalLoginDefault={false}
            handleModalRegister={handleModalRegister}
            switchBetweenModals={true}
            proxy={"shareproduct"}
            freelance={true}
            recruiter={true}
            buisness={false}
            defaultkey={"freelance"}
          />);
    }
  };
  const typeTitle = () => {
    if (type && !selected) {
      switch (type) {
        case "Business":
          return (
            <Container style={{ marginTop: "30px" }}>
              <FontSize40PxContainer>BUSINESS</FontSize40PxContainer>
              <FontSize25Px495057Color>
                Large Talent Pool: Access a broad pool od skilled freelancers
                across various diciplines.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Find the Perfect Fit: Easy search & filter tools and detailed
                freelancer profiles & reviews help you find the right match.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Streamline Your Workflow: Utilise secure communication,project
                management tools, and transparent pricing options. Some
                platforms offer talent verification for added assurance .
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Customise Your Project: Tailor your project postings,
                commnication preferences, and payment methods for you specific
                needs.
              </FontSize25Px495057Color>
            </Container>
          );
        case "Individual":
          return (
            <Container style={{ marginTop: "30px" }}>
              <FontSize40PxContainer>
                INDIVIDUALS
              </FontSize40PxContainer>
              <FontSize25Px495057Color>
                Easy Sign-Up, High-Quality Projects: Join a platform with a
                wealth of legitimate projects to match your skills. Set
                competitive rates, get secure payments, and enjoy fair dispute
                resolution.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Build Your Reputation: Showcase your talents with a strong
                profile, portfolio, and client reviews. Find a supportive
                community for networking and collaboration.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Flexible Work, Your Way: Enjoy diverse project types and lengths
                to fit your schedule and preferences.
              </FontSize25Px495057Color>
              <br />
            </Container>
          );
        case "Recruiter":
          return (
            <Container style={{ marginTop: "30px" }}>
              <FontSize40PxContainer>
                Recruiter
              </FontSize40PxContainer>
              <FontSize25Px495057Color>
                Source Top Talent: Find the perfect fit with our vast pool of
                pre-vetted candidates across all industries.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Seamless Hiring: Streamline your process with our powerful
                search & filtering tools and manage applications efficiently.
              </FontSize25Px495057Color>
              <br />
              <FontSize25Px495057Color>
                Expert Guidance: Get access to industry insights and. leverage
                our expertise to make informed hiring decisions.
              </FontSize25Px495057Color>
              <br />
            </Container>
          );
      }
    } else {
      switch (selectedOption) {
        case "Compose your Team":
          return (
            <>
              <FontSize40PxContainer>
                COMPOSE YOUR TEAM :
              </FontSize40PxContainer>
              <FontSize25Px495057Color>
                Creating a high-performance team is essential to achieving your
                goals. By selecting the right people, you can
                maximize skills, foster collaboration and
                guarantee optimal efficiency. Choose members to
                complementary talents, defining clear roles and
                establish open communication to build a team
                dynamic and united. Together you can overcome challenges
                and celebrate successes.
              </FontSize25Px495057Color>
            </>
          );
        case "Share your project":
          return (
            <>

              <FontSize40PxContainer>
                On Demand Project
              </FontSize40PxContainer>
              <FontSize25Px495057Color>
                Creating a high-performance team is essential to achieving your
                goals. By selecting the right people, you can
                maximize skills, foster collaboration and
                guarantee optimal efficiency. Choose members to
                complementary talents, defining clear roles and
                establish open communication to build a team
                dynamic and united. Together you can overcome challenges
                and celebrate successes.
              </FontSize25Px495057Color>
            </>

          );
        case "IT Marketplace":
          return (
            <>
              <FontSize40PxContainer>
                Vous êtes dans la rubrique IT Marketplace
              </FontSize40PxContainer>
              <FontSize25Px495057Color>
                Creating a high-performance team is essential to achieving your
                goals. By selecting the right people, you can
                maximize skills, foster collaboration and
                guarantee optimal efficiency. Choose members to
                complementary talents, defining clear roles and
                establish open communication to build a team
                dynamic and united. Together you can overcome challenges
                and celebrate successes.
              </FontSize25Px495057Color>
            </>

          );
      }
    }
  };
  const getContentCards = () => {
    switch (type) {
      case "Business":
        return (
          <InlineBlockContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Set up your Business"}
                items={[
                  "Compose your Team",
                  "Share your project",
                ]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Marketplace : Services ready to be integrate"}
                header2={""}
                items={[
                  "IT Marketplace",
                ]}
              />
            </ProjectTypeCardsContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Cloud ItGalaxy"}
                header2={""}
                items={["Cloud Marketplace"]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Trainings"}
                header2={""}
                items={[
                  "Videos training",
                ]}
              />
            </ProjectTypeCardsContainer>
          </InlineBlockContainer>
        );
      case "Individual":
        return (
          <InlineBlockContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Find a Jobs "}
                header2={""}
                items={[
                  "Create profile with IA",
                  "Apply for a jobs",
                ]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Create your own business on ItGalaxy"}
                header2={""}
                items={[
                  "Share your videos training",
                  "Share some services",
                ]}
              />
            </ProjectTypeCardsContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Marketplace : Cloud,tools and trainings product"}
                header2={""}
                items={[
                  "Services ready to be integrate ",
                  "Videos training",
                  "Cloud Marketplace",
                ]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Create your own company wherever you are"}
                header2={""}
                items={["Create your own company"]}
              />
            </ProjectTypeCardsContainer>
          </InlineBlockContainer>
        );
      // case "Recruiter":
      case "Recruiter":
        return (
          <InlineBlockContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Jobs"}
                items={["Share a Jobs", "Apply for a jobs"]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"ItGalaxy Contractors"}
                items={[
                  "ItGalaxy Nav",
                ]}
              />
            </ProjectTypeCardsContainer>
            <ProjectTypeCardsContainer>
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Become a recruiter for the ItGalaxy platform"}
                items={["Get your own link for recruitment"]}
              />
              <ChooseOptionChat
                selected={selected}
                setSelected={setSelected}
                setSelectedOption={setSelectedOption}
                header1={"Create your own company wherever you are"}
                header2={""}
                items={["Create your own company"]}
              />
            </ProjectTypeCardsContainer>
          </InlineBlockContainer>
        );
    }
  };
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    icon: "fa fa-check fa-4x",
    label: "Business",
    description:
      "Tap into a large pool of skilled and experienced freelancers across various diciplines",
  });
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {redirect || (
        <>
          <Header />
          <FlexCenteredContainer>
            <InlineFlexContainer style={{ marginTop: "5%" }}>
              <InlineBlockW45PContainer>
                <MarginBottom20PxContainer>
                  <FontSize30PXContainer>
                    What are you looking to do
                  </FontSize30PXContainer>
                  <br />
                  <FontSize20PxGrayContainer>
                    Choose your account type
                  </FontSize20PxGrayContainer>
                </MarginBottom20PxContainer>
                <UserTypeCardsContainer>
                  {props.items?.map((item) => (
                    <SelectedItemContainer
                      key={item?.id}
                      isSelected={selectedItem?.id === item?.id}
                      onClick={() => {
                        item.label === "Business"
                          ? setType("Business")
                          : item.label === "Individual"
                            ? setType("Individual")
                            : setType("Recruiter");
                        setSelectedItem(item);
                        setSelected(false);

                      }}
                    >
                      {typeof item.icon === "string" &&
                        item.icon.endsWith(".webp") ? (
                        <div>
                          <img
                            src={item.icon}
                            alt={item.label}
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>
                      ) : (
                        <CDBSidebarMenuItem
                          icon={item.icon}
                          iconSize="lg"
                        ></CDBSidebarMenuItem>
                      )}
                      <FontSize30PXContainer>{item.label}</FontSize30PXContainer>
                      {!isTablet && <Padding10PxContainer isSelected={selectedItem?.id === item?.id}>
                        {item.description}
                      </Padding10PxContainer>}
                    </SelectedItemContainer>
                  ))}
                </UserTypeCardsContainer>
                <hr style={{ borderTop: "2px solid", opacity: "1" }} />
                {!isTablet && typeTitle()}
              </InlineBlockW45PContainer>
              {!selected ? (
                <ButtonGroupsContainerHome>
                  <InlineFlexContainer>
                    <FontSize40PxContainer>{props.title}</FontSize40PxContainer>
                    <CDBSidebarMenuItem icon="fa-hands-clapping"></CDBSidebarMenuItem>
                  </InlineFlexContainer>
                  <FontSize20PxContainer>{props.description}</FontSize20PxContainer>
                  {getContentCards()}
                </ButtonGroupsContainerHome>
              ) : (
                <ButtonGroupsContainer>
                  {selected && renderContentChat()}
                </ButtonGroupsContainer>
              )}
            </InlineFlexContainer>
          </FlexCenteredContainer>
        </>)}
    </>
  );
}

import ItGalaxyServerProduct from "./ItGalaxyServerProduct/ItGalaxyServerProduct";
import ItGalaxyDatabaseProduct from "./ItGalaxyDatabaseProduct/ItGalaxyDatabaseProduct";
import { jwtDecode } from "jwt-decode";
import SideBar from "components/SideBar/sideBar";
import { getTokenFromLocalStorage } from "core/helpers/storage";
import { useGetMarketplaceItgalaxyByTokenQuery } from "../../../../redux/api/marketplaceItgalaxy/marketplaceItgalaxyApi";
import { Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { MessageContainer } from "./Style"; // Assuming MessageContainer is a styled component

const ItGalaxyProduct = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tokenProduct = queryParams.get("token");
  const userToken = getTokenFromLocalStorage();
  const decodeUserToken = userToken ? jwtDecode(userToken) : null;
  const id = decodeUserToken ? decodeUserToken.id : null;
  const role = decodeUserToken ? decodeUserToken.role : null;

  const {
    data: itgalaxyproducts,
    error,
    isLoading,
    refetch
  } = useGetMarketplaceItgalaxyByTokenQuery({ tokenProduct, id, role });

  return (
    <div
      style={{
        display: "flex",
        overflowY: "hidden",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <SideBar
        path={"/marketplace"}
        isLoggedIn={true}
        role={role}
        id={id}
        style={{ overflowY: "hidden" }}
      />
      
      {/* Loading State */}
      {isLoading && (
        <MessageContainer>
          <Spinner animation="border" role="status" style={{ marginBottom: "10px" }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <span>Loading...</span>
        </MessageContainer>
      )}

      {/* Error State */}
      {error && (
        <MessageContainer>
          <span>Error loading product data. Please try again later.</span>
        </MessageContainer>
      )}

    {/* No Product Data Available */}
    {!isLoading && !error && (!itgalaxyproducts || !itgalaxyproducts.data) && (
        <MessageContainer>
          <span>No product data available.</span>
        </MessageContainer>
      )}



      {itgalaxyproducts && itgalaxyproducts.data && itgalaxyproducts.data.productType === "SERVER" && (
        <ItGalaxyServerProduct data={itgalaxyproducts.data} />
      )}

      {itgalaxyproducts && itgalaxyproducts.data && itgalaxyproducts.data.productType === "DATABASE" && (
        <ItGalaxyDatabaseProduct data={itgalaxyproducts.data} refetch={refetch} />
      )}
    </div>
  );
};

export default ItGalaxyProduct;
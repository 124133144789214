export const RootRoute = "/";
export const DashboardRoute = "dashboard";
export const CompanyRoute = "company";
export const FreelancerRoute = "freelancer";
export const TrainingRoute = "training";

export const DashboardCompanyRoutes = {
  jobopenings: "jobopenings",
  projects: "projects",
  profil: "profil",

  chat: "dashboardCompany",
  profils: "profils",
  composeyourteam: "composeyourteam",

  trainingsession: "trainingsession",
  appDetails: "detailsAppEntreprise/:id",
  payments: "payments",
  appspurchased: "appspurchased",
  products: "/products",
  videoTrainingDetails: "/detailsVideoTrainingEntreprise/:id",
  sessionTrainingDetails: "/detailsSessionTrainingEntreprise/:id",
  purchasedservices: "servicespurchased",
  purchasedservicesDetails: "/detailsPurchasedApplicationEntreprise/:id",
  purchasedservicesSessionTrainingDetails:
    "/detailsPurchasedSessionTrainingEntreprise/:id",
  purchasedservicesVideoTrainingDetails:
    "/detailsPurchasedVideoTrainingEntreprise/:id",
  createSessionTrainingRequest: "sessionTraining/create",
  sessionTrainingRequest: "sessionTrainingRequest",
  trainingSessionRequest: "trainingsessionRequests",
  joinus: "about-us",
  profileFreelances: "profilsCandidates",
  company: "company",

};

export const DashboardFreelancerRoutes = {
  projects: "projects",
  jobopenings: "jobopenings",
  profilCandidat: "profilcandidat",
  chat: "dashboardCandidat",
  trainer: "trainer",
  wallet: "/wallet",
  products: "/myproducts",
  myproduct: "/myproduct",
};

export const DashboardRecruterRoutes = {
  chat: "dashboardRecruter",
  projects: "projects",
  itGalaxyContactor: "itgalaxycontactor",
  itGalaxyRecruitment: "itgalaxyrecruitment",
  recruitmentDash: "recruitmentDash",
  shareCandidat: "shareCandidat",
  shareJob: "shareJob",
};

export const DashboardAccountingRoutes = {
  chat: "dashboardAccounting",
  homepageAccounting: "hpAccounting",
  signupAccounting: "signupAccounting",
  myWorkingFolder: "myworkingfolder",
};

export const TrainingRoutes = {
  videosTrainings: "videosTrainings",
  videosTraining: "videosTrainings/:token",
  videosTrainingPurchased: "videosTrainingPurchased/:token",
};

export const ITProducerRoutes = {
  itProducer: "itProducer",
  editMyProduct: "editMyProduct/:token",
};

export const AuthRoutes = {
  login: "/login",
  logout: "/logout",
  signup: "/signup",
  verifyemail: "verify-email",
  resetpassword: "reset-password",
  checkyouremailplz: "checkyouremailplz",
  dashboard: "dashboard",
  UserAgreement: "useragreement",
  PrivacyPolicy: "privacypolicy",
  TermsOfService: "termsofservice",
  ChangePassword: "changepassword",

  signup_freelancer: "/signup/freelancer",
  signup_company: "/signup/company",
  forget_password: "/forget-password",
  composeyourteam: "/composeyourteam",
  sharetask: "/sharetask",
  appeloffre: "/appeloffre",
  shareproduct: "/shareproduct",
};

export const ItGalaxyMarketplaceRoutes = {
  marketplace: "marketplace",
  itgalaxyProductDetails: "itgalaxyProductDetails/:token",
  itgalaxyProductPurchased: "itgalaxyProductPurchased/:token",
  productPurchased: "productPurchased/:token",
  server: "createProductServer",
  database: "createProductDatabase",
  github: "createProductGithub"
};

export const ErrorRoutes = {
  page404: "/404",
  page500: "/500",
  error: "/*",
};

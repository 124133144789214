import styled from 'styled-components';
import CoursePlaceholder from '../../assets/course-image-placeholder.jpg';
import CoursePreviewCard from '../CoursePreviewCard/CoursePreviewCard';
import { ReactComponent as ArrowUpRight } from "../../assets/IconITgalaxy/akar-icons-arrow-up-right.svg";
import ImageProfilCard from 'components/ImageProfilCard/ImageProfilCard';
import { useEffect, useState } from 'react';
import { getURL } from '../../redux/api/uploads/uploadSlice';
import { useDispatch } from "react-redux";
import ModalPayment from "components/ModalITgalaxy/ModalPayment/ModalPayment";
import useHandleSubmitSoldedProductCreation from "../../hooks/useHandleSubmitSoldedProductCreation";
import { getTokenFromLocalStorage } from 'core/helpers/storage';
import { jwtDecode } from 'jwt-decode';

const Container = styled.div`
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
`;

const CourseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
`;

const CourseInfo = styled.div`
width: 55%;
`;

const CourseTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #222;
`;

const University = styled.p`
  font-size: 14px;
  color: #009FF5;
  margin: 5px 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
`;


const InstructorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const InstructorAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
`;

const EnrollButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight : 501;
  cursor: pointer;
`;

const Price = styled.p`
  font-size: 30px;
  font-weight: bold;
  color: #009FF5;
  border-radius: 5px;
  margin: 0;
`;

const EnrollContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

const UserNameStyle = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: gray;
  padding-left: 10px;
  border-radius: 5px;
  margin: 0;
`;

const CourseIntro = (props) => {
  const [imageUrl ,setImageUrl ] = useState();
  const dispatch = useDispatch();
  const [showModalPayment, setShowModalPayment] = useState(false);
  const token = getTokenFromLocalStorage();
  const decodeToken = token ? jwtDecode(token) : null;
  const buyerId = decodeToken ? decodeToken.id : null;
  const buyerType = decodeToken ? decodeToken.role : null;

  const [formData, setFormData] = useState({
    productId: props.data.id,
    buyerId: buyerId,
    buyerType: buyerType,
  });


  let { handleSubmit, loading } = useHandleSubmitSoldedProductCreation(
    formData,
  );

  const handleClose = () => {
    setShowModalPayment(false);
  };

  // Handling form submission
  const handelShowModalPayment = () => {
    setShowModalPayment(true); // Show confirmation modal
  };


  useEffect(() => {
    let path ;
    const fetchImageUrl = async () => {
      try {
        if(props?.data?.type ==="APPLICATION"){
          path = `products/applications/${props?.data?.id}/image/`;

        } else {
          path = `products/videos_trainings/${props?.data?.id}/image/`;
        }
        const url = await dispatch(
          getURL({
            location: `${path}`,
          })
        );
        console.log("url", url);
        if (url.Contents && url.Contents.length > 0 && url.Contents[0].Key) {
          setImageUrl(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
        } 
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    };

    fetchImageUrl();
  }, [dispatch]);

  return (
    <Container>
      <CourseHeader>
        <CourseInfo>
          <CourseTitle>{props?.data?.title}</CourseTitle>
          <InstructorInfo>
          <ImageProfilCard
                        type={"candidats"}
                        id={props?.data?.userId}
                        typeimg={"cercel"}
                      />
            <UserNameStyle>{props?.data?.user?.name}{" "}{props?.data?.user?.first_name} </UserNameStyle>
          </InstructorInfo>
          <EnrollContainer><EnrollButton onClick={handelShowModalPayment}  >Purchased the course <ArrowUpRight /></EnrollButton><Price>{props?.data?.price} euro</Price></EnrollContainer>
        </CourseInfo>
        <CoursePreviewCard
          imageSrc={imageUrl}
          duration={props?.data?.duration}
        />
      </CourseHeader>

    {showModalPayment && (
      <ModalPayment
        handleCloseShowModal={handleClose}
        showModalPayment={handelShowModalPayment}
        paymentProposal={false}
        price={props.data.price}
        title={"PAYMENT PRODUCT"}
        note={"Note that if problem with the product you can demand a refund"}
        onSubmit={handleSubmit}
      />
    )}
    </Container>

  );
};

export default CourseIntro;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as RightUpArrow } from "../../../../assets/IconITgalaxy/arrow-up-right.svg";
import { getURL } from "../../../../redux/api/uploads/uploadSlice";
import { StackStyle } from "../Formations.style";
import CustomStars from "./CustomStars/CustomStars";
import { minutesToHours } from "./FormationCard.helpers";
import {
  HeadingStyle,
  ImageBoxStyle,
  RootStyle,
  SubHeadingStyle,
  SupportingTextStyle,
  StyledImage
} from "./FormationsCard.style";
import UserAvatar from "./UserAvatar/UserAvatar";

function FormationsCard({ formation, animateDirection, category, onClick }) {
  const {
    id,
    type,
    title,
    description,
    rising_star_global,
    stars,
    price,
    userId,
    skills,
    image,
    user
  } = formation;
  const duration = minutesToHours(formation.duration);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    let path;
    if (type === 'MARKETPLACE') {
      path = "products/marketplace";
    } else if (type === 'APPLICATION') {
      path = "products/applications";
    } else if (type === 'VIDEOSTRAINING') {
      path = "products/videos_trainings/";
    }

    getUrl(path);
  }, []);

  const getUrl = async (path) => {
    const url = await dispatch(
      getURL({
        location: `${path}/${id}/image`,
      })
    );

    if (url.Contents && url.Contents.length > 0 && url.Contents[0].Key) {
      setFileName(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
    } 
  };

  // Shorten description if longer than 20 characters
  const shortenedDescription = description.length > 20 
    ? `${description.substring(0, 20)}...` 
    : description;
  
  // Shorten title if longer than 20 characters
  const shortenedTitle = title.length > 20
    ? `${title.substring(0, 20)}...`
    : title;

  return (
    <RootStyle animateDirection={animateDirection} onClick={() => onClick()}>
      <ImageBoxStyle>
        <StyledImage src={fileName} alt={shortenedTitle} />
      </ImageBoxStyle>
      <StackStyle spacing={2.3}>
        <StackStyle spacing={1.5}>
          <SubHeadingStyle>{category || skills[0]}</SubHeadingStyle>
          <StackStyle direction={"row"} style={{ justifyContent: "space-between" }}>
            <HeadingStyle>{shortenedTitle}</HeadingStyle>
            <RightUpArrow />
          </StackStyle>
          <SupportingTextStyle>{shortenedDescription}</SupportingTextStyle>
        </StackStyle>
        {type === 'MARKETPLACE' ? (
          <CustomStars stars={4.8} starsTotal={rising_star_global} />
        ) : (
          <CustomStars stars={stars} starsTotal={rising_star_global} />
        )}
        <UserAvatar userId={userId} price={price} user={user} type={type} />
      </StackStyle>
    </RootStyle>
  );
}

export default FormationsCard;

import { yupResolver } from "@hookform/resolvers/yup";
import useHandleSubmitTrainingCreation from "hooks/useHandleSubmitTrainingCreation";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { VIDEO_TRAINING_ITEMS } from "../../../../core/constants/trainerForm.constants";
import { renderContentList } from "../../../../core/helpers/trainerForm";
import { clearVideosTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import TrainerNewPaymentForm from "../TrainerNewPaymentForm/TrainerNewPaymentForm";
import FormStepHeader from "./components/FormStepHeader";
import ModalConfirmationTraining from "./components/ModalConfirmationTraining";
import { StepperNavigation } from "./components/StepperNavigation";
import {
  BigContainer,
  StyledContainer,
  StyledFormContainer,
  StyledStepContentCard,
  StyledStepContentColumn,
  StyledStepsFormContainer,
} from "./TraininerForm.style";
import {
  createChapterState,
  formatFormDataForBackend,
  getChapterFromForm,
  updateChapterInForm,
} from "./utils/chapterManager";
import {
  validateChapterFields,
  validateStep,
  validateTrainingType,
} from "./utils/validationManager";
import { validationSchemas } from "./validationSchemas";

const ParcoursForm = ({ type, isEdit }) => {
  // State management
  const [formState, setFormState] = useState({
    confirmShow: false,
    items: VIDEO_TRAINING_ITEMS,
    selectedElement: 1,
    openModalRegister: false,
    token: null,
    currentChapterIndex: null,
    subModules: [], // Track chapter sub-modules
    currentSubModule: null, // Track current sub-module
  });

  // Form setup with validation schema based on current step
  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchemas[formState.selectedElement] || {}),
    defaultValues: {
      type_product: type,
    },
    shouldUnregister: false,
  });

  const { control, getValues, setValue, watch, handleSubmit } = formMethods;

  // Redux and navigation
  const trainingType = useSelector((state) => state.trainingCreation.type);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Use the useFieldArray hook for managing chapters
  const {
    fields: chapterFields,
    append: appendChapter,
    update: updateChapter,
    remove: removeChapter,
  } = useFieldArray({
    control,
    name: "chapters",
  });

  // Training creation handler
  const { handleSubmit: handleTrainingSubmit } =
    useHandleSubmitTrainingCreation(
      formState.selectedElement,
      (val) => setFormState((prev) => ({ ...prev, selectedElement: val })),
      (val) => setFormState((prev) => ({ ...prev, confirmShow: val })),
      formState.openModalRegister,
      (val) => setFormState((prev) => ({ ...prev, openModalRegister: val })),
      isEdit,
      formMethods
    );

  // Handle training payment submission
  const handlePaymentSuccess = async (data) => {
    try {
      const formData = formMethods.getValues();
      console.log("Submitting training:", formData);
      const result = await handleTrainingSubmit(formData);

      if (!result.success) {
        console.error("Training creation failed:", result.invoicingDescription);
        return result;
      }

      // Update UI state
      setFormState((prev) => ({
        ...prev,
        selectedElement: prev.selectedElement + 1,
      }));

      return result;
    } catch (error) {
      console.error("Error during training submission:", error);
      return {
        success: false,
        targetProductType: "TRAINING",
        targetProductId: 0,
        invoicingDescription: "REFUND : error create training",
        type: "TRAINING",
      };
    }
  };

  // Format final data for submission
  const formatFinalData = () => {
    const formData = getValues();
    const chapters = chapterFields.map((chapter, index) => ({
      ...chapter,
      ...getChapterFromForm(getValues, index),
    }));

    return formatFormDataForBackend({
      ...formData,
      chapters,
      userId: 1,
      trainerId: 1,
      trainerType: "CANDIDAT",
    });
  };

  // Modal handlers
  const modalHandlers = {
    handleConfirmationSubmit: async () => {
      const isValid = await formMethods.trigger();
      if (isValid) {
        const formData = formatFinalData();
        console.log("Submitting form:", formData);
        try {
          const response = await handleTrainingSubmit(formData);
          if (response.success) {
            dispatch(clearVideosTraining());
            navigate("/trainer/trainings");
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      } else {
        console.log("Trainer Form Validation failed");
      }
    },
  };

  // Initialize first chapter if none exist
  useEffect(() => {
    if (chapterFields.length === 0) {
      const initialChapter = createChapterState();
      appendChapter(initialChapter);
      setFormState((prev) => ({
        ...prev,
        currentChapterIndex: 0,
      }));
    }
  }, []);

  // Initialize subModules state when chapters change
  useEffect(() => {
    const newSubModules = chapterFields.map((_, index) => ({
      id: `chapter_${index + 1}`,
      index,
      title: `Chapter ${index + 1}`,
    }));

    setFormState((prev) => ({
      ...prev,
      subModules: newSubModules,
    }));
  }, [chapterFields]);

  // Watch chapters data
  const chaptersData = watch("chapters");

  // Handle chapter navigation
  const handleChapterNavigation = (index) => {
    try {
      // Save current chapter data before switching
      if (formState.currentChapterIndex !== null) {
        const currentData = getChapterFromForm(
          getValues,
          formState.currentChapterIndex
        );
        if (currentData) {
          console.log(
            `Saving chapter ${formState.currentChapterIndex + 1} data:`,
            currentData
          );
          updateChapter(formState.currentChapterIndex, { ...currentData });
        }
      }

      // Load data for the requested chapter
      if (index >= chapterFields.length) {
        const newChapter = createChapterState();
        console.log(`Creating new chapter ${index + 1}:`, newChapter);
        appendChapter(newChapter);
      }

      // Update form state
      setFormState((prev) => ({
        ...prev,
        currentChapterIndex: index,
        currentSubModule: {
          id: `chapter_${index + 1}`,
          index,
          title: `Chapter ${index + 1}`,
        },
      }));
    } catch (error) {
      console.error("Error in chapter navigation:", error);
    }
  };

  // Handle chapter data updates
  const handleChapterUpdate = (data) => {
    if (formState.currentChapterIndex !== null) {
      try {
        const currentIndex = formState.currentChapterIndex;
        console.log(`Updating chapter ${currentIndex + 1}:`, data);
        updateChapter(currentIndex, { ...data });
      } catch (error) {
        console.error("Error updating chapter data:", error);
      }
    }
  };

  // Handle chapter confirmation
  const handleChapterConfirm = (chapterData) => {
    try {
      const currentIndex = formState.currentChapterIndex;

      if (currentIndex !== null) {
        // Save the current chapter data
        console.log(`Confirming chapter ${currentIndex + 1}:`, chapterData);
        updateChapter(currentIndex, { ...chapterData });

        // Move to the next chapter
        const nextIndex = currentIndex + 1;
        handleChapterNavigation(nextIndex);
      }
    } catch (error) {
      console.error("Error confirming chapter:", error);
    }
  };

  // Chapter operations
  const handleChapterOperations = {
    addChapter: () => {
      try {
        // Save current chapter data if exists
        if (
          formState.currentChapterIndex !== null &&
          formState.currentChapterIndex >= 0
        ) {
          const currentData = getChapterFromForm(
            getValues,
            formState.currentChapterIndex
          );
          if (currentData) {
            updateChapter(formState.currentChapterIndex, currentData);
          }
        }

        const newIndex = chapterFields.length;
        const newChapter = createChapterState(newIndex);

        // Add new chapter
        appendChapter(newChapter);

        // Update form state
        setFormState((prev) => ({
          ...prev,
          currentChapterIndex: newIndex,
        }));

        // Initialize new chapter data
        updateChapterInForm(setValue, chapterFields, newIndex, newChapter);

        console.log("New chapter added:", { newIndex, newChapter });
      } catch (error) {
        console.error("Error adding chapter:", error);
      }
    },

    confirmChapter: async (addNewChapter = false) => {
      try {
        const currentData = getChapterFromForm(
          getValues,
          formState.currentChapterIndex
        );
        const isValid = await validateChapterFields(
          formMethods,
          formState.currentChapterIndex
        );

        if (isValid) {
          // Save current chapter data
          updateChapter(formState.currentChapterIndex, currentData);

          if (addNewChapter) {
            handleChapterOperations.addChapter();
          } else {
            setFormState((prev) => ({
              ...prev,
              selectedElement: prev.selectedElement + 1,
              currentChapterIndex: null,
              currentSubModule: null,
            }));
          }
        } else {
          console.log("Chapter validation failed");
        }
      } catch (error) {
        console.error("Error confirming chapter:", error);
      }
    },

    loadChapter: (index) => {
      try {
        // Save current chapter data before switching
        if (formState.currentChapterIndex !== null) {
          const currentData = getChapterFromForm(
            getValues,
            formState.currentChapterIndex
          );
          if (currentData) {
            updateChapter(formState.currentChapterIndex, currentData);
          }
        }

        // Load data for the requested chapter
        const chapterData =
          getChapterFromForm(getValues, index) || createChapterState();
        setValue(`chapters.${index}`, chapterData);

        // Update form state
        setFormState((prev) => ({
          ...prev,
          currentChapterIndex: index,
          currentSubModule: prev.subModules[index],
        }));

        console.log("Loaded chapter data:", { index, chapterData });
      } catch (error) {
        console.error("Error loading chapter:", error);
      }
    },
  };

  // Navigation handlers
  const navigationHandlers = {
    handleCancel: () => {
      navigate("/training");
    },
    handleBack: () => {
      if (formState.selectedElement > 1) {
        // Save current chapter data before going back
        if (formState.currentChapterIndex !== null) {
          const currentData = getChapterFromForm(
            getValues,
            formState.currentChapterIndex
          );
          if (currentData) {
            updateChapter(formState.currentChapterIndex, currentData);
          }
        }

        setFormState((prev) => ({
          ...prev,
          selectedElement: prev.selectedElement - 1,
          currentChapterIndex: null,
          currentSubModule: null,
        }));
      } else {
        navigate("/training");
      }
    },

    handleStepValidation: async (nextStep) => {
      console.log(
        "Starting step validation for step:",
        formState.selectedElement
      );

      try {
        let isValid = false;

        if (formState.selectedElement === 1) {
          isValid = await validateTrainingType(formMethods);
        } else {
          isValid = await validateStep(formMethods, formState.selectedElement);
        }

        if (!isValid) {
          console.log("Validation failed for step:", formState.selectedElement);
          return { success: false };
        }

        if (formState.selectedElement === 2) {
          // When validating step 2, create first chapter
          const newChapter = createChapterState(0);

          appendChapter(newChapter);
          setFormState((prev) => ({
            ...prev,
            selectedElement: 3,
            currentChapterIndex: 0,
          }));

          updateChapterInForm(setValue, chapterFields, 0, newChapter);
          console.log("Created first chapter after step 2 validation");
          return { success: true };
        } else if (formState.selectedElement === 5) {
          // Step 5 validation - only validate required fields
          const formValues = formMethods.getValues();
          const requiredFields = [
            "details.title",
            "type_product",
            "details.description",
            "details.language",
            "price",
          ];
          const missingFields = requiredFields.filter((field) => {
            const value = field
              .split(".")
              .reduce((obj, key) => obj?.[key], formValues);
            return !value;
          });

          if (missingFields.length > 0) {
            console.error("Missing required fields:", missingFields);
            return {
              success: false,
              error: `Missing required fields: ${missingFields.join(", ")}`,
            };
          }

          return { success: true };
        } else {
          // For other steps, proceed normally
          setFormState((prev) => ({
            ...prev,
            selectedElement: nextStep,
          }));
          return { success: true };
        }
      } catch (error) {
        console.error("Error in handleStepValidation:", error);
        return { success: false };
      }
    },

    handleStepClick: (stepId, subModuleId = null) => {
      if (stepId === 3 && subModuleId) {
        // Extract chapter index from subModuleId (e.g., "chapter_1" -> 0)
        const chapterIndex = parseInt(subModuleId.split("_")[1]) - 1;
        handleChapterNavigation(chapterIndex);
      } else {
        setFormState((prev) => ({
          ...prev,
          selectedElement: stepId,
          currentSubModule: null,
        }));
      }
    },
  };

  // Handle step navigation
  const handleNext = async () => {
    const currentValues = getValues();
    const isValid = await validateStep(
      formState.selectedElement,
      currentValues
    );

    if (isValid) {
      if (formState.selectedElement < formState.items.length) {
        setFormState((prev) => ({
          ...prev,
          selectedElement: prev.selectedElement + 1,
        }));
      } else {
        modalHandlers.handleConfirmationSubmit();
      }
    }
  };

  const handlePaymentValidation = async (data) => {
    // This is called by the payment component to validate before processing payment
    const formData = formMethods.getValues();
    const result = await handleSubmit(formData);
    return result;
  };

  const renderStepContent = () => {
    switch (formState.selectedElement) {
      case 5: // Payment step
        return (
          <TrainerNewPaymentForm
            isEdit={isEdit}
            price={2.99}
            onSubmit={handleTrainingSubmit}
            onValidate={handlePaymentValidation}
            formMethods={formMethods}
          />
        );
      default:
        return renderContentList({
          type,
          trainingType,
          selectedElement: formState.selectedElement,
          handleStepValidation: navigationHandlers.handleStepValidation,
          handleSubmit: handleTrainingSubmit,
          setConfirmShow: (val) =>
            setFormState((prev) => ({ ...prev, confirmShow: val })),
          setItems: (val) => setFormState((prev) => ({ ...prev, items: val })),
          formMethods,
          addChapter: handleChapterOperations.addChapter,
          currentChapterIndex: formState.currentChapterIndex,
          handleConfirmChapter: handleChapterOperations.confirmChapter,
          totalChapters: chapterFields.length,
          handleNextStep: navigationHandlers.handleNext,
          navigationHandlers,
          isEdit,
          openModalRegister: formState.openModalRegister,
          setOpenModalRegister: (value) =>
            setFormState((prev) => ({
              ...prev,
              openModalRegister: value,
            })),
        });
    }
  };

  // Clean up function to handle chapter state
  useEffect(() => {
    return () => {
      // Clean up chapter state when component unmounts
      formMethods.reset({
        ...formMethods.getValues(),
        chapters: chapterFields,
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      console.log("getValues", formMethods.getValues());
    };
  }, [formMethods]);

  return (
    <FormProvider {...formMethods}>
      <StyledContainer>
        <BigContainer>
          <FormStepHeader />
          <StyledFormContainer>
            <StyledStepsFormContainer>
              <StyledStepContentColumn>
                <StyledStepContentCard>
                  {renderStepContent()}
                  <ModalConfirmationTraining
                    confirmShow={formState.confirmShow}
                    onCancel={() => {
                      if (formState.selectedElement > 1) {
                        setFormState((prev) => ({
                          ...prev,
                          selectedElement: prev.selectedElement - 1,
                          confirmShow: false,
                        }));
                      }
                    }}
                    onSubmit={modalHandlers.handleConfirmationSubmit}
                  />
                </StyledStepContentCard>
              </StyledStepContentColumn>
              <StepperNavigation
                items={formState.items}
                selectedElement={formState.selectedElement}
                currentSubModule={formState.currentSubModule}
                subModules={formState.subModules}
                onStepClick={navigationHandlers.handleStepClick}
                onAddChapter={handleChapterOperations.addChapter}
              />
            </StyledStepsFormContainer>
          </StyledFormContainer>
        </BigContainer>
      </StyledContainer>
    </FormProvider>
  );
};

export default ParcoursForm;

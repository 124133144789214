import React, { useState } from 'react';
import { useGetUserByIdQuery } from '../../redux/api/users/userApi';
import { useGetWithdrawalQuery, useGetWithdrawalMethodQuery } from "../../redux/api/withdrawal/withdrawalApi";
import {
  BackCard,
  CardBalance,
  CardContainer,
  FrontCard,
  StyleWallet,
  StyledYourCardContainer,
  StyledYourCardContainerBank,
  WithdrawButton,
  StatusBadge,
  BankDetailTitle,
  BankDetailContent,
  BankDetailCardBloc,
  BankDetailCardBlocALL,
  BankDetailCardBloc1,
  BankDetailCardBloc3,
  StyleTitle,
  StyleTitleStatus,
  StyleTitleBloc,
  StyleTitleWallet,
  StyleComment,
  WithdrawAccountBankButton,
  WithdrawAccountBankButtonDelete,
  TransactionsTable,
  TransactionRow,
  InvoicingLink
} from './WalletComponents.style';
import ModalAddBankDetails from '../ModalITgalaxy/ModalAddBankDetails/ModalAddBankDetails';
import ModalDeleteWidthrawMethod from '../ModalITgalaxy/ModalDeleteWidthrawMethod/ModalDeleteWidthrawMethod';
import ModalPayOut from '../ModalITgalaxy/ModalPayOut/ModalPayOut';
import PaginatedComponent from 'components/PaginationComponent/PaginationComponent';


const WithdrawMethodComponent = (props) => {
  const { typeUser, id , emailUser , nameUser  } = props; // Extract role and id from props
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalAddBankDetails, setShowModalAddBankDetails] = useState(false);
  const [showModalDeleteBank, setShowModalDeleteBank] = useState(false);
  const [showModalPayOut, setShowModalPayOut] = useState(false);
  const paramswithdrawals = `?userId=${id}&typeUser=${typeUser}`;
  const params = `?userId=${id}&typeUser=${typeUser}`;

  const handleShowModalDelete= () => {
      setShowModalDeleteBank(true);
     }
 
  const handleShowModalPayOut= () => {
    setShowModalPayOut(true);
  }

  const handleCloseShowModalPayOut= () => {
    setShowModalPayOut(false);
  }

  const handleCloseShowModalDelete= () => {
      setShowModalDeleteBank(false);
     }


  const handleShowModalAddBank = () => {
    setShowModalAddBankDetails(true);
  };

  const handleCloseModalAddBank = () => {
    setShowModalAddBankDetails(false);
  };

  const { data: withdrawalMethod , refetch : refetch_withdrawalMethod} = useGetWithdrawalMethodQuery(params);

  const { data: widthrawals, error: error , refetch: reftech_widthrawal} = useGetWithdrawalQuery(paramswithdrawals, {
    skip: !id, // Skip if the id is not available
  });


    // Create transactions from invoicings or use a fallback
    const transactions = widthrawals?.data.map((widthrawal, index) => ({
      num: index + 1,
      id: widthrawal.id,
      type: 'widthrawal',
      date: widthrawal.createdAt || 'N/A',
      totalAmount: widthrawal.totalAmount || '$0.00',
      FeeItgalaxy : widthrawal.feeItGalaxy  || '0.00',
      iban: widthrawal.withdrawal_method.iban || 'N/A',
      currency: widthrawal.withdrawal_method.currency || 'N/A',
      rate: widthrawal.rate|| 'N/A',
      transfertPrice: widthrawal.transfertPrice ,
      netAmount: widthrawal.netAmount || 'N/A',
      status: widthrawal.status || 'Unknown',
      widthrawal: 'Click for detail',
    })) || []; 
  

    // Pagination logic
  const totalPages = Math.ceil(transactions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedTransactions = transactions.slice(startIndex, startIndex + itemsPerPage);

   // Handle page change
   const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const { data: user } = useGetUserByIdQuery({ role: typeUser, id });

  const bankDetails = withdrawalMethod ? {
    id: withdrawalMethod?.data[0]?.id || 'N/A',
    owner_bank_account: withdrawalMethod?.data[0]?.owner_bank_account || 'N/A',
    bank_country: withdrawalMethod?.data[0]?.bank_country || 'N/A',
    currency: withdrawalMethod?.data[0]?.currency || 'N/A',
    bankName: withdrawalMethod?.data[0]?.bankName || 'N/A',
    iban: withdrawalMethod?.data[0]?.iban || 'N/A',
    control_comment: withdrawalMethod?.data[0]?.control_comment || 'Nffff/A',
    status: withdrawalMethod?.data[0]?.status || 'N/A',
  } : {};

  const statusColor = bankDetails.status === 'VALIDATE'
      ? 'green'
      : bankDetails.status === 'ACTION_REQUIRED'
      ? 'red'
      : bankDetails.status === 'VERIFICATION'
      ? 'orange'
      : 'black';

  return (
    <>
      <StyleWallet>
        <StyledYourCardContainer>
          <StyleTitleWallet>Wallet in euro:</StyleTitleWallet>
          <CardContainer>
            <BackCard />
            <FrontCard>
              <CardBalance>{user?.data[0]?.balance_details}</CardBalance>
            </FrontCard>
          </CardContainer>
          <WithdrawButton onClick={handleShowModalPayOut}  >Pay Out</WithdrawButton>
        </StyledYourCardContainer>

        <StyledYourCardContainerBank>
          <StyleTitleBloc>
            <StyleTitle>Bank Details:</StyleTitle>
            <BankDetailCardBloc>

            <StyleTitleStatus statusColor={statusColor}>Status: {bankDetails.status}</StyleTitleStatus>
         
         {(bankDetails.status === 'ACTION_REQUIRED' && bankDetails.control_comment !== 'N/A') && (
          <StyleComment> Control comment : {bankDetails.control_comment} </StyleComment>
         )}
          </BankDetailCardBloc>
          </StyleTitleBloc>
          <BankDetailCardBloc>
            <BankDetailCardBloc3>
              <BankDetailTitle>Account Owner Name</BankDetailTitle>
              <BankDetailContent>{bankDetails.owner_bank_account}</BankDetailContent>
            </BankDetailCardBloc3>

            <BankDetailCardBlocALL>
              <BankDetailCardBloc1>
                <BankDetailTitle>Country Bank</BankDetailTitle>
                <BankDetailContent>{bankDetails.bank_country}</BankDetailContent>
              </BankDetailCardBloc1>
              <BankDetailCardBloc1>
                <BankDetailTitle>Currency</BankDetailTitle>
                <BankDetailContent>{bankDetails.currency}</BankDetailContent>
              </BankDetailCardBloc1>
            </BankDetailCardBlocALL>

            <BankDetailCardBloc3>
              <BankDetailTitle>IBAN</BankDetailTitle>
              <BankDetailContent>{bankDetails.iban}</BankDetailContent>
            </BankDetailCardBloc3>
          </BankDetailCardBloc>

          {/* Conditional rendering based on withdrawalMethod existence */}
          {!withdrawalMethod || withdrawalMethod.data.length === 0 ? (
            <WithdrawAccountBankButton onClick={handleShowModalAddBank} disabled={false}>
              Add Bank Account
            </WithdrawAccountBankButton>
          ) : (
            <WithdrawAccountBankButtonDelete onClick={handleShowModalDelete} disabled={bankDetails.status === 'VERIFICATION'}>
            Delete withdrawal Method
          </WithdrawAccountBankButtonDelete>
        )}
      </StyledYourCardContainerBank>
    </StyleWallet>

    <TransactionsTable>
          <thead>
            <tr>
              <th>Num</th>
              <th>Date</th>
              <th>Total Amount (EUR) </th>
              <th>ItGalaxy fee 20% (EUR) </th>
              <th>Your bank iban</th>
              <th>Your bank currency</th>
              <th>Rate</th>
              <th>Transfer fee (in your currency)</th>
              <th>Net Amount (in your currency)</th>
              <th>Status</th>
              <th>Invoicing</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <TransactionRow key={index}>
                <td>{"Widthrawal"}</td>
                <td>{transaction.date}</td>
                <td>{transaction.totalAmount}</td>
                <td>{transaction.FeeItgalaxy}</td>
                <td>{transaction.iban}</td>
                <td>{transaction.currency}</td>
                <td>{transaction.rate}</td>
                <td>{transaction.transfertPrice}</td>
                <td>{transaction.netAmount}</td>
                <td>
                  <StatusBadge status={transaction.status}>
                    {transaction.status}
                  </StatusBadge>
                </td>
                <td>
                  <InvoicingLink href={`${process.env.REACT_APP_CDN_ITGALAXY}/invoices/${transaction.type}s/facture${transaction.id}.pdf`}>
                    {transaction.widthrawal}
                  </InvoicingLink>
                </td>
              </TransactionRow>
            ))}
          </tbody>
        </TransactionsTable>

        <PaginatedComponent
          itemsPerPage={itemsPerPage}
          totalItems={transactions.length}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />


    <ModalAddBankDetails 
      show={showModalAddBankDetails} 
      close={handleCloseModalAddBank} 
      id={id}
      role={typeUser}
      nameUser={nameUser}
      emailUser={emailUser}    
      refetch={refetch_withdrawalMethod} 
    />

  <ModalDeleteWidthrawMethod 
      show={showModalDeleteBank} 
      close={handleCloseShowModalDelete} 
      id={bankDetails.id} 
      refetch={refetch_withdrawalMethod} 
    />
    <ModalPayOut 
      show={showModalPayOut} 
      close={handleCloseShowModalPayOut} 
      bankDetailsStatus={bankDetails.status}
      id={id}
      role={typeUser}
      refetch={reftech_widthrawal}
    />
  </>
);
};

export default WithdrawMethodComponent;
import styled from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: auto;
`;

export const StyledFormBody = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
`;

export const StyledTypography = styled.h3`
  margin: 20px 0px 20px 0px;
`;


export const ActiveVersementButton  = styled.button`

background: black;
color: white;

height: 46px;
width: 100%;
border-radius: 10px;
font-weight: bold;
font-size: 16px;
justify-content: center;
align-items: center;
grap: 15px;

  &:hover {
    background: red; // Change background color on hover
    cursor: pointer; // Optional: Change cursor to pointer
}
`;

export const DisableActiveVersementButton  = styled.button`

background: gray;
color: white;

height: 46px;
width: 100%;
border-radius: 10px;
font-weight: bold;
font-size: 16px;
justify-content: center;
align-items: center;
grap: 15px;

`;
import React, { useCallback, useEffect, useState } from "react";
import { useGetProjectsQuery } from "../../redux/api/projects/projectApi";
import { Placeholder, Spinner } from "react-bootstrap";
import ModalShowMyOffre from "components/ModalITgalaxy/ModalShowMyOffre/ModalShowMyOffre";
import Pagination from "components/Paginations/Pagination";

import {
  AddNewProjectButtonstyle,
  ContainerWrapper,
  ListingProjects,
  CardContainers,
  NotFoundTextStyle,
  CardProjectsTitle,
  ViewLabelProjects,
  CardProjects,
  ProjectLabelsContainer,
  DatePost,
  StyleAllProjects,
  AllCard,
  SearchBar,
  SearchInputContainer,
  DashProposals,
  DashProposalDetails,
  Dropdown,
  DropdownItem,
  ButtonContainer
} from "./MyProjects.style";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { FormProvider, useForm } from "react-hook-form";
import { SearchIconInput } from "components/MyOffers/styled";
import { formConfig } from "./MyProjects.constants";
import { FaCaretDown } from 'react-icons/fa'; 
import ProposalsDash from "../ProposalsDash/ProposalsDash";
import NewComposeyourTeam from "components/NewComposeyourTeam/NewComposeyourTeam";
import NewShareyourTasks from "components/NewShareyourTasks/NewShareyourTasks";

export default function MyProjects({ decodeToken , id }) {
  const queryParams = new URLSearchParams(window.location.search);
  const formMethods = useForm({});
  const [selectedItem, setSelectedItem] = useState("");
  const [showModalProject, setShowModalProject] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [projectsData, setProjectsData] = useState([]);
  const [title, setTitle] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [proposalDash, setProposalDash] = useState(false);
  const composeyourTeam = queryParams.get('composeyourTeam');
  const shareTasks = queryParams.get('shareTasks');
  const [newComposeyourTeam, setNewComposeyourTeam] = useState(composeyourTeam);
  const [newShareTasks, setNewShareTasks] = useState(shareTasks); 

 
  useEffect(() => {
    if (composeyourTeam === true) {
      setNewComposeyourTeam(true);
    } else if (shareTasks === true)(
      setNewShareTasks(true)
    )
  }, []);


  const handleNewComposeyourteam = () => {
    setNewComposeyourTeam(true);
  };

  const handleNewShareTasks = () => {
    setNewShareTasks(true);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the menu visibility
  };

  const params = `?page=${currentPage}&limit=${recordsPerPage}&entrepriseId=${id}${title ? `&title=${title}` : ''}`;

  // Fetch user projects with the updated filter and title
  const { data: projectData, isLoading: projectsDataLoading, error: projectsDataError, refetch } = useGetProjectsQuery(params);

  // Update projectsData when projectData is available
  useEffect(() => {
    if (projectData) {
      setProjectsData(projectData.data);
    }
  }, [projectData]);

  // Automatically fetch on title change or after adding a new project
  useEffect(() => {
    refetch();
  }, [title, currentPage, recordsPerPage]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModalProject(true);
  };

  const handleChangeSearch = useCallback(
    (e) => {
      if (e.key === "Enter") {
        setTitle(e.target.value);
      }
    },
    []
  );

  const handleItemProposalsClick = (item) => {
    setSelectedItem(item);
    setProposalDash(true);
    setShowModalProject(false)  
  };

      return (
        <>
          <ContainerWrapper className="container">
            {!proposalDash && !newComposeyourTeam && !newShareTasks && (
              <ListingProjects>
                <SearchBar>
                  <FormProvider {...formMethods}>
                    <SearchInputContainer onKeyDown={handleChangeSearch}>
                      <GenericInput
                        inputObject={{
                          ...formConfig.title,
                          label: "title",
                          placeholder: "Search your project",
                        }}
                        disabledForm={false}
                      />
                      <SearchIconInput className="fa fa-search SearchIconInput" />
                    </SearchInputContainer>
                  </FormProvider>
                  <AddNewProjectButtonstyle onClick={handleMenuToggle}>
                    Add New Project
                    <FaCaretDown style={{ marginLeft: "30px", cursor: "pointer" }} />
                    {isMenuOpen && (
                      <Dropdown>
                        <DropdownItem onClick={handleNewComposeyourteam}>
                          Compose your team
                        </DropdownItem>
                        <DropdownItem onClick={handleNewShareTasks}>
                          Share a project
                        </DropdownItem>
                      </Dropdown>
                    )}
                  </AddNewProjectButtonstyle>
                </SearchBar>
    
                {projectsDataLoading ? (
                  <CardContainers>
                    <Spinner />
                  </CardContainers>
                ) : projectsDataError ? (
                  <CardContainers>
                    <NotFoundTextStyle>
                      🚀 Error loading projects, please try again later! 🚀
                    </NotFoundTextStyle>
                  </CardContainers>
                ) : (
                  <>
                    <ProjectLabelsContainer>
                      <ViewLabelProjects>Projects</ViewLabelProjects>
                    </ProjectLabelsContainer>
    
                    {projectsData.length === 0 ? (
                      <CardContainers>
                        <NotFoundTextStyle>No project was created...</NotFoundTextStyle>
                      </CardContainers>
                    ) : (
                      <>
                        <AllCard>
                          {projectsData.map((projectData) => (
                            <CardProjects key={projectData.id}>
                              <StyleAllProjects>
                                <CardProjectsTitle>
                                  {projectData.title.length > 30 
                                    ? `${projectData.title.substring(0, 30)}...`
                                    : projectData.title
                                  }
                                  <ButtonContainer>
                                    <DashProposalDetails onClick={() => handleItemClick(projectData)}>
                                      Project Details
                                    </DashProposalDetails>
                                    <DashProposals onClick={() => handleItemProposalsClick(projectData)}>
                                      Dashboard proposals <i className="fas fa-external-link-alt"></i>
                                    </DashProposals>
                                  </ButtonContainer>
                                </CardProjectsTitle>
                                <DatePost>
                                  <span
                                    style={{
                                      color:
                                        projectData.status === 'ACTIVE' ? 'orange' :
                                        projectData.status === 'VALIDATED' ? 'var(--Success-Success200, rgba(21, 176, 151, 1))' :
                                        projectData.status === 'FINISHED' ? 'green' :
                                        projectData.status === 'INPROGRESS' ? 'green' :    
                                        projectData.status === 'SUSPEND' ? 'red' :
                                        'orange' // fallback color
                                    }}
                                  >
                                    Status: {projectData.status}
                                  </span><br />
                                  Created: {projectData.createdAt ? new Date(projectData.createdAt).toDateString() : 'Date not available'}
                                </DatePost>
                              </StyleAllProjects>
                            </CardProjects>
                          ))}
                        </AllCard>
    
                        <div className="col-lg-12 col-md-12 col-12 mt-4">
                          {projectData?.pagination?.totalPages > 1 && (
                            <Pagination
                              nPages={projectData.pagination.totalPages}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </ListingProjects>
            )}
    
            {proposalDash && !newComposeyourTeam && !newShareTasks && (
              <ProposalsDash id={selectedItem.id} setProposalDash={setProposalDash} idEntreprise={id} role={"ENTREPRISE"} />
            )}
            
            {!proposalDash && newComposeyourTeam && !newShareTasks && (
              <NewComposeyourTeam id={id} setNewComposeyourTeam={setNewComposeyourTeam} />
            )}
            
            {!proposalDash && !newComposeyourTeam && newShareTasks && (
              <NewShareyourTasks id={id} setNewShareTasks={setNewShareTasks} />
            )}
    
          </ContainerWrapper>
    
          {selectedItem && (
            <ModalShowMyOffre
              offre={selectedItem}
              confirmShow={showModalProject}
              refetch={refetch}
              closeModal={setShowModalProject}
            />
          )}
        </>
      );
    }
    

import styled from 'styled-components';

import { breakpoints } from '../../../../core/constants/breakpoints';

export const ContentFormContainer = styled.div`
  height: 100%;

  @media (max-width: ${breakpoints.extrasmall}) {
    height: 100%;
  }

  @media (min-width: ${breakpoints.small}) {
    height: 100%;
  }

  @media (min-width: ${breakpoints.medium}) {
    height: 100%;
  }

  @media (min-width: ${breakpoints.large}) {
    height: 100%;
  }

  @media (min-width: ${breakpoints.extraLarge}) {
    height: 100%;
  }

  @media (min-width: ${breakpoints.extraExtraLarge}) {
    height: 100%;
  }
`;

export const StyledLabel = styled.label`
  font-family: "Inter", sans-serif; // Use Inter font family
  font-weight: bold; // Bold font weight
  font-size: 16px; // Define the font size
  margin-bottom: 8px; // Space below the label
  color: #333; // Darker text color for readability
  padding-top: 10px;
    width: 100%;

`;
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { formations } from "utils/fake_data";
import { addTypeTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import { formConfig } from "./ListingMyTrainer.config";
import {
  Container,
  ContainerWrapper,
  CreateTrainingButtonContainer,
  SearchBar,
  SearchFilter,
  SearchFilterBar,
  SearchFilterSkills,
  StyledSearchBarContainer,
  Wrapper
} from "./ListingMyTrainer.style";
import { useGetMyProductsQuery } from "../../../../redux/api/training/trainingApi";
import { getTokenFromLocalStorage } from "../../../../core/helpers/storage";
import { jwtDecode } from "jwt-decode";

const ListingMyTrainer = (props) => {
  const formMethods = useForm({});
  const dispatch = useDispatch();
  const tokenUser = getTokenFromLocalStorage();
  const decodeToken = tokenUser ? jwtDecode(tokenUser) : null ;
  const userId = decodeToken ? decodeToken.id : null ;

  //myproducts

  const {
    data: myProducts,
  } = useGetMyProductsQuery(userId);


  const handleNavigate = (token) => {
   
    window.location.href = `myproduct/${token}`;      

  };

  const handelTrainerForm = () => {

    props.setTrainerForm(true);
  }

  // Use watch to monitor changes to the 'type' field
  const { watch } = formMethods;
  const trainingType = watch('type'); // This will give you the current value of 'type'

  useEffect(() => {
    if (trainingType) { // Only dispatch if trainingType is defined
      dispatch(addTypeTraining(trainingType));
    }
  }, [dispatch, trainingType]); // Run effect when trainingType changes


  return (
    <Wrapper>
      <ContainerWrapper className="container">
        <SearchBar>
          <FormProvider {...formMethods}>
            <StyledSearchBarContainer>
              <SearchFilterBar>
                <GenericInput
                  inputObject={{ ...formConfig.title, label: "Filter by Title" }}
                  disabledForm={false}
                />
              </SearchFilterBar>
              <SearchFilterSkills>
                <GenericInput
                  inputObject={{ ...formConfig.skills, label: "Filter by Skills" }}
                  disabledForm={false}
                />
              </SearchFilterSkills>
              <SearchFilter>
                <GenericInput
                  inputObject={{ ...formConfig.type, label: "Filter by Type" }}
                  disabledForm={false}
                />
              </SearchFilter>
              <CreateTrainingButtonContainer onClick={handelTrainerForm}>
                Create a Product
              </CreateTrainingButtonContainer>
            </StyledSearchBarContainer>

          </FormProvider>
        </SearchBar>
        <Container>
          {myProducts && myProducts.length > 0 ? (
            myProducts.data.map((value) => (
              <FormationsCard
                key={value.id}
                formation={value}
                animateDirection="fade-in" // Assuming you handle animations via this prop
                onClick={() => handleNavigate(value.display)}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", margin: "20px" }}>
              <h2>No Available Formations</h2>
              <p>Please check back later or contact support for more information.</p>
            </div>
          )}
        </Container>
      </ContainerWrapper>
    </Wrapper>
  );
}

export default ListingMyTrainer;

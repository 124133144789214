// CourseModules.jsx
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaFolder } from 'react-icons/fa'; // Import a folder icon from Font Awesome
import { getURL } from '../../redux/api/uploads/uploadSlice';
import { useDispatch } from "react-redux";


const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
`;

const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
`;

const ModulesCard = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 0px 0px black;
`;

const ModuleRow = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #f9f9f9;
  }
`;

const ModuleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
`;

const ModuleSubTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin-top: 5px;
`;

const ModuleDetails = styled.div`
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
  line-height: 1.5;
`;

const DetailsText = styled.p`
  margin-bottom: 10px;
`;

const IncludedItems = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  margin-top: 10px;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
`;

const Arrow = styled.span`
  font-size: 10px;
  color: black;
`;

const CourseModules = (props) => {
  const [expandedModule, setExpandedModule] = useState(null);
  const [moduleCounts, setModuleCounts] = useState({});
  const dispatch = useDispatch();

  // Function to toggle the expanded module
  const toggleModule = (moduleId) => {
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  // Ensure props.data is defined before accessing its properties
  const chapters = props.data?.chapters || {}; // Fallback to an empty object
  const length = Object.keys(chapters).length;

  useEffect(() => {
    const fetchCounts = async () => {
      const counts = {};
      // Iterating through chapter IDs to get counts
      for (const chapterId of Object.keys(chapters)) {
        counts[chapterId] = await calculeVideosAndSupportChapter(chapterId);
      }
      setModuleCounts(counts);
    };

    if (length > 0) {
      fetchCounts();
    }
  }, [chapters, dispatch, length]); // Use length to control effect execution

  const calculeVideosAndSupportChapter = async (chapterId) => {
    const pathVideos = `products/applications/${props.data?.id}/chapters/${chapterId}/videos/`;
    const pathSupports = `products/applications/${props.data?.id}/chapters/${chapterId}/support/`;

    try {
      const listPathVideos = await dispatch(getURL({ location: pathVideos }));
      const listPathSupports = await dispatch(getURL({ location: pathSupports }));

      return { 
        countVideos: listPathVideos?.Contents?.length || 0, 
        countSupports: listPathSupports?.Contents?.length || 0 
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      return { countVideos: 0, countSupports: 0 };
    }
  };

  return (
    <Container>
      <Header>There are {length} modules in this course</Header>
      <Description>{props.data?.description || "No description available."}</Description>
      <ModulesCard>
        {Object.keys(chapters).map((chapterId) => {
          const module = chapters[chapterId];

          return (
            <ModuleRow key={chapterId} onClick={() => toggleModule(chapterId)}>
              <ModuleTitle>
                {module.title}
                <Arrow>Module Details {expandedModule === chapterId ? '∧' : '∨'}</Arrow>
              </ModuleTitle>
              <ModuleSubTitle>{module.description}</ModuleSubTitle>

              {expandedModule === chapterId && (
                <ModuleDetails>
                  <IncludedItems>
                    <IconText>📹 {moduleCounts[chapterId]?.countVideos ?? 0} videos</IconText>
                    <IconText><FaFolder /> {moduleCounts[chapterId]?.countSupports ?? 0} support</IconText>
                  </IncludedItems>
                </ModuleDetails>
              )}
            </ModuleRow>
          );
        })}
      </ModulesCard>
    </Container>
  );
};

export default CourseModules;

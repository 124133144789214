import React from "react";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const RichTextEditor = ({ value, onChange }) => {
  return (
    <div style={{ height: '250px', minHeight: '250px', overflowY: 'auto' }}>
      <ReactQuill
        value={value} // The content to display in the editor
        onChange={(content) => onChange(content)} // Pass the changed content directly
        theme="snow"
        style={{ minHeight: '250px', height: '100%', backgroundColor: 'white' }} // Set background color to white
      />
    </div>
  );
};

export default RichTextEditor;

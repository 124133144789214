import PaymentDetails from "components/PaymentForm/paymentDetails/paymentDetails";

const TrainerNewPaymentForm = ({
  isEdit = true,
  price = 2.99,
  onSubmit,
  onCancel,
  formMethods,
  disableStep,
  refetchCompanyRequestCreation,
  paymentProposal,
}) => {
  if (typeof onSubmit !== "function") {
    console.error(
      "onSubmit is not a function in TrainerNewPaymentForm:",
      onSubmit
    );
  }

  return (
    <PaymentDetails
      price={price}
      title={"PAYMENT TO SHARE THE PRODUCT"}
      note={"To share this product with community you need to pay the service."}
      onSubmit={onSubmit}
      paymentProposal={paymentProposal}
    />
  );
};

export default TrainerNewPaymentForm;

import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./ModalPayOut.constants";
import {
  PayOutButton,
  StyleInput,
  Title,
  TitleInvalid
} from "./Style";
import { Spinner } from "react-bootstrap";
import { useGetCompanyByPresidentIdAndTypeQuery } from "../../../redux/api/company/companyApi";
import { useCreateWithdrawalMutation } from "../../../redux/api/withdrawal/withdrawalApi";
import { useGenrateInvoicingMutation } from '../../../redux/api/genrateInvoicing/genrateInvoicingApi';
import { useAddInvoicingMutation } from '../../../redux/api/invoicing/invoicingApi';
import toast from "react-hot-toast";


const ModalPayOut = (props) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const now = Date.now(); // returns the number of milliseconds since Jan 1, 1970


  const formMethods = useForm({});
  const [createWithdrawal] = useCreateWithdrawalMutation();
  const [createInvoice] = useAddInvoicingMutation();
  const [generateInvoicing] = useGenrateInvoicingMutation();
  const [formData, setFormData] = useState({
    rising: 0,
  });

  const { data: company } = useGetCompanyByPresidentIdAndTypeQuery({ presidentId: props.id, type: props.role });


  const handleChangeRising = (amount) => {

    setFormData(prevFormData => ({
      ...prevFormData,
      rising: amount.target.value
    }));
  };


  const handlePayOutClick = async () => {
    setLoading(true);

    try {
      const withdrawalResponse = await createWithdrawal({
        userId: props.id,
        typeUser: props.role,
        totalAmount: formData.rising, // Ensure formData is correctly set up
      }).unwrap();  // Using unwrap to handle the promise properly
   
      const sendInvoicing = await generateInvoicing({        
        "fileFolder" : "withdrawals" ,
        "fileName": "withdrawal-"+withdrawalResponse.id+".pdf" , 
        "id"    : withdrawalResponse.id ,
        "status": "DONE",
        "date": now,
        "items": [
            {
                "totalAmount":  withdrawalResponse.totalAmount,
                "feeItGalaxy": withdrawalResponse.feeItGalaxy,
                "toCurrency" : withdrawalResponse.withdrawalMethod.currency,
                "rate": withdrawalResponse.rate,
                "netAmount": withdrawalResponse.netAmount,
                "transfertPrice":  withdrawalResponse.transfertPrice
            }
        ],
        "customer": {
            "summary": withdrawalResponse.withdrawalMethod.nameUser,
            "company_name" : withdrawalResponse.company.company_name,
            "siren": withdrawalResponse.company.company_siren,
            "company_country" :  withdrawalResponse.company.company_location,
            "iban" : withdrawalResponse.withdrawalMethod.iban ,
            "toCurrency" : withdrawalResponse.withdrawalMethod.currency  ,
            "email": withdrawalResponse.withdrawalMethod.emailUser
        }

         });      
   
       console.log("send invoice s3 && email", sendInvoicing);

       toast.success("Create Withdrawal Success", {
        position: "top-center",
        autoClose: 3000,
      });

      window.location.href = `/wallet`;      
      props.close();
    } catch (e) {
      console.error("Error creating withdrawal :",e);
      setError("Error creating withdrawal : " );
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };
  
  const RenderBodyPayOut = (
    <>
      {loading && <Spinner />}

      {props.bankDetailsStatus !== 'VALIDATE' && (
        <StyleInput>
          <TitleInvalid>{"Please verify your withdrawal method, it is not valid yet to pay out. Thank you."}</TitleInvalid>
        </StyleInput>
      )}

      {/*(company?.data?.length === 0 || company?.data[0].status !== 'VALIDATE') && (
        <StyleInput>
          <TitleInvalid>{"Please verify your company details, they are not valid yet to pay out. Thank you."}</TitleInvalid>
        </StyleInput>
      )} */}

      {/*company?.data?.length > 0 && company?.data[0].status === 'VALIDATE' && */ props.bankDetailsStatus === 'VALIDATE' && (
        <FormProvider {...formMethods}>
          <StyleInput>
            <Title>{"Rising"}</Title>
            <GenericInput
              inputObject={{
                ...formConfig.rising,
              }}
              onChange={handleChangeRising}
              disabledForm={false}
            />
          </StyleInput>
        </FormProvider>
      )}
    </>
  );
  const RenderFooterPayOut = (
    <PayOutButton 
      onClick={handlePayOutClick} 
      disabled={!(/*company?.data?.length > 0 && company?.data[0]?.status === 'VALIDATE' && */ props?.bankDetailsStatus === 'VALIDATE')}
    >
      Pay Out
    </PayOutButton>
  );
  

  const RenderHeaderPayOut = (
    <Title>
      How much money you need ?
    </Title>
  );
  return (
    <ModalComponent
      show={props.show}
      closeModal={props.close}
      body={RenderBodyPayOut}
      header={RenderHeaderPayOut}
      footer={RenderFooterPayOut}
      bodyPadding={"0px"}
      minWidth={"50vw"}
      Height={"250px"}
      footerpaddingtop={"0"}
      footerpaddingbottom={"0"}
    />
  );
};

export default ModalPayOut;

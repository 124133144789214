import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { getTokenFromLocalStorage, sendNotificationEmail } from "../core/helpers/storage";
import { useUpdateProposalEntrepriseMutation, useUpdateProposalMutation } from "../redux/api/proposals/proposalApi";
import { useRefundPaypalPaymentMutation } from "../redux/api/payment/paymentApi";
import { useAddInvoicingMutation } from '../redux/api/invoicing/invoicingApi';
import { useGenrateInvoicingMutation } from '../redux/api/genrateInvoicing/genrateInvoicingApi';

const useHandleSubmitUpdateProposal = (proposal, formMethods) => {
  const [loading, setLoading] = useState(false);
  const [updateEntrepriseProposal] = useUpdateProposalEntrepriseMutation();
  const [updateProposalShareTask] = useUpdateProposalMutation();
  const [refundPaypalPayment] = useRefundPaypalPaymentMutation();
  const [createInvoice] = useAddInvoicingMutation();
  const [generateInvoicing] = useGenrateInvoicingMutation();
  const token = getTokenFromLocalStorage();
  const decodedToken = token ? jwtDecode(token) : null;
  const payerId = decodedToken?.id;
  const payerType  = decodedToken?.role;
  const payerEmail = decodedToken?.email;
  const payerName = payerType === 'ENTREPRISE' ? decodedToken?.username :  decodedToken?.name + " "  + decodedToken?.first_name ;
  const now = Date.now(); // returns the number of milliseconds since Jan 1, 1970


  const updateProposal = async (status, additionalData, typeProposal, comment) => {
    setLoading(true);
    try {
      const updateBody = {
        proposalData: { status, ...additionalData },
        comment: comment
      };

      const response = typeProposal === 'COMPOSED_FREELANCE'
        ? await updateEntrepriseProposal({ body: updateBody, id: proposal.id }).unwrap()
        : await updateProposalShareTask({ body: updateBody, id: proposal.id }).unwrap();
      return response;
    } catch (error) {
      console.error('Error during proposal update:', error);
      return null;
    } 
  };

  const handleDecline = async () => {
    try {
      // Update the proposal status to 'DECLINED' with a reason comment
      const declineResponse = await updateProposal("DECLINED", {
        proposal_description: 'Reason decline: ' + formMethods.watch("comment"),
      }, proposal.type);

      // Check if the proposal was successfully declined
      if (declineResponse?.data?.status === 'DECLINED') {
        try {
          // If there's an orderID, initiate a PayPal refund
          if (proposal.orderID) {
            const refund = await refundPaypalPayment({
              params: proposal.orderID,
              body: { amount: proposal.totalPrice },
            }).unwrap();
             // generateInvoicing
          }

          // Create an invoice reflecting the refund
          const invoice = await createInvoice({  
            payerId: payerId,
            payerType: payerType,
            payerName: payerName,
            payerEmail: payerEmail,
            payerCountryDetails : '',
            price: declineResponse.data.totalPrice.toFixed(2),
            paymentType: 'paypal',
            targetProductType: declineResponse.data.type,
            targetProductId: declineResponse.data.id,
            orderId: proposal.orderID || 'on the first step',
            invoicingDescription: 'Proposal Declined',
            status: 'REFUND'
          });
          console.log("invoice created", invoice);

          const sendInvoicing = await generateInvoicing({   
            "fileFolder" : "invoices/"+invoice.data.type +"s",
            "fileName": "facture" + invoice.data.id +".pdf" , 
            "id"    : invoice.data.id ,
            "status": "Rembourssement",
            "date": now,
            "items": [
                {
                    "title": invoice.data.invoicingDescription,
                    "price": (invoice.data.price / 1.2 ).toFixed(2),
                    "tax": 20
                }
            ],
            "customer": {
                "summary": invoice.data.payerName,
                "address_line_1": invoice.data.payerCountryDetails,
                "email": invoice.data.payerEmail
            }});     

           await sendNotificationEmail(declineResponse.user.email, 'NOTIFICATION_PROPOSAL_DENIED')
           setLoading(false);

          console.log("send invoice s3 && email", sendInvoicing);
          window.location.href = `/projects`;      


          return { success: true };
        } catch (error) {
          console.error('Error during refund or invoicing:', error.message);
        }
      } else {
        console.error('Failed to decline the proposal:', declineResponse);
      }
    } catch (error) {
      console.error('Error updating proposal:', error.message);
    }

    return { success: false };
  };

  const handleActivateSubmit = async (orderID) => {

    const submitResponse = await updateProposal("VALIDATED", { orderID: orderID }, proposal.type);

    if (submitResponse && submitResponse.data && submitResponse.data.status === 'VALIDATED') {

     await sendNotificationEmail(submitResponse.user.email, 'NOTIFICATION_PROPOSAL_ACCEPTED')
     
     console.log('Mail Notifcation was sent .');
      
     return {
        success: true,
        url: 'projects',
        targetProductType: submitResponse.data.type,
        targetProductId: submitResponse.data.id,
        invoicingDescription: "VALIDATED the proposal",
      };
    }

    return { 
      success: false,
      targetProductType: proposal.type,
      targetProductId: proposal.id,
      invoicingDescription: "REFUND : error create proposal ", 
    };
  };

  const handleFinishSubmit = async (comment, starts) => {
    const submitResponse = await updateProposal("FINISHED", {}, proposal.type, {
      stars: starts,
      comment_text: comment,
    },
    );
    if (submitResponse && submitResponse.data && submitResponse.data.status === 'FINISHED') {
      try {
        await sendNotificationEmail(submitResponse.user.email, 'NOTIFICATION_PROPOSAL_FINISHED')
        return { success: true, url: 'projects' };
      } catch (error) {
        console.error('Error during message sending:', error.message);
        return { success: false };
      }
    }

    return { success: false };
  };

  return { handleDecline, handleActivateSubmit, handleFinishSubmit, loading, setLoading };
};

export default useHandleSubmitUpdateProposal;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getURL } from "../../../../../redux/api/uploads/uploadSlice"; 
import { useGetUserByIdQuery } from "../../../../../redux/api/users/userApi";
import { StackStyle } from "../../Formations.style";
import {
  CustomAvatarStyle,
  NameStyle,
  PriceStyle,
  SubtitleStyle,
} from "./UserAvatar.style";

function UserAvatar({ userId, price, user: userData, type }) {
  const { data } = useGetUserByIdQuery(userId);
  const user = userData ?? data?.data;
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if (userId) {
      getUrl();
    }
  }, [userId]); // Dependency on userId to trigger updates accordingly

  const getUrl = async () => {
    try {
      const url = await dispatch(
        getURL({
          location: `candidats/${userId}/image/`,
        })
      );

      if (url?.Contents && Array.isArray(url.Contents) && url.Contents.length > 0) {
        const extractedFileName = url.Contents[0].Key.split("/").pop(); // Safer extraction
        setFileName(extractedFileName);
      } else {
        console.warn("No valid image found for user:", userId);
      }
    } catch (error) {
      console.error("Error fetching URL:", error);
    }
  };

  return (
    <StackStyle direction="row" style={{ justifyContent: "space-between" }}>
      <StackStyle direction="row" spacing={1}>
        {type !== 'MARKETPLACE' && (
          <CustomAvatarStyle
            src={user?.image ?? `${process.env.REACT_APP_URL_BUCKET}/candidats/${userId}/image/${fileName}`}
            roundedCircle
            alt="User Avatar"
          />
        )}
        <StackStyle>
          {type === 'MARKETPLACE' ? (
            <NameStyle>ItGalaxy Product</NameStyle>
          ) : (
            <>
              <NameStyle>{`${user?.first_name} ${user?.last_name}`}</NameStyle>
              <SubtitleStyle>{user?.job}</SubtitleStyle>
            </>
          )}
        </StackStyle>
      </StackStyle>
      {type === 'MARKETPLACE' ? (
        <PriceStyle>As you Use</PriceStyle>
      ) : (
        <PriceStyle>${price}</PriceStyle>
      )}
    </StackStyle>
  );
}

export default UserAvatar;

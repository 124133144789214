import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import CustomAutoComplete from "../../../Inputs/CustomAutoComplete/CustomAutoComplete";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { StyledLabel } from "../TrainerForm/TraininerForm.style";
import { formConfig } from "./CreateApplication.constants";
import { ContentFormContainer } from "./CreateApplication.style";

export default function CreateApplication({ onValidate, onCancel }) {
  const { watch, setValue, trigger, control, formState: { errors } } = useFormContext();
  const [skills, setSkills] = useState([]);
  const [image, setImage] = useState(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  useEffect(() => {
    setValue('title', '', { shouldValidate: false });
    setValue('description', '', { shouldValidate: false });
    setValue('duration', '', { shouldValidate: false });
    setValue('skills', [], { shouldValidate: false });
    setValue('image', null, { shouldValidate: false });
  }, []);

  const watchTrainingTitle = watch('title');
  const watchTrainingDescription = watch('description');
  const watchTrainingDuration = watch('duration');
  const watchTrainingImage = watch('image');

  const handleSubmit = async () => {
    setHasAttemptedSubmit(true);
    const fieldsToValidate = ['title', 'description', 'duration', 'skills', 'image'];
    const isValid = await trigger(fieldsToValidate);
    if (isValid) {
      onValidate();
    }
  };

  const handleChange = (value, fieldName) => {
    if (!fieldName) return;
    setValue(fieldName, value, { shouldValidate: hasAttemptedSubmit });
  };

  const handleSkillsChange = (selectedSkills) => {
    setSkills(selectedSkills);
    setValue('skills', selectedSkills, { shouldValidate: hasAttemptedSubmit });
  };

  const handleImageChange = (file) => {
    try {
      if (file instanceof File) {
        setImage(file);
        setValue('image', file, { 
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true 
        });
        trigger('image');
      } else {
        setImage(null);
        setValue('image', null, { 
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
        trigger('image');
      }
    } catch (error) {
      console.error('Error in handleImageChange:', error);
    }
  };

  const getFieldError = (fieldName) => {
    if (!hasAttemptedSubmit) return null;
    return errors[fieldName]?.message;
  };

  return (
    <ContentFormContainer>
      <StyledLabel>1. Application title</StyledLabel>
      <GenericInput
        inputObject={{
          ...formConfig.title,
          fieldName: 'title'
        }}
        onChange={(e) => handleChange(e.target.value, 'title')}
        disabledForm={false}
        error={getFieldError('title')}
      />

      <StyledLabel>2. Application Description</StyledLabel>
      <GenericInput
        inputObject={{
          ...formConfig.description,
          fieldName: 'description'
        }}
        onChange={(e) => handleChange(e.target.value, 'description')}
        disabledForm={false}
        error={getFieldError('description')}
      />

      <StyledLabel>3. Application Duration (hours)</StyledLabel>
      <GenericInput
        inputObject={{
          ...formConfig.training,
          fieldName: 'duration'
        }}
        onChange={(e) => handleChange(e.target.value, 'duration')}
        disabledForm={false}
        error={getFieldError('duration')}
      />

      <StyledLabel>4. Application Skills</StyledLabel>
      <div className="mb-4">
        <CustomAutoComplete
          recieveSkills={handleSkillsChange}
          error={getFieldError('skills')}
        />
        {getFieldError('skills') && (
          <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
            {errors.skills.message}
          </div>
        )}
      </div>

      <div>
        <StyledLabel>5. Application Image</StyledLabel>
        <Controller
          name="image"
          control={control}
          defaultValue={null}
          rules={formConfig.image.config}
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <GenericInput
              inputObject={{
                ...formConfig.image,
                fieldName: 'image',
                config: {
                  ...formConfig.image.config,
                  onChange: handleImageChange
                }
              }}
              onChange={(file) => {
                onChange(file); 
                handleImageChange(file); 
              }}
              value={value}
              disabledForm={false}
              error={error?.message || getFieldError('image')}
            />
          )}
        />
      </div>

      <div style={{ float: "right", marginTop: "10%" }}>
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            !watchTrainingTitle ||
            !watchTrainingDescription ||
            !watchTrainingDuration ||
            !skills.length ||
            !watchTrainingImage
          }
          style={{ marginLeft: "15px" }}
          variant="primary"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </ContentFormContainer>
  );
}

import { useEffect, useRef, useState } from "react";
import DeployIcon from "../../../../assets/services/deploy.png";
import FleshIcon from "../../../../assets/services/flesh.png";
import FolderIcon from "../../../../assets/services/folder.png";
import MongoDBIcon from "../../../../assets/services/mongodb.png";
import OrgIcon from "../../../../assets/services/org.png";
import ServerIcon from "../../../../assets/services/server.png";
import SettingsIcon from "../../../../assets/services/settings.png";
import SqlIcon from "../../../../assets/services/sql.png";
import {
  Arrow,
  Container,
  Item,
  ItemContent,
  ItemIcon,
  ItemList,
  ItemProposalDash,
  ItemIconDash,
  ItemContentDash
} from "./ListServicesContainer.style";
import ModalAcceptProposals from "../../../../components/ModalITgalaxy/ModalAcceptProposals/ModalAcceptProposals";


const items = [

  {
    id: 2,
    icon: ServerIcon,
    title: "Serveurs Linux",
    description:
      "Optimisez vos infrastructures avec des solutions Linux robustes et sécurisées",
  },
  {
    id: 3,
    icon: OrgIcon,
    title: "Organisation: Board pour votre projet",
    description:
      "Planifiez, suivez et collaborez sur chaque étape de votre projet avec efficacité",
  },
  {
    id: 4,
    icon: FolderIcon,
    title: "Create repos Github pour le projet",
    description: "Simplify Your Project Management with Organized Repositories",
  },
  {
    id: 5,
    icon: MongoDBIcon,
    title: "Database MongoDB",
    description:
      "Gérez vos données avec une flexibilité et une évolutivité maximales",
  },
  {
    id: 6,
    icon: SqlIcon,
    title: "DataBase SQL",
    description:
      "Gérez et optimisez vos données avec des bases SQL puissantes et flexibles",
  },
  {
    id: 7,
    icon: DeployIcon,
    title: "Déployer et tester votre code en 2mins",
    description: "Simplifiez et accélérez vos processus de développement",
  },
  {
    id: 8,
    icon: SettingsIcon,
    title: "Tools & ItGalaxy Cloud",
    description: "Empower Your Workflow with Advanced Cloud Solutions",
  },
  {
    id: 9,
    icon: ServerIcon,
    title: "Serveurs Linux",
    description:
      "Optimisez vos infrastructures avec des solutions Linux robustes et sécurisées",
  },
  {
    id: 10,
    icon: OrgIcon,
    title: "Organisation: Board pour votre projet",
    description:
      "Planifiez, suivez et collaborez sur chaque étape de votre projet avec efficacité",
  },
  {
    id: 11,
    icon: FolderIcon,
    title: "Create repos Github pour le projet",
    description: "Simplify Your Project Management with Organized Repositories",
  },
  {
    id: 12,
    icon: MongoDBIcon,
    title: "Database MongoDB",
    description:
      "Gérez vos données avec une flexibilité et une évolutivité maximales",
  },
  {
    id: 13,
    icon: SqlIcon,
    title: "DataBase SQL",
    description:
      "Gérez et optimisez vos données avec des bases SQL puissantes et flexibles",
  },
  {
    id: 14,
    icon: DeployIcon,
    title: "Déployer et tester votre code en 2mins",
    description: "Simplifiez et accélérez vos processus de développement",
  },
  {
    id: 15,
    icon: SettingsIcon,
    title: "Tools & ItGalaxy Cloud",
    description: "Empower Your Workflow with Advanced Cloud Solutions",
  },
];

const ItemComponent = ({ theme }) => {
  const listRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showAcceptProposal,setshowAcceptProposal] = useState(false) ;


  const handelShowAcceptProposal  = () => {
    setshowAcceptProposal(true);
  };

  const handelCloseAcceptProposal = () => { 
    setshowAcceptProposal(false);
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollUp = () => {
    listRef.current.scrollBy({ top: -100, behavior: "smooth" });
  };

  const scrollDown = () => {
    listRef.current.scrollBy({ top: 100, behavior: "smooth" });
  };

  const scrollLeft = () => {
    listRef.current.scrollBy({ left: -100, behavior: "smooth" });
  };

  const scrollRight = () => {
    listRef.current.scrollBy({ left: 100, behavior: "smooth" });
  };
  return (
    <>
    <Container>
          <ItemProposalDash key='1' theme={theme} onClick={handelShowAcceptProposal}>
            <ItemIconDash theme={theme}>
              <img
                style={{
                  margin: "1px 20px 10px 0px",
                  filter: "invert(100%)",
                }}
                src={ServerIcon}
                width={20}
                height={20}
              />
              <h5>{"Dashboard Proposals"}</h5>
            </ItemIconDash>
            <ItemContentDash theme={theme}>
            </ItemContentDash>
          </ItemProposalDash>
      <Arrow
        className="up"
        onClick={isMobile ? scrollLeft : scrollUp}
        theme={theme}
      >
        <img src={FleshIcon} width={15} height={15} alt="flesh" />
      </Arrow>
      <ItemList ref={listRef}>
        {items.map((item) => (
          <Item key={item.id} theme={theme}>
            <ItemIcon theme={theme}>
              <img
                style={{
                  margin: "1px 10px 10px 0px",
                  filter: theme === "light" ? "invert(0%)" : "invert(100%)",
                }}
                src={item.icon}
                alt={item.title}
                width={20}
                height={20}
              />
              <h6>{item.title}</h6>
            </ItemIcon>
            <ItemContent theme={theme}>
              <p>{item.description}</p>
            </ItemContent>
          </Item>
        ))}
      </ItemList>
      <Arrow
        className="down"
        onClick={isMobile ? scrollRight : scrollDown}
        theme={theme}
      >
        <img
          src={FleshIcon}
          width={15}
          height={15}
          alt="flesh"
          style={{ transform: " rotateX(180deg)" }}
        />
      </Arrow>
    </Container>
    {showAcceptProposal && (
    <ModalAcceptProposals
          show={showAcceptProposal}
          closeModal={handelCloseAcceptProposal}
      />)}
    </>
  );
};

export default ItemComponent;

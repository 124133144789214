import { InputTypes } from "../../../Inputs/input.type";

const FILE_SIZE = 5 * 1024 * 1024; // 5MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const formConfig = {
  title: {
    inputType: InputTypes.TEXT,
    fieldName: "title",
    config: {
      required: "Title is required",
    },
  },
  description: {
    inputType: InputTypes.TEXTAREA,
    fieldName: "description",
    config: {
      required: "Description is required",
    },
  },
  duration: {
    inputType: InputTypes.NUMBER,
    fieldName: "duration",
    config: {
      required: "Duration is required",
    },
  },
  skills: {
    inputType: InputTypes.AUTOCOMPLETE,
    fieldName: "skills",
    config: {
      required: "Please select at least one skill",
      validate: {
        notEmpty: value => {
          if (!Array.isArray(value) || value.length === 0) {
            return "Please select at least one skill";
          }
          return true;
        },
        validSkills: value => {
          if (Array.isArray(value) && value.length > 0) {
            return value.every(skill => skill && typeof skill === 'object' && 'id' in skill && 'title' in skill) || 
              "Invalid skill format";
          }
          return true;
        }
      }
    },
  },
  image: {
    inputType: InputTypes.FILE,
    fieldName: "image",
    config: {
      required: "Image is required",
      validate: {
        fileExists: value => {
          console.log('Validating file exists:', value);
          if (!value || !(value instanceof File)) {
            return "Image is required";
          }
          return true;
        },
        fileSize: value => {
          console.log('Validating file size:', value);
          if (!value || !(value instanceof File)) return "Image is required";
          return value.size <= FILE_SIZE || "File is too large (max 5MB)";
        },
        fileFormat: value => {
          console.log('Validating file format:', value);
          if (!value || !(value instanceof File)) return "Image is required";
          return SUPPORTED_FORMATS.includes(value.type) || "Unsupported file format (jpg, jpeg, png only)";
        }
      }
    },
    acceptedFiles: SUPPORTED_FORMATS.join(","),
    maxSize: FILE_SIZE,
  },
};

import { jwtDecode } from "jwt-decode";
import ListingMyTrainer from "components/DashboardTrainingComponnents/TrainerComponnents/ListMyTrainerComponnents/ListingMyTrainer";
import { getTokenFromLocalStorage } from "../../core/helpers/storage";
import ParcoursForm from "components/DashboardTrainingComponnents/TrainerComponnents/TrainerForm/TrainerForm";
import { useState } from "react";
import SideBar from "../../components/SideBar/sideBar";

const DashboardTrainer = () => {
   
    const token = getTokenFromLocalStorage();
    const decodeToken = token ? jwtDecode(token) : null;
    const role = decodeToken ? decodeToken.role : null;
    const id = decodeToken ? decodeToken.id : null;
    const [trainerForm , setTrainerForm] = useState(false);
  
  
    return (
       <div style={{ display: "flex" ,  overflowY: 'hidden'  ,  overflowX: 'hidden'}} >
        <SideBar path={'/trainer'} isLoggedIn={true} role={role} id={id} style={{ overflowY: 'hidden'}}  />
        {!trainerForm ? (
         <ListingMyTrainer setTrainerForm={setTrainerForm} /> ) : (
        
          <ParcoursForm type='training' />

         )}   
       
        </div>
    );
}
export default DashboardTrainer;


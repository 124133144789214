import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import ChatbotTextEffect from "../../ChatbotTextEffect/ChatbotTextEffect";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./FormItemChatbot.contants";
import { useJoinusContext } from '../../allContext/joinusContext';
import Register from "components/Authentification/modals/register"; 
import {
  CenteredInlineFlexMB20PxContainer,
  InlineBlockContainer,
  InlineFlexContainer,
  ML20PxBoldContainer,
  RightButtonContainer,
  Vh90WidthContainer,
  Textbutton,
  Answer
} from "./FormItemChatbot.style";
import MultiSelectCompoent from "../../Inputs/CustomMultiSelectAutoComplet/multiSelect";
import { skillsData } from "../../../data/skillData";

export const FormItemChatbot = ({
  img,
  title,
  question,
  placeholder,
  items,
  index,
  chattype,
  formValues,
  setFormValues,
  onComplete,
  onNext,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [renderedElements, setRenderedElements] = useState([]);
  const [answers,setAnswers] =useState([]);
  const [showInput, setShowInput] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [textTyped, setTextTyped] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([
  ]);
  const { setRedirect } = useJoinusContext();
  const handleChangeMultiSelect = (selected) => {
    setSelectedOptions(selected);
  };
  const [filterValues, setFilterValues] = useState({ "skills": [] });

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
  });

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const handleSkillsChange = (selectedSkills) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      skills: selectedSkills.map((skill) => skill.value),
    }));
  };

  const handleModalRegister = () => {
    window.location.href = `/about-us`;
  }

 
  const onSubmit = () => {
      switch (chattype) {
            case "Compose your Team":
              return (setRedirect(
              <Register
                openModalRegister={true}
                setOpenModalRegister={true}
                openModalLoginDefault={false}
                handleModalRegister={handleModalRegister}
                switchBetweenModals={true}
                proxy={"composeyourteam"}
                freelance = {false}
                recruiter = {false}
                buisness  = {true}
                defaultkey = {"entreprise"}

              /> ));
            case "Share your project":
                return (setRedirect(
              <Register
                openModalRegister={true}
                  setOpenModalRegister={true}
                  openModalLoginDefault={false}
                  handleModalRegister={handleModalRegister}
                  switchBetweenModals={true}
                  proxy={"shareProject"}
                  freelance = {false}
                  recruiter = {false}
                  buisness  = {true}
                  defaultkey = {"entreprise"}
                />  ));
            case "Share a Jobs":
                return (setRedirect(
              <Register
                  openModalRegister={true}
                  setOpenModalRegister={true}
                  openModalLoginDefault={false}
                  handleModalRegister={handleModalRegister}
                  switchBetweenModals={true}
                  proxy={"shareJob"}
                  freelance = {false}
                  recruiter = {true}
                  buisness  = {false}
                  defaultkey = {"recruiter"}
                /> ));    
          }
   }; 

  const handleChange = (e) => {
    
    if (index === 0 ) {
     setFormData(prevFormData => ({
      ...prevFormData,
      title: e
    }));
    } else if (index === 1 ) {
     setFormData(prevFormData => ({
      ...prevFormData,
      description: e
     }));
    } else if ( index === 2 ) {
     setFormData(prevFormData => ({
      ...prevFormData,
      price: e
     }));
    }
  }
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        setAnswers((prev) => [
          ...prev,    
          <InlineFlexContainer key={`response-${prev.length}`}>
            <ML20PxBoldContainer>
            <b>[Vous]:</b>
              <Vh90WidthContainer>
               <Answer>
              {index === 0
                    ? formMethods.getValues("title")
                    : index === 1
                    ? formMethods.getValues("description")
                    : index === 2
                    ? formMethods.getValues("price")+ '$'
                    : ""}
               </Answer>
                </Vh90WidthContainer>

              </ML20PxBoldContainer>
          </InlineFlexContainer>,
        ]);
    
          setIsEditable(true);
          onNext();
      }
    },
   [onNext]
  );

  const handleStepCompletion = useCallback((step) => {
    setCurrentStep(step + 1);
  }, []);

  const handleGenerateWithAI = useCallback(() => {
    // Save current input to formValues
   /* const currentValue = formMethods.getValues(
      index === 0 ? "title" : "description"
    );
    setFormValues((prevValues) => ({
      ...prevValues,
      [index === 0 ? "title" : "description"]: currentValue,
    }));

    // Move to next question
    onNext(); */
  }, [formMethods, index, setFormValues, onNext]);

  useEffect(() => {
    setCurrentStep(0);
    setShowInput(true);
  }, [index]);

  useEffect(() => {
    setRenderedElements([]);
    if (currentStep >= 0) {
      setRenderedElements((prev) => [
        ...prev,
        <CenteredInlineFlexMB20PxContainer key="title">
          <img src={img} width={50} height={50} alt="chatbot" />
          <ChatbotTextEffect
            message={title}
            onComplete={() => handleStepCompletion(0)}
          />
        </CenteredInlineFlexMB20PxContainer>,
      ]);
    }
    if (currentStep >= 1) {
      setRenderedElements((prev) => [
        ...prev,
        <b key="question">
          <ChatbotTextEffect
            message={question}
            onComplete={() => handleStepCompletion(1)}
          />
        </b>,
      ]);
    }

    if (currentStep >= 2 && currentStep < 3 && showInput) {
      setRenderedElements((prev) => [
        ...prev,
<InlineFlexContainer key="inputContainer">
  <b>[Vous]:</b>
  <Vh90WidthContainer>
    <FormProvider {...formMethods}>
      <div style={{ marginLeft: "20px" }} onKeyDown={handleKeyPress}>
        {index !== items.length - 1 ? (
          <div>
            <div style={{ marginBottom: "20px" }}>
              <GenericInput
                key={`input-${index}`}
                inputObject={
                  index === 0
                    ? formConfig.title
                    : index === 1
                    ? formConfig.description
                    : index === 2
                    ? formConfig.price
                    : null
                }
                onChange={handleChange}
                disabledForm={isEditable}
                name={
                  index === 0
                    ? "title"
                    : index === 1
                    ? "description"
                    : index === 2
                    ? "price"
                    : ""
                }
              />
       

            </div>
            <b>{placeholder}</b>
          </div>
        ) : (
          <MultiSelectCompoent
            isMulti={true}
            options={skillsData.map((skill) => ({
              value: skill.title,
              label: skill.title,
            }))}
            onChange={handleSkillsChange}
          />
        )}
      </div>
    </FormProvider>
  </Vh90WidthContainer>
</InlineFlexContainer>,
      ]);    
    }
  }, [
    currentStep,
    handleStepCompletion,
    img,
    title,
    question,
    index,
    formMethods,
    handleKeyPress,
    handleChange,
    handleGenerateWithAI,
    showInput,
  ]);

  return (
    <InlineBlockContainer>
      {renderedElements}
      {answers}
      {index === items.length - 1 && filterValues?.skills?.length > 0 && (
        <div>
          <hr />
          <Button
            onClick={onSubmit}
            style={{
              width: "100%",
              padding: "5px",
              backgroundColor: "black",
            }}
          >
            Next
          </Button>
        </div>
      )}
     </InlineBlockContainer>
  );
};

import { InputTypes } from "../../Inputs/input.type";
import { countriesData }  from "../../../data/countriesData";
import {countryLengthMapData} from "data/countryLengthMapData"; 

const fetchCountries = async () => {
  try {
    // Map fetched data to the desired structure
    const countriesArray = countryLengthMapData.map((skill, index) => ({
      id: index + 1,
      title: skill.title, // Assuming the skill object has a name property
      value: skill.title,    // Using the skill ID as the value
    }));
    return(countriesArray);
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
};

const fetchCurencies = async () => {
  try {
    // Map fetched data to the desired structure
    const currencyArray = countriesData.map((skill, index) => ({
      id: index + 1,
      title: skill.currency, // Assuming the skill object has a name property
      value: skill.currency,    // Using the skill ID as the value
    }));
    return(currencyArray);
  } catch (error) {
    console.error("Error fetching curencies:", error);
  }
};

const locations   = await fetchCountries();
const curencies   = await fetchCurencies();


export const formConfig = {
  owner_bank_account: {
    inputType: InputTypes.TEXT,
    fieldName: "owner_bank_account",
    label: "",
    placeholder: "Add Account Owner Name",
  },
  iban: {
    inputType: InputTypes.TEXT,
    fieldName: "iban",
    label: "",
    placeholder: "Add your IBAN",
  },

  bic: {
    inputType: InputTypes.TEXT,
    fieldName: "bic",
    label: "",
    placeholder: "Add your BIC",
  },

  bank_country: {
    inputType: InputTypes.MULTISELECT,
    fieldName: "bank_country",
    isMulti: false,
    config: {
      required: {
        value: true,
        message: "This field is required."  // Optional message for required rule
      },
      },
     placeholder:
     "Select Bank Country",
     defaultValue:[],
      options: locations
    },  


    currency: {
      inputType: InputTypes.MULTISELECT,
      fieldName: "currency",
      isMulti: false,
      config: {
        required: {
          value: true,
          message: "This field is required."  // Optional message for required rule
        },
        placeholder:
          "",
       },
       placeholder:
       "Select Currency",
       defaultValue:[],
        options: curencies
      }, 

};

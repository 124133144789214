import { jwtDecode } from "jwt-decode";
import SideBar from "components/SideBar/sideBar";
import { getTokenFromLocalStorage } from "core/helpers/storage";
import { Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { MessageContainer } from "./Style"; // Assuming MessageContainer is a styled component
import { useGetMyProductQuery } from "../../redux/api/training/trainingApi";
import EditTrainingDetail from "./EditTrainingDetail/EditTrainingDetail";
import EditProductDetail from "./EditProductDetail/EditProductDetail";


const MyProductEdit = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tokenProduct = queryParams.get("token");
  const userToken = getTokenFromLocalStorage();
  const decodeUserToken = userToken ? jwtDecode(userToken) : null;
  const userId = decodeUserToken ? decodeUserToken.id : null;
  const role = decodeUserToken ? decodeUserToken.role : null;

  const {
    data: myproduct,
    error,
    isLoading,
    refetch
  } = useGetMyProductQuery({ tokenProduct, userId });

  return (
    <div
      style={{
        display: "flex",
        overflowY: "hidden",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <SideBar
        path={"/trainer"}
        isLoggedIn={true}
        role={role}
        id={userId}
        style={{ overflowY: "hidden" }}
      />
      
      {/* Loading State */}
      {isLoading && (
        <MessageContainer>
          <Spinner animation="border" role="status" style={{ marginBottom: "10px" }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <span>Loading...</span>
        </MessageContainer>
      )}

      {/* Error State */}
      {error && (
        <MessageContainer>
          <span>Error loading product data. Please try again later.</span>
        </MessageContainer>
      )}

    {/* No Product Data Available */}
    {!isLoading && !error && (!myproduct || !myproduct.data) && (
        <MessageContainer>
          <span>No product data available.</span>
        </MessageContainer>
      )}



      {myproduct && myproduct.data && myproduct.data.type === "VIDEOSTRAINING" && (
        <EditTrainingDetail data={myproduct.data} />
      )}

      {myproduct && myproduct.data && myproduct.data.type === "APPLICATION" && (
        <EditProductDetail data={myproduct.data} refetch={refetch} />
      )}
    </div>
  );
};

export default MyProductEdit;
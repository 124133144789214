import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./ModalAddBankDetails.constants";
import {
  AddBankAccountButton,
  TitleStyle,
  StyledFormText
} from "./Style";
import { Spinner } from "react-bootstrap";
import {countryLengthMapData} from "data/countryLengthMapData"; 
import { useCreateWithdrawalMethodMutation } from "../../../redux/api/withdrawal/withdrawalApi";
import toast from "react-hot-toast";


const ModalAddBankDetails = (props) => {
  const formMethods = useForm({ mode: "onChange", shouldFocusError: true });
  const { clearErrors, formState: { errors } } = formMethods;
  const [lenghtOwnerBankAccount , setLenghtOwnerBankAccount] = useState(0);
  const [lenghtIban , setLenghtIban] = useState(0);
  const [lenghtCountry , setLenghtCountry] = useState(0);
  const [currency , setCurrency] = useState("");
  const [createWidthowMethod] = useCreateWithdrawalMethodMutation();
  const [error , setError] = useState("");
  const [loading , setLoading] = useState(false);

  const [formData, setFormData] = useState({
    userId: props.id,
    typeUser: props.role,
    nameUser: props.nameUser,
    emailUser : props.emailUser,
    owner_bank_account: "",
    bank_country: "",
    currency: "",
    iban: "",
  });

  useEffect(() => {
    const countryCode = formData.bank_country; // Get current bank_country value
    const countryData = countryLengthMapData.find(item => item.title === countryCode); // Find country data
    const currencyValue = countryData ? countryData.currency : ""; // Get currency if found, else set it to empty
    setCurrency(currencyValue); 
    setFormData(prevData => ({
      ...prevData,
      currency: currencyValue,
    }));
  }, [formData.bank_country]); 
 

  const handleAddCreateWithdrawalMethod = async () => {
    setLoading(true) ;
    try {
        await createWidthowMethod(formData).unwrap(); // Assuming createWithdrawalMethod returns a promise.
        toast.success("Create Withdrawal Method Success", {
            position: "top-center",
            autoClose: 3000,
        });
        props.refetch() ;
        props.close() ;
    } catch (e) {
        console.error("Error creating withdrawal method:", e.data.error); // Log the error for debugging
        setError("Error creating withdrawal method: "+ e.data.error) ;
    } finally {
      setLoading(false) ;

    }
};


  const handleChangeOwerBankAccount = (selectedOptions) => {
    setLenghtOwnerBankAccount(selectedOptions);
    setFormData(prevFormData => ({
      ...prevFormData,
      owner_bank_account: selectedOptions
    }));
  };
  const handleChangeIban = (selectedOptions) => {
    setLenghtIban(selectedOptions);
     setFormData(prevFormData => ({
      ...prevFormData,
      iban: selectedOptions
    }));
  };

  const handleChangeBankCountry = (selectedOptions) => {
    setLenghtCountry(selectedOptions.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      bank_country: selectedOptions.value
    }));
  };

  const RenderHeader = <TitleStyle>Add Bank Account</TitleStyle>;

  const RenderFooter = (
    <AddBankAccountButton
      onClick={handleAddCreateWithdrawalMethod}
      disabled={lenghtOwnerBankAccount === 0 || lenghtIban === 0 || lenghtCountry === 0}
    >
      Add Bank Account
    </AddBankAccountButton>
  );


  const RenderBody = (
    <>
    {loading && (<Spinner /> ) }

      {error !== "" && (<p style={{color: "red"}}>  {error} </p>)} 
      <FormProvider {...formMethods}>
        <StyledFormText> Account Owner </StyledFormText>
        <GenericInput
          inputObject={{
            ...formConfig.owner_bank_account,
            label: "owner_bank_account",
          }}
          onChange={handleChangeOwerBankAccount}
          disabledForm={false}
        />
        <StyledFormText> Country Bank </StyledFormText>
        <GenericInput
          inputObject={{
            ...formConfig.bank_country,
            label: "bank_country",
            validation: { required: "Country bank is required." },
          }}
          onChange={handleChangeBankCountry}
          disabledForm={false}
        />
        <StyledFormText> IBAN </StyledFormText>
        <GenericInput
          inputObject={{
            ...formConfig.iban,
            label: "iban",
          }}
          onChange={handleChangeIban}
          disabledForm={false}
        />
       </FormProvider>


    </> );


  return (
    <ModalComponent
      show={props.show}
      closeModal={props.close}
      header={RenderHeader}
      body={RenderBody}
      footer={RenderFooter}
      bodyPadding={"20px 20px 20px 20px"}
      Height={"37vw"}
      minWidth={"70vw"}
      footerpaddingtop={"0"}
      footerpaddingbottom={"0"}
    />
  );
};

export default ModalAddBankDetails;

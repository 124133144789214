import  DashboardTrainerPurchasedComponnents from "components/DashboardTrainingComponnents/TrainingsComponnents/DashboardTrainingPurchasedComponnents/DashboardTrainingPurchasedComponnents";
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formConfig } from "./VideosTrainingsComponnents.config";
import {
  StyleBarSearch,
  Container,
  ContainerWrapper,
  RowStyle,
  SearchBar,
  StyleBarSearchSkills,
  Wrapper,
  StyleLineCount,
  ProductLabelsContainer,
  ViewLabelProducts,
  ViewLabelMyProducts,
  StyleProductsCount,
  NewProductButtonContainer,
  StyleCount1,
  StyleCount,
  TopContainer
} from "./VideosTrainingsComponnents.style";
import { useGetTrainingsQuery } from "../../../../redux/api/training/trainingApi";

const VideosTrainingsComponnents = (props) => {
  const [animateDirection, setAnimateDirection] = useState("inRight");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [purchasedProducts, setPurchasedProducts] = useState(false);

  const {
    data: productsData,
    error: productsDataError,
    isLoading: productsDataLoading,
    refetch
  } = useGetTrainingsQuery(
    `?page=${currentPage}&limit=${recordsPerPage}${searchQuery}`,
  );

  const handelPurchasedProducts = () => {
    setPurchasedProducts(true);
  };

  const handleNavigate = (token) => {
      window.location.href = `/videosTrainings/${token}` ; 
  };

  const formMethods = useForm({});



  if (props.isLoading) {
    return <div>Loading...</div>;
  }

  if (props.error) {
    return <div>Error loading data: {props.error.message}</div>;
  }

  return (
    !purchasedProducts ? (
      <Wrapper>
        <ContainerWrapper className="container">
          <SearchBar>
            <FormProvider {...formMethods}>
              <StyleBarSearch>
                <GenericInput
                  inputObject={{
                    ...formConfig.title,
                    label: "filterType"
                  }}
                  onChange={(e) => props.setType(e.target.value)}
                />
              </StyleBarSearch>
              <StyleBarSearchSkills>
                <GenericInput
                  inputObject={{
                    ...formConfig.skills,
                    label: "filterType"
                  }}
                />
              </StyleBarSearchSkills>
              {props.role === "CANDIDAT" && (
                <NewProductButtonContainer onClick={handelPurchasedProducts}>
                  {"Create a product"}
                </NewProductButtonContainer>
              )}
            </FormProvider>
          </SearchBar>
          <TopContainer>
            <Container>
              <StyleLineCount>
                <ProductLabelsContainer>
                  <ViewLabelProducts>Trainings</ViewLabelProducts>
                  <ViewLabelMyProducts onClick={handelPurchasedProducts}>Purchased Trainings</ViewLabelMyProducts>
                </ProductLabelsContainer>
                <StyleProductsCount>
                  {productsData && (
                    <StyleCount1>
                      <StyleCount>{productsData?.pagination.totals}</StyleCount> Trainings found
                    </StyleCount1>
                  )}
                </StyleProductsCount>
              </StyleLineCount>
            </Container>
            <RowStyle spacing={3}>
              {productsData?.data?.map((value) => (
                <FormationsCard
                  key={value.id}
                  formation={{
                    id: value.id,
                    title: value.title,
                    description: value.description,
                    image: value.image || value.thumbnail,
                    price: value.price,
                    skills:  value.skills,
                  }}
                  animateDirection={animateDirection}
                  category={value.category}
                  onClick={() => handleNavigate(value.display)}
                />
              ))}
            </RowStyle>
          </TopContainer>
        </ContainerWrapper>
      </Wrapper>
    ) : (
    
    <DashboardTrainerPurchasedComponnents  setPurchasedProducts={setPurchasedProducts} />
    )
  );
};

export default VideosTrainingsComponnents;
import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCreatePaymentMutation, useCapturePaypalPaymentMutation, useRefundPaypalPaymentMutation , useRefundWalletPaymentMutation} from "../../../redux/api/payment/paymentApi";
import { useAddInvoicingMutation } from '../../../redux/api/invoicing/invoicingApi';
import { LuWallet } from "react-icons/lu";
import { getTokenFromLocalStorage } from 'core/helpers/storage';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {
    StyledContainer, Section, SubmitButton,
    WalletDetails, WalletBalance, BalanceTitle, BalanceAmount
} from './Payment.style';
import { getBalanceFromLocalStorage } from '../../../core/helpers/storage';
import { useGenrateInvoicingMutation } from '../../../redux/api/genrateInvoicing/genrateInvoicingApi';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const Payment = (props) => {

    const [showPaymentWallet, setShowPaymentWallet] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState("inprogress");
    const [createPayment] = useCreatePaymentMutation();
    const [capturePaypalPayment] = useCapturePaypalPaymentMutation();
    const [createInvoice] = useAddInvoicingMutation();
    const [refundPaypalPayment] = useRefundPaypalPaymentMutation();
    const [refundWalletPayment] = useRefundWalletPaymentMutation();
    const [generateInvoicing] = useGenrateInvoicingMutation();


    const balance = getBalanceFromLocalStorage();

    const token = getTokenFromLocalStorage();
    const decodedToken = token ? jwtDecode(token) : null;
    const payerId = decodedToken?.id;
    const payerType  = decodedToken?.role;
    const payerEmail = decodedToken?.email;
    const payerName = payerType === 'ENTREPRISE' ? decodedToken?.username :  decodedToken?.name + " "  + decodedToken?.first_name ;
    const now = Date.now(); // returns the number of milliseconds since Jan 1, 1970

    const navigate = useNavigate();
    const [urlNavigate, setUrlNavigate] = useState();

    const handleWalletPayment = () => setShowPaymentWallet(!showPaymentWallet);
    const handlePayPalPayment = () => setShowPaymentWallet(false);


    useEffect(() => {
        if (paymentStatus === 'success' && urlNavigate) {
            const timer = setTimeout(() => {
               window.location.href = `${urlNavigate}`;
            }, 1000);
        }
    }, [paymentStatus, urlNavigate, navigate]);


    const handleWalletSubmit = async () => {
        setLoading(true);
        let productCreationSuccess;
        try {
            const orderData = await createPayment({
                amount: props.totalCost,
                payerId,
                payerType,
                paymentType: 'wallet'
            }).unwrap();

            if (orderData.status === "success") {
                productCreationSuccess = await props.onSubmit( "wallet" + orderData.id , props.totalCost );
           } else {
               const refund = await refundWalletPayment({
                    amount: props.totalCost,
                    payerId,
                    payerType,
                }).unwrap();
                  setPaymentStatus('error');
            }
        } catch (error) {
            handleError('Payment capture failed. Please try again.');
            setPaymentStatus('error');
        } finally {
            setPaymentStatus('success');
            setUrlNavigate(productCreationSuccess.url);
            setLoading(false);
        }
    };


    const handleError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
    };


    return (
        <StyledContainer>
            {paymentStatus === 'inprogress' && (
                <Section>
                    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID, currency: "EUR" }}>
                        <div style={{
                            border: '1px solid #eaeaea',
                            borderRadius: '8px',
                            padding: '20px',
                            backgroundColor: '#f9f9f9',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center'
                        }}>
                            <h3 style={{ marginBottom: '15px' }}> {props.title} </h3>
                            <p style={{ marginBottom: '10px', color: '#666' }}>
                                {props.note}
                            </p>

                            {payerType !== "ENTREPRISE" && (
                                <SubmitButton
                                    onClick={handleWalletPayment}
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginBottom: '10px',
                                        fontFamily: 'Inter',
                                        fontWeight: '501'
                                    }}
                                >
                                    <LuWallet /> Pay with Wallet
                                </SubmitButton>
                            )}
                            <PayPalButtons
                                style={{
                                    layout: "vertical",
                                    color: "gold",
                                    shape: "rect",
                                    label: "paypal",
                                }}
                                createOrder={async () => {
                                    handlePayPalPayment();
                                    setLoading(true);
                                    try {
                                        const orderData = await createPayment({
                                            amount: props.totalCost,
                                            payerId,
                                            payerType,
                                            paymentType: 'paypal'
                                        }).unwrap();

                                        if (orderData?.id) {
                                            return orderData.id;
                                        } else {
                                            const errorDetail = orderData?.details?.[0];
                                            const errorMessage = errorDetail
                                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                                : JSON.stringify(orderData);
                                            throw new Error(errorMessage);
                                        }
                                    } catch (error) {
                                        console.error(error);
                                        handleError('Failed to create PayPal order. Please try again.');
                                        setLoading(false);
                                        setPaymentStatus('error');
                                    }
                                }}
                                onApprove={async (data, actions) => {
                                    setLoading(true);
                                    try {

                                        const orderData = await capturePaypalPayment(data.orderID).unwrap();
                                        const errorDetail = orderData?.details?.[0];
                                        let invoice ;
                                        let productCreationSuccess;
                                        let sendInvoicing ;

                                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                            return actions.restart();
                                        }

                                        switch (orderData.status) {
                                            case "COMPLETED":
                                                productCreationSuccess = await props.onSubmit( orderData.purchase_units[0].payments.captures[0].id , props.totalCost );
                                                if (productCreationSuccess.success) {
                                                    invoice = await createInvoice({
                                                        payerId,
                                                        payerType,
                                                        payerName,
                                                        payerEmail,
                                                        payerCountryDetails : orderData.payer.address.country_code,
                                                        price: props.totalCost,
                                                        paymentType: 'paypal',
                                                        targetProductType: productCreationSuccess.targetProductType,
                                                        targetProductId: productCreationSuccess.targetProductId,
                                                        orderId: orderData.purchase_units[0].payments.captures[0].id,
                                                        invoicingDescription: productCreationSuccess.invoicingDescription
                                                    });

                                                    console.log("invoice created", invoice);
                                             
                                                    sendInvoicing = await generateInvoicing({        
                                                         "fileFolder" : "invoices/"+invoice.data.type +"s",
                                                         "fileName": "facture" + invoice.data.id +".pdf" , 
                                                         "id"    : invoice.data.id ,
                                                         "status": "Payé",
                                                         "date": now,
                                                         "items": [
                                                             {
                                                                 "title": invoice.data.invoicingDescription,
                                                                 "price": (invoice.data.price / 1.2 ),
                                                                 "tax": 20
                                                             }
                                                         ],
                                                         "customer": {
                                                             "summary": invoice.data.payerName,
                                                             "address_line_1": invoice.data.payerCountryDetails,
                                                             "email": invoice.data.payerEmail
                                                         }});      

                                                      console.log("send invoice s3 && email", sendInvoicing);


                                                    setUrlNavigate(productCreationSuccess.url);
                                                    setPaymentStatus('success');
                                                    break;
                                                } else {
                                                    await refundPaypalPayment({
                                                        params: orderData.purchase_units[0].payments.captures[0].id,
                                                        body: { amount: props.totalCost },
                                                    }).unwrap();

                                                    invoice = await createInvoice({
                                                        payerId,
                                                        payerType,
                                                        payerName,
                                                        payerEmail,
                                                        payerCountryDetails : orderData.payer.address.country_code,
                                                        price: props.totalCost,
                                                        paymentType: 'paypal',
                                                        targetProductType: productCreationSuccess.targetProductType,
                                                        targetProductId: productCreationSuccess.targetProductId,
                                                        orderId: orderData.purchase_units[0].payments.captures[0].id,
                                                        invoicingDescription: productCreationSuccess.invoicingDescription,
                                                        status: "REFUND"
                                                    });
                                                    console.log("invoice created", invoice);
                                           
                                                    sendInvoicing = await generateInvoicing({          "fileFolder" : "invoices/"+invoice.data.type +"s",
                                                        "fileName": "facture" + invoice.data.id +".pdf" , 
                                                        "id"    : invoice.data.id ,
                                                        "status": "Rembourssement",
                                                        "date": now,
                                                        "items": [
                                                            {
                                                                "title": invoice.data.invoicingDescription,
                                                                "price": (invoice.data.price / 1.2 ),
                                                                "tax": 20
                                                            }
                                                        ],
                                                        "customer": {
                                                            "summary": invoice.data.payerName,
                                                            "address_line_1": invoice.data.payerCountryDetails,
                                                            "email": invoice.data.payerEmail
                                                        }});     

                                                    console.log("send invoice s3 && email", sendInvoicing);

                                                }
                                            case "PENDING":
                                            case "DENIED":
                                            case "EXPIRED":
                                            case "FAILED":
                                                handleError(`Payment failed with status: ${orderData.status}. Please check with PayPal for more details.`);
                                                setPaymentStatus('error');
                                                break;

                                            default:
                                                handleError('Unknown payment status. Please try again.');
                                                setPaymentStatus('error');
                                        }
                                    } catch (error) {
                                        handleError('Payment capture failed. Please try again.');
                                        console.error('There was a problem with your fetch operation:', error);
                                        setPaymentStatus('error');
                                    } finally {
                                        setLoading(false);
                                    }
                                }}

                                onError={(err) => handleError(`An error occurred during payment processing: ${err.message}`)}
                            />
                            {loading && <p>Loading, please wait...</p>}
                        </div>
                    </PayPalScriptProvider>
                    {showPaymentWallet && (
                        <Section style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px'
                        }}>
                            <WalletDetails>
                                <WalletBalance>
                                    <BalanceTitle>Balance in euro</BalanceTitle>
                                    <BalanceAmount>{balance}</BalanceAmount>
                                </WalletBalance>
                            </WalletDetails>
                            <SubmitButton
                                onClick={handleWalletSubmit}
                                style={{
                                    backgroundColor: '#007BFF',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    marginTop: '10px',
                                    fontFamily: 'Inter',
                                    fontWeight: '500',
                                    width: '50%',
                                }}
                            >
                                Pay with Wallet
                            </SubmitButton>
                        </Section>
                    )}
                </Section>)}
            {/* Success/Error Messages */}
            {paymentStatus === 'success' && (
                <div style={{ paddingLeft: '150px', fontWeight: '501', color: 'green', marginBottom: '15px', fontFamily: 'Inter', fontSize: '30px' }}>
                    Payment processed successfully!
                </div>
            )}
            {paymentStatus === 'error' && (
                <div style={{ paddingLeft: '150px', fontWeight: '501', color: 'red', marginBottom: '15px', fontFamily: 'Inter', fontSize: '30px' }}>
                    Payment capture failed. Please try again.
                </div>
            )}
        </StyledContainer>
    );
};

export default Payment;

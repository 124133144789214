import FirstEnterpriseSliderImage from "../../assets/images-webp/enterprise-slider-image-1.webp";
import SecondEnterpriseSliderImage from "../../assets/images-webp/silderhome2.webp";
import ThirdEnterpriseSliderImage from "../../assets/images-webp/sliderhome3.webp";
import FirstFreeLancerSliderImage from "../../assets/images-webp/sliderHome4.png";
import SecondFreeLancerSliderImage from "../../assets/images-webp/sliderHome5.png";
import ThirdFreeLancerSliderImage from "../../assets/images-webp/sliderHome6.webp";

export const HOME_SLIDER_COMPANY_CONTENTS = [
  {
    backgroundImage: FirstEnterpriseSliderImage,
    title: `Make your buissness real with the best ItWorks.`,
    text: ``,
    buttonLabel: `Get Started`,
    buttonUrl: "/choix",
    id: 1,
  },
  {
    backgroundImage: SecondEnterpriseSliderImage,
    title: `ItGalaxy provide a Marketplace with all you need.`,
    text: ``,
    buttonLabel: `Get Started`,
    id: 2,
    buttonUrl: "/choix",
  },
  {
    backgroundImage: ThirdEnterpriseSliderImage,
    title: `Hire the best IT Workers around the word without fees.                               `,
    text: ``,
    buttonLabel: `Get Started`,
    id: 3,

    buttonUrl: "/choix",
  },
];

export const HOME_SLIDER_FREELANCE_CONTENTS = [
  {
    backgroundImage: FirstFreeLancerSliderImage,
    title: `Make a lot of Money with ItGalaxy on IT Works without fees.`,
    text: ``,
    buttonLabel: `Get Started`,
    id: 4,
    buttonUrl: "/choix",
  },
  {
    backgroundImage: SecondFreeLancerSliderImage,
    title: `Share your It knowledge with ItGalaxy `,
    text: ` .`,
    buttonLabel: `Get Started`,
    id: 5,
    buttonUrl: "/choix",
  },
  {
    backgroundImage: ThirdFreeLancerSliderImage,
    title: `Find your it happiness `,
    text: ``,
    buttonLabel: `Get Started`,
    id: 6,
    buttonUrl: "/choix",
  },
];

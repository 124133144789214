export const countriesData = [
  { id: 1, title: "China", currency: "CNY" }, // Renminbi
  { id: 2, title: "India", currency: "INR" }, // Indian Rupee
  { id: 3, title: "United States", currency: "USD" }, // US Dollar
  { id: 4, title: "Indonesia", currency: "IDR" }, // Indonesian Rupiah
  { id: 5, title: "Pakistan", currency: "PKR" }, // Pakistani Rupee
  { id: 6, title: "Brazil", currency: "BRL" }, // Brazilian Real
  { id: 7, title: "Nigeria", currency: "NGN" }, // Nigerian Naira
  { id: 8, title: "Bangladesh", currency: "BDT" }, // Bangladeshi Taka
  { id: 9, title: "Russia", currency: "RUB" }, // Russian Ruble
  { id: 10, title: "Mexico", currency: "MXN" }, // Mexican Peso
  { id: 11, title: "Japan", currency: "JPY" }, // Japanese Yen
  { id: 12, title: "Ethiopia", currency: "ETB" }, // Ethiopian Birr
  { id: 13, title: "Philippines", currency: "PHP" }, // Philippine Peso
  { id: 14, title: "Egypt", currency: "EGP" }, // Egyptian Pound
  { id: 15, title: "Vietnam", currency: "VND" }, // Vietnamese Dong
  { id: 16, title: "DR Congo", currency: "CDF" }, // Congolese Franc
  { id: 17, title: "Turkey", currency: "TRY" }, // Turkish Lira
  { id: 18, title: "Iran", currency: "IRR" }, // Iranian Rial
  { id: 19, title: "Germany", currency: "EUR" }, // Euro
  { id: 20, title: "Thailand", currency: "THB" }, // Thai Baht
  { id: 21, title: "United Kingdom", currency: "GBP" }, // British Pound
  { id: 22, title: "France", currency: "EUR" }, // Euro
  { id: 23, title: "Italy", currency: "EUR" }, // Euro
  { id: 24, title: "Tanzania", currency: "TZS" }, // Tanzanian Shilling
  { id: 25, title: "South Africa", currency: "ZAR" }, // South African Rand
  { id: 26, title: "Myanmar", currency: "MMK" }, // Burmese Kyat
  { id: 27, title: "Kenya", currency: "KES" }, // Kenyan Shilling
  { id: 28, title: "South Korea", currency: "KRW" }, // South Korean Won
  { id: 29, title: "Colombia", currency: "COP" }, // Colombian Peso
  { id: 30, title: "Spain", currency: "EUR" }, // Euro
  { id: 31, title: "Uganda", currency: "UGX" }, // Ugandan Shilling
  { id: 32, title: "Argentina", currency: "ARS" }, // Argentine Peso
  { id: 33, title: "Algeria", currency: "DZD" }, // Algerian Dinar
  { id: 34, title: "Sudan", currency: "SDG" }, // Sudanese Pound
  { id: 35, title: "Ukraine", currency: "UAH" }, // Ukrainian Hryvnia
  { id: 36, title: "Iraq", currency: "IQD" }, // Iraqi Dinar
  { id: 37, title: "Afghanistan", currency: "AFN" }, // Afghan Afghani
  { id: 38, title: "Poland", currency: "PLN" }, // Polish Zloty
  { id: 39, title: "Canada", currency: "CAD" }, // Canadian Dollar
  { id: 40, title: "Morocco", currency: "MAD" }, // Moroccan Dirham
  { id: 41, title: "Saudi Arabia", currency: "SAR" },
  { id: 42, title: "Uzbekistan", currency: "UZS" }, // Uzbekistani Som
  { id: 43, title: "Peru", currency: "PEN" }, // Peruvian Sol
  { id: 44, title: "Angola", currency: "AOA" }, // Angolan Kwanza
  { id: 45, title: "Malaysia", currency: "MYR" }, // Malaysian Ringgit
  { id: 46, title: "Mozambique", currency: "MZN" }, // Mozambican Metical
  { id: 47, title: "Ghana", currency: "GHS" }, // Ghanaian Cedi
  { id: 48, title: "Yemen", currency: "YER" }, // Yemeni Rial
  { id: 49, title: "Nepal", currency: "NPR" }, // Nepalese Rupee
  { id: 50, title: "Venezuela", currency: "VES" } // Venezuelan Bolívar
];
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowUpRight } from "../../../assets/IconITgalaxy/akar-icons-arrow-up-right.svg";
import { ReactComponent as ArrowUpRightBlack } from "../../../assets/IconITgalaxy/akar-icons-arrow-up-right-black.svg";
import { useState } from "react";

import {
  ButtonStyle,
  RootStyle,
  StackStyle,
  TitleStyle,
  TypographyStyle,
} from "./HomeSliderText.style";
import Register from "../../Authentification/modals/register";

function HomeSliderText({ title, text, buttonLabel, buttonUrl, active }) {
  const navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  const handleModalRegister = () => {
    setOpenModalRegister(!openModalRegister);
  };


  return (
    <RootStyle>
      <TitleStyle active={active}>{title}</TitleStyle>
      <TypographyStyle active={active}>{text}</TypographyStyle>
      <ButtonStyle
        // icon={active==='FREELANCERS' ? ArrowUpRightBlack :ArrowUpRightBlack}
        onClick={handleModalRegister}
        active={active}
      >
        <StackStyle
          direction={"row"}
          style={{ justifyContent: "space-between" }}
          active={active}
        >
          {buttonLabel}
          {active==='FREELANCERS' ? <ArrowUpRightBlack /> :<ArrowUpRight />}
          
        </StackStyle>
      </ButtonStyle>

      <Register
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalRegister}
        switchBetweenModals={false}
        proxy={"dashboard"}
      />
    </RootStyle>
  );
}

export default HomeSliderText;

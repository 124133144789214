import { LogoRedhat , LogoDebian , LogoUbuntu,TogglePasswordButton, StyleStatus,StyleBlocConnection, StyleVersion,Table, TableHeader, TableCell, MethodContainer, MethodButton, CommandDisplay, StyleStatusServerDetailLine2, StyledDot, StyleServer, ProductContainer, ProductBar, ProductTitleContainer, StyleServerDetailsColunm, StyleServerDetailsBloc, StyleServerDetailLine2, StyleServerDetailLine1 } from "../Style"
import ubuntu from "assets/logo/logo_ubuntu.jpeg";
import redhat from "assets/logo/redhat.png";
import debian from "assets/logo/debian.png";

import { useState } from "react";


const ItGalaxyServerProduct = (props) => {

    const aws_details = JSON.parse(props.data.aws_details.body);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const username = props.data.details.USER ;
    const password = props.data.details.PASSWORD ;
    const createdat = aws_details.instances[0].Tags[3].Value ;
    const turnoff =  aws_details.instances[0].Tags[2].Value ;
    const ip_public = aws_details.instances[0].PublicIpAddress || 'pending ...' ;
    const State = aws_details.instances[0].State || 'pending' ;
  
    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prev) => !prev);
    };

    const commands = {
        ssh: `ssh ${props.data.details.USER}@${ip_public}`,
        
    };
    const [command, setCommand] = useState(commands.ssh); 
    const handleClick = (method) => {
        setCommand(commands[method]);
    };
    return (
        <>
            <StyleServer>
                <ProductContainer>
                    <ProductBar>
                        <ProductTitleContainer>
                            <p style={{ fontWeight: "501", fontSize: "30px", color: "black", fontFamily: "Inter", margin: 0 }}>Welcom to your Server Dashboard </p>
                        </ProductTitleContainer>
                    </ProductBar>
                </ProductContainer>
              {props.data.details.OS === 'ubuntu' && (
               <StyleServerDetailsBloc> <LogoUbuntu src={ubuntu} /> <StyleVersion> 24.04 LTS </StyleVersion> </StyleServerDetailsBloc> )}

              {props.data.details.OS === 'debian' && (
               <StyleServerDetailsBloc> <LogoDebian src={debian} /> <StyleVersion> 12 LTS  </StyleVersion> </StyleServerDetailsBloc> )}
            
              {props.data.details.OS === 'redhat' && (
               <StyleServerDetailsBloc> <LogoRedhat src={redhat} /> <StyleVersion> 9 LTS  </StyleVersion> </StyleServerDetailsBloc> )}

               
               <hr />
                <StyleServerDetailsBloc>

                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1>Status</StyleServerDetailLine1>
                        <StyleStatus>
                            <StyleStatusServerDetailLine2 isActive={State === 'running'}> {State} </StyleStatusServerDetailLine2>
                            <StyledDot isActive={true} />

                        </StyleStatus>
                    </StyleServerDetailsColunm>


                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1>Created At </StyleServerDetailLine1>
                        <StyleServerDetailLine2> {createdat} </StyleServerDetailLine2>
                    </StyleServerDetailsColunm>

                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1> Turn of at </StyleServerDetailLine1>
                        <StyleServerDetailLine2> {turnoff} </StyleServerDetailLine2>
                    </StyleServerDetailsColunm>


                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1>Public Adress</StyleServerDetailLine1>
                        <StyleServerDetailLine2> {ip_public} </StyleServerDetailLine2>
                    </StyleServerDetailsColunm>

                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1>Username</StyleServerDetailLine1>
                        <StyleServerDetailLine2> {username} </StyleServerDetailLine2>
                    </StyleServerDetailsColunm>

                    <StyleServerDetailsColunm>
                        <StyleServerDetailLine1>Password</StyleServerDetailLine1>
                        <StyleServerDetailLine2> 
                        {isPasswordVisible ? password : '••••••••'} {/* Display password or dots */}
                        <TogglePasswordButton onClick={togglePasswordVisibility}>
                        {isPasswordVisible ? '👁️' : '👁️‍🗨️'}
                        </TogglePasswordButton>                            
                     </StyleServerDetailLine2>
                    </StyleServerDetailsColunm>



                </StyleServerDetailsBloc>
                <hr />

               <StyleBlocConnection>
              <StyleServerDetailLine1> Server Connection :  </StyleServerDetailLine1>
               <Table>
                    <thead>
                    <tr>
                        <TableHeader colSpan={2}>
                        <MethodContainer>
                            <MethodButton
                            isSelected={command === commands.ssh}
                            onClick={() => handleClick('ssh')}
                            >
                            ssh
                            </MethodButton>
                        </MethodContainer>
                        </TableHeader>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <TableCell colSpan={2}>
                        <CommandDisplay>
                            <pre>{command}</pre>
                        </CommandDisplay>
                        </TableCell>
                    </tr>
                    </tbody>
                </Table>
                </StyleBlocConnection>


            </StyleServer>
        </>
  );
};

export default ItGalaxyServerProduct;

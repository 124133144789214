import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { APPLICATION_ITEMS, VIDEO_TRAINING_ITEMS } from "../../../../core/constants/trainerForm.constants";
import { addTypeTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { formConfig } from "./TrainingType.constants";
import { ContentFormContainer } from "./TrainingType.style";

const TrainingType = ({ trainingType, onValidate, onCancel, setItems }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();

  const watchProductType = watch('productType');

  const handleValidateClick = async () => {
    try {
      const isValid = await trigger('productType');
      if (isValid) {
        onValidate();
      }
    } catch (error) {
      console.error("Error validating training type:", error);
    }
  };

  const handleChange = (value) => {
    setValue('productType', value, { shouldValidate: true });
    if (value === "Video Training") {
      setItems(VIDEO_TRAINING_ITEMS);
      dispatch(addTypeTraining("Video Training"));
    } else if (value === "Application") {
      setItems(APPLICATION_ITEMS);
      dispatch(addTypeTraining("Application"));
    }
  };

  return (
    <ContentFormContainer>
      <GenericInput
        inputObject={{
          ...formConfig.productType,
          fieldName: 'productType',
        }}
        label="Select Training Type"
        value={watchProductType}
        onChange={handleChange}
        disabledForm={false}
      />

      <div style={{ float: "right", marginTop: "10%" }}>
        <Button 
          variant="light" 
          onClick={onCancel}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleValidateClick}
          disabled={!watchProductType}
        >
          Next
        </Button>
      </div>
    </ContentFormContainer>
  );
};

export default TrainingType;

import { jwtDecode } from "jwt-decode";
import MarketplaceComponnents from "components/DashboardItGalaxyMarketplaceComponnent/MarketplaceComponnents/MarketplaceComponnents";
import SideBar from "../../components/SideBar/sideBar";
import {  getTokenFromLocalStorage } from "../../core/helpers/storage";

const Marketplace = () => {
  const token = getTokenFromLocalStorage();
  const decodedToken = token ? jwtDecode(token) : null;
  const role = decodedToken ? decodedToken.role : null;
  const id = decodedToken ? decodedToken.id : null;


  return (
    <div style={{ display: "flex", overflowY: 'hidden', height: '100vh', overflowX: 'hidden' }} >
      <SideBar path={'/marketplace'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
      <MarketplaceComponnents role={role} id={id}/>
    </div>
  );
};

export default Marketplace;

import AddPrice from "components/DashboardTrainingComponnents/TrainerComponnents/AddPrice/AddPrice";
import Content from "components/DashboardTrainingComponnents/TrainerComponnents/Content/Content";
import CreateApplication from "components/DashboardTrainingComponnents/TrainerComponnents/CreateApplication/CreateApplication";
import CreateTraining from "components/DashboardTrainingComponnents/TrainerComponnents/CreateTraining/CreateTraining";
import {
  InlineBlockContainer,
  InlineBlockMarginTopContainer,
} from "components/DashboardTrainingComponnents/TrainerComponnents/TrainerForm/TraininerForm.style";
import { priceShares } from "data/priceShares";
import { FormProvider } from "react-hook-form";
import TrainerNewPaymentForm from "components/DashboardTrainingComponnents/TrainerComponnents/TrainerNewPaymentForm/TrainerNewPaymentForm";
import TrainingType from "components/DashboardTrainingComponnents/TrainerComponnents/TrainingType/TrainingType";

export const renderContentList = ({
  type,
  trainingType,
  selectedElement,
  handleStepValidation,
  setConfirmShow,
  setItems,
  formMethods,
  addChapter,
  currentChapterIndex,
  handleConfirmChapter,
  totalChapters,
  handleNextStep,
  navigationHandlers,
  isEdit,
  openModalRegister,
  setOpenModalRegister,
  handleSubmit,
}) => {
  if (type !== "training") return null;

  const handleCancel = () => {
    if (selectedElement > 1) handleStepValidation(selectedElement - 1);
  };

  const renderWithFormProvider = (Component) => {
    return <FormProvider {...formMethods}>{Component}</FormProvider>;
  };

  if (trainingType === "Video Training") {
    switch (selectedElement) {
      case 1:
        return renderWithFormProvider(
          <TrainingType
            setItems={setItems}
            trainingType={trainingType}
            onCancel={handleCancel}
            onValidate={() => handleStepValidation(selectedElement + 1)}
          />
        );
      case 2:
        return renderWithFormProvider(
          <InlineBlockContainer>
            <CreateTraining
              onValidate={() => handleStepValidation(selectedElement + 1)}
              onCancel={handleCancel}
            />
          </InlineBlockContainer>
        );
      case 3:
        if (currentChapterIndex === null) return null;
        return renderWithFormProvider(
          <InlineBlockContainer>
            <Content
              onValidate={() => handleStepValidation(selectedElement + 1)}
              onCancel={handleCancel}
              currentChapterIndex={currentChapterIndex}
              onConfirmChapter={handleConfirmChapter}
              addChapter={addChapter}
              onNextStep={handleNextStep}
            />
          </InlineBlockContainer>
        );

      case 4:
        return renderWithFormProvider(
          <InlineBlockContainer>
            <AddPrice
                onValidate={() => {
                  handleStepValidation(selectedElement + 1);
                  setConfirmShow(true);
                }}
               onCancel={handleCancel}
             />
          </InlineBlockContainer>
        );
      case 5:
        return renderWithFormProvider(
          <InlineBlockMarginTopContainer>
            <TrainerNewPaymentForm
              isEdit={false}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              formMethods={formMethods}
              disableStep={true}
              refetchCompanyRequestCreation={
                navigationHandlers.refetchCompanyRequestCreation
              }
            />
          </InlineBlockMarginTopContainer>
        );

      default:
        return null;
    }
  } else if (trainingType === "Application") {
    switch (selectedElement) {
      case 1:
        return renderWithFormProvider(
          <TrainingType
            setItems={setItems}
            onCancel={handleCancel}
            onValidate={() => handleStepValidation(selectedElement + 1)}
          />
        );
      case 2:
        return renderWithFormProvider(
          <InlineBlockContainer>
            <CreateApplication
              onValidate={() => handleStepValidation(selectedElement + 1)}
              onCancel={handleCancel}
            />
          </InlineBlockContainer>
        );
      case 3:
        if (currentChapterIndex === null) return null;
        return renderWithFormProvider(
          <InlineBlockContainer>
            <Content
              onValidate={() => handleStepValidation(selectedElement + 1)}
              onCancel={handleCancel}
              currentChapterIndex={currentChapterIndex}
              onConfirmChapter={handleConfirmChapter}
              addChapter={addChapter}
              onNextStep={handleNextStep}
            />
          </InlineBlockContainer>
        );
      case 4:
        return renderWithFormProvider(
          <InlineBlockContainer>
            <AddPrice
                onValidate={() => {
                  setConfirmShow(true);
                }}
                onCancel={handleCancel}
             />

          </InlineBlockContainer>
        );
      case 5:
        return renderWithFormProvider(
          <InlineBlockMarginTopContainer>
            <TrainerNewPaymentForm
              isEdit={false}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              formMethods={formMethods}
              disableStep={true}
              refetchCompanyRequestCreation={
                navigationHandlers.refetchCompanyRequestCreation
              }
              paymentProposal={false}
              price={priceShares.product}
            />
          </InlineBlockMarginTopContainer>
        );
      default:
        return null;
    }
  }

  return null;
};

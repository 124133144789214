export const countryLengthMapData = [
    { id: 1,  title: "CN", LENGHT: 22, currency: "CNY" }, // China
    { id: 2,  title: "IN", LENGHT: 20, currency: "INR" }, // India
    { id: 3,  title: "US", LENGHT: 22, currency: "USD" }, // United States
    { id: 4,  title: "ID", LENGHT: 16, currency: "IDR" }, // Indonesia
    { id: 5,  title: "PK", LENGHT: 24, currency: "PKR" }, // Pakistan
    { id: 6,  title: "BR", LENGHT: 29, currency: "BRL" }, // Brazil
    { id: 7,  title: "NG", LENGHT: 10, currency: "NGN" }, // Nigeria
    { id: 8,  title: "BD", LENGHT: 28, currency: "BDT" }, // Bangladesh
    { id: 9,  title: "RU", LENGHT: 33, currency: "RUB" }, // Russia
    { id: 10, title: "MX", LENGHT: 18, currency: "MXN" }, // Mexico
    { id: 11, title: "JP", LENGHT: 17, currency: "JPY" }, // Japan
    { id: 12, title: "ET", LENGHT: 13, currency: "ETB" }, // Ethiopia
    { id: 13, title: "PH", LENGHT: 22, currency: "PHP" }, // Philippines
    { id: 14, title: "EG", LENGHT: 29, currency: "EGP" }, // Egypt
    { id: 15, title: "VN", LENGHT: 28, currency: "VND" }, // Vietnam
    { id: 16, title: "CD", LENGHT: 28, currency: "CDF" }, // DR Congo
    { id: 17, title: "TR", LENGHT: 26, currency: "TRY" }, // Turkey
    { id: 18, title: "IR", LENGHT: 22, currency: "IRR" }, // Iran
    { id: 19, title: "DE", LENGHT: 22, currency: "EUR" }, // Germany
    { id: 20, title: "TH", LENGHT: 15, currency: "THB" }, // Thailand
    { id: 21, title: "GB", LENGHT: 22, currency: "GBP" }, // United Kingdom
    { id: 22, title: "FR", LENGHT: 27, currency: "EUR" }, // France
    { id: 23, title: "IT", LENGHT: 27, currency: "EUR" }, // Italy
    { id: 24, title: "TZ", LENGHT: 20, currency: "TZS" }, // Tanzania
    { id: 25, title: "ZA", LENGHT: 28, currency: "ZAR" }, // South Africa
    { id: 26, title: "MM", LENGHT: 18, currency: "MMK" }, // Myanmar
    { id: 27, title: "KE", LENGHT: 20, currency: "KES" }, // Kenya
    { id: 28, title: "KR", LENGHT: 16, currency: "KRW" }, // South Korea
    { id: 29, title: "CO", LENGHT: 24, currency: "COP" }, // Colombia
    { id: 30, title: "ES", LENGHT: 24, currency: "EUR" }, // Spain
    { id: 31, title: "UG", LENGHT: 30, currency: "UGX" }, // Uganda
    { id: 32, title: "AR", LENGHT: 20, currency: "ARS" }, // Argentina
    { id: 33, title: "DZ", LENGHT: 20, currency: "DZD" }, // Algeria
    { id: 34, title: "SD", LENGHT: 18, currency: "SDG" }, // Sudan
    { id: 35, title: "UA", LENGHT: 29, currency: "UAH" }, // Ukraine
    { id: 36, title: "IQ", LENGHT: 23, currency: "IQD" }, // Iraq
    { id: 37, title: "AF", LENGHT: 24, currency: "AFN" }, // Afghanistan
    { id: 38, title: "PL", LENGHT: 28, currency: "PLN" }, // Poland
    { id: 39, title: "CA", LENGHT: 24, currency: "CAD" }, // Canada
    { id: 40, title: "MA", LENGHT: 24, currency: "MAD" }, // Morocco
    { id: 41, title: "SA", LENGHT: 18, currency: "SAR" }, // Saudi Arabia
    { id: 42, title: "UZ", LENGHT: 28, currency: "UZS" }, // Uzbekistan
    { id: 43, title: "PE", LENGHT: 20, currency: "PEN" }, // Peru
    { id: 44, title: "AO", LENGHT: 25, currency: "AOA" }, // Angola
    { id: 45, title: "MY", LENGHT: 20, currency: "MYR" }, // Malaysia
    { id: 46, title: "MZ", LENGHT: 21, currency: "MZN" }, // Mozambique
    { id: 47, title: "GH", LENGHT: 10, currency: "GHS" }, // Ghana
    { id: 48, title: "YE", LENGHT: 24, currency: "YER" }, // Yemen
    { id: 49, title: "NP", LENGHT: 29, currency: "NPR" }, // Nepal
    { id: 50, title: "VE", LENGHT: 20, currency: "VES" }  // Venezuela
];

import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import GenericInput from "components/Inputs/GenericInput/GenericInput"; // Assuming this is your generic input
import { formConfig } from "./AddNewJob.constants";
import RichTextEditor from "components/RichTextEditor/RichTextEditor"; // Import the rich text editor
import {
  LocationStyleModal,
  TitleForm,
  ButtonSubmitStyle,
  ButtonSubmit,
  ButtonSubmitDisable,
  TextStyleModal,
  InputContainer,
  ButtonContainer,
  InputLabel,
  LocationStyle,
} from "./Style";
import ModalPayment from "components/ModalITgalaxy/ModalPayment/ModalPayment";
import { useCreateAppelOffreMutation } from "../../redux/api/appeloffres/appeloffreApi";
import toast from "react-hot-toast";
import ModalComponent from "components/ModalITgalaxy/ModalComponent";
import { getCountOffreFromLocalStorage } from "../../core/helpers/storage";
import { countfreeShares } from "../../data/countfreeShares";
import { priceShares } from "../../data/priceShares";
import useHandleSubmitOffreCreation from "../../hooks/useHandleSubmitOffreCreation";

export default function AddNewJob({ refetch ,id, setAddNewJob }) {
  const methods = useForm({
    mode: 'onChange', // Validate on input
    defaultValues: {
      jobDescription: "", // Initialize the field
    },
  });
  
  const [jobDescription, setJobDescription] = useState("");
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [titleLength, setTitleLength] = useState(0);
  const [describLenght , setDescribLenght] = useState(0);

  const [createOffre, { isLoading }] = useCreateAppelOffreMutation();
  const { control, setValue, formState: { errors , isValid} } = methods;

  const [formData, setFormData] = useState({
    recruterId: id,
    title: "",
    appeloffre_description: jobDescription,
    skills: [],
    category:[],
    location: "",
    languages: [],
  });

  useEffect(() => {
    setDescribLenght(jobDescription.length);
    setFormData(prevFormData => ({
      ...prevFormData,
      appeloffre_description: jobDescription 
    }));
    methods.trigger("jobDescription");

  }, [jobDescription]);

  // Function to close modal
  const handleClose = () => { 
    setShowModalPayment(false);
  };

  const handelShowModalPayment = () => {
    setShowModalPayment(true); // Show confirmation modal
  };

  let { handleSubmit, loading } = useHandleSubmitOffreCreation(
    formData,
  );

  // Function to handle offer creation
  const HandleCreateAppelOffre = async () => {
    if (isLoading) return; // Prevent further action while loading
    try {
      if (getCountOffreFromLocalStorage() > countfreeShares.appel_offre ) {
        handelShowModalPayment();
      } else {
      await createOffre(formData).unwrap(); // Handle promise
      toast.success("Offre was created", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      refetch();
      setAddNewJob(false); // Reset or hide the modal upon success
     }
    } catch (e) {
      console.error("Error creating offre:", e); // Log error
      // Show specific error message if available
      const errorMessage = e.data?.error || "Please check the inputs";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleChangeCategorysSelected = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      category: selectedOptions.map(option => option.value)
    }));
  };

  const handleChangeTitle = (selectedOptions) => {
    setTitleLength(selectedOptions.length);
    setFormData(prevFormData => ({
        ...prevFormData,
        title: selectedOptions
    }));
  };

  const handleChangeLocations = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      location:  selectedOptions.value
    }));
  };

  const handleChangeLanguages = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      languages: selectedOptions.map(selectedOption => selectedOption.value)
    }));
  };

  const handleChangeSkills = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      skills: selectedOptions.map(selectedOption => selectedOption.value)
    }));
    methods.trigger("skills");  // Manually trigger validation for updates

  };


  const handleChangeJobDescription = (value) => {
    setJobDescription(value); // Update local state
    setValue("jobDescription", value); // Set form value

    // Manual validation check
    if (value.length < 200) {
      methods.setError("jobDescription", { type: "manual", message: "Description must be at least 200 characters." });
    } else {
      // Clear error if valid
      methods.clearErrors("jobDescription");
    } 
  };

  return (
    <>  
      <FormProvider {...methods} style={{ marginRight: '10px;'}}>
          <TitleForm>
            Post your Job
            <hr />
          </TitleForm>


        <InputContainer>
        <InputLabel>Job Title*</InputLabel>
            <GenericInput
                inputObject={{
                    ...formConfig.title
                 }}
                onChange={handleChangeTitle}
                disabledForm={false}
                control={control}
            />                     
            <p style={{ fontSize: '5px;' ,textAlign: 'right', color: titleLength >= 100 ? 'red' : 'black' }}>
                {titleLength}/50
            </p>
            </InputContainer>

          <LocationStyle>
            <InputContainer>
              <InputLabel>Job Location*</InputLabel>
              <GenericInput
                inputObject={{
                  ...formConfig.location,
                }}
                control={control}
                onChange={handleChangeLocations}
                disabledForm={false}
              />
            </InputContainer>
            <InputContainer>
                  <InputLabel>Category*</InputLabel>
                  <GenericInput
                    inputObject={{
                      ...formConfig.category
                    }}
                    onChange={handleChangeCategorysSelected}
                    disabledForm={false}
                  />
                </InputContainer>

            <InputContainer>
              <InputLabel>Languages*</InputLabel>
              <GenericInput
                inputObject={{
                  ...formConfig.languages
                }}
                onChange={handleChangeLanguages}
                disabledForm={false}
              />
            </InputContainer>
          </LocationStyle>

          <InputContainer>
            <InputLabel>Job Description*</InputLabel>
            <RichTextEditor
              value={jobDescription}
              onChange={handleChangeJobDescription} // Pass the validation and setter
            />
              <p style={{ color: describLenght < 200 ? 'red' : 'black' , fontSize: 'small', textAlign: 'right', marginTop: '4px' }}>
                  Description must be at least 200 characters. {describLenght}/200
               </p>
          </InputContainer>

          <InputContainer>
            <InputLabel>Skills*</InputLabel>
            <GenericInput
              inputObject={{
                ...formConfig.skills
              }}
              control={control}
              onChange={handleChangeSkills}
              disabledForm={false}
            />
          </InputContainer>
       

          { isValid && Object.keys(errors).length === 0 ? (
            <ButtonContainer>
            <ButtonSubmit onClick={HandleCreateAppelOffre} disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Post it'}
            </ButtonSubmit>
          </ButtonContainer>
          ) : (
           <ButtonContainer>
            <ButtonSubmitDisable >
                Post it
            </ButtonSubmitDisable>
          </ButtonContainer>

          )}
      </FormProvider>

      {showModalPayment && ( 
        <ModalPayment
          handleCloseShowModal={handleClose}
          showModalPayment={handelShowModalPayment}
          paymentProposal={false}
          price={priceShares.appel_offre}
          formMethods={methods}
          title={"PAYMENT OFFRE CREATION"}
          note={"To share this offre with community you need to pay the service."} 
          onSubmit={handleSubmit}

      />
      )}
    </>
  );
}

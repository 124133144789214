export const priceProductCloud = {   

 "server_ubuntu_nano"     : 0.09 ,
 "server_ubuntu_micro"    : 0.19 ,
 "server_ubuntu_small"    : 0.29 ,
 "server_ubuntu_medium"   : 0.39 , 

 "server_redhat_nano"     : 0.09 ,
 "server_redhat_micro"    : 0.19 ,
 "server_redhat_small"    : 0.29 ,
 "server_redhat_medium"   : 0.39 , 

 "server_debian_nano"     : 0.09 ,
 "server_debian_micro"    : 0.19 ,
 "server_debian_small"    : 0.29 ,
 "server_debian_medium"   : 0.39 , 


 "database_nano_21"   : 0.09 ,
 "database_nano_30"   : 0.09 ,
 "database_nano_40"   : 0.09 ,
 "database_nano_50"   : 0.09 ,
 "database_nano_60"   : 0.09 ,
 "database_nano_80"   : 0.09 ,
 "database_nano_90"   : 0.09 ,
 "database_nano_100"   : 0.09 ,
 "database_nano_110"   : 0.09 ,
 "database_nano_120"   : 0.09 ,
 "database_nano_130"   : 0.09 ,
 "database_nano_140"   : 0.09 ,
 "database_nano_150"   : 0.09 ,
 "database_nano_160"   : 0.09 ,
 "database_nano_170"   : 0.09 ,
 "database_nano_180"   : 0.09 ,
 "database_nano_190"   : 0.09 ,
 "database_nano_200"   : 0.09 ,



 "database_micro_21"  : 0.19 ,
 "database_micro_30"  : 0.19 ,
 "database_micro_40"  : 0.19 ,
 "database_micro_50"  : 0.19 ,
 "database_micro_60"  : 0.19 ,
 "database_micro_70"  : 0.19 ,
 "database_micro_80"  : 0.19 ,
 "database_micro_90"  : 0.19 ,
 "database_micro_100"  : 0.19 ,
 "database_micro_110"  : 0.19 ,
 "database_micro_120"  : 0.19 ,
 "database_micro_130"  : 0.19 ,
 "database_micro_140"  : 0.19 ,
 "database_micro_150"  : 0.19 ,
 "database_micro_160"  : 0.19 ,
 "database_micro_170"  : 0.19 ,
 "database_micro_180"  : 0.19 ,
 "database_micro_190"  : 0.19 ,
 "database_micro_200"  : 0.19 ,



 "database_small_21"  : 0.19 ,
 "database_small_30"  : 0.19 ,
 "database_small_40"  : 0.19 ,
 "database_small_50"  : 0.19 ,
 "database_small_60"  : 0.19 ,
 "database_small_70"  : 0.19 ,
 "database_small_80"  : 0.19 ,
 "database_small_90"  : 0.19 ,
 "database_small_100"  : 0.19 ,
 "database_small_110"  : 0.19 ,
 "database_small_120"  : 0.19 ,
 "database_small_130"  : 0.19 ,
 "database_small_140"  : 0.19 ,
 "database_small_150"  : 0.19 ,
 "database_small_160"  : 0.19 ,
 "database_small_170"  : 0.19 ,
 "database_small_180"  : 0.19 ,
 "database_small_190"  : 0.19 ,
 "database_small_200"  : 0.19 ,


 
 "database_medium_21"  : 0.19 ,
 "database_medium_30"  : 0.19 ,
 "database_medium_40"  : 0.19 ,
 "database_medium_50"  : 0.19 ,
 "database_medium_60"  : 0.19 ,
 "database_medium_70"  : 0.19 ,
 "database_medium_80"  : 0.19 ,
 "database_medium_90"  : 0.19 ,
 "database_medium_100"  : 0.19 ,
 "database_medium_110"  : 0.19 ,
 "database_medium_120"  : 0.19 ,
 "database_medium_130"  : 0.19 ,
 "database_medium_140"  : 0.19 ,
 "database_medium_150"  : 0.19 ,
 "database_medium_160"  : 0.19 ,
 "database_medium_170"  : 0.19 ,
 "database_medium_180"  : 0.19 ,
 "database_medium_190"  : 0.19 ,
 "database_medium_200"  : 0.19 ,

}
import  DashboardItGalaxyMarketplacePurchased from "components/DashboardItGalaxyMarketplaceComponnent/DashboardItGalaxyMarketplacePurchased/DashboardItGalaxyMarketplacePurchased";
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formConfig } from "./MarketplaceComponnents.config";
import {
  StyleBarSearch,
  Container,
  ContainerWrapper,
  RowStyle,
  SearchBar,
  StyleBarSearchSkills,
  Wrapper,
  StyleLineCount,
  ProductLabelsContainer,
  ViewLabelProducts,
  ViewLabelMyProducts,
  StyleProductsCount,
  NewProductButtonContainer,
  StyleCount1,
  StyleCount,
  TopContainer
} from "./MarketplaceComponnents.style";
import { useGetMarketplaceItgalaxyQuery } from "../../../redux/api/marketplaceItgalaxy/marketplaceItgalaxyApi";
const MarketplaceComponents = (props) => {
  const [animateDirection, setAnimateDirection] = useState("inRight");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [purchasedProducts, setPurchasedProducts] = useState(false);

  const { data: productsData, error: productsDataError, isLoading: productsDataLoading } = useGetMarketplaceItgalaxyQuery(
    `?page=${currentPage}&limit=${recordsPerPage}&userId=${props.id}&userType=${props.role}${searchQuery}`
  );

  useEffect(() => {
    if (!productsDataLoading && !productsDataError && productsData) {
      const marketplaceProducts = productsData.data.marketplaceProducts || [];
      const applicationProducts = productsData.data.applicationProducts || [];
      setAllProducts([...marketplaceProducts, ...applicationProducts]); // Spread syntax combines both arrays
    }
  }, [productsData, productsDataLoading, productsDataError]);

  const handlePurchasedProducts = () => {
    setPurchasedProducts(true);
  };

  const formMethods = useForm({});
  const type = formMethods.watch("type");

  const handleNavigate = (type , title , token) => {

    if(type === 'MARKETPLACE') {
      if(title === "Server Ubuntu") {
      window.location.href = `/createProductServer?type=ubuntu` ;
      } else if (title === "Server Debian") {
        window.location.href = `/createProductServer?type=debian` ;

      } else if (title === "Server RedHat") {
        window.location.href = `/createProductServer?type=redhat` ;

      } else if (title === "Database Postgres") {
        window.location.href = `/createProductDatabase?type=postgres` ;

        
        
      } else if (title === "Database Mongodb") {
        window.location.href = `/createProductDatabase?type=mongodb` ;

      } else if (title === "Database Mysql") {
        window.location.href = `/createProductDatabase?type=mysql` ;

      } 

    } else if (type === 'APPLICATION') {
      window.location.href = `/itgalaxyProductDetails/${token}` ;
    }
  };

  if (productsDataLoading) {
    return <div>Loading...</div>;
  }

  if (productsDataError) {
    return <div>Error loading data: {productsDataError.message}</div>;
  }

  return (
    !purchasedProducts ? (
      <Wrapper>
        <ContainerWrapper className="container">
          <SearchBar>
            <FormProvider {...formMethods}>
              <StyleBarSearch>
                <GenericInput
                  inputObject={{
                    ...formConfig.title,
                    label: "filterType"
                  }}
                  onChange={(e) => props.setType(e.target.value)}
                />
              </StyleBarSearch>
              <StyleBarSearchSkills>
                <GenericInput
                  inputObject={{
                    ...formConfig.skills,
                    label: "filterType"
                  }}
                />
              </StyleBarSearchSkills>
              {props.role === "CANDIDAT" && (
                <NewProductButtonContainer onClick={handlePurchasedProducts}>
                  {"Create a product"}
                </NewProductButtonContainer>
              )}
            </FormProvider>
          </SearchBar>
          <TopContainer>
            <Container>
              <StyleLineCount>
                <ProductLabelsContainer>
                  <ViewLabelProducts>Marketplace Products</ViewLabelProducts>
                  <ViewLabelMyProducts onClick={handlePurchasedProducts}>Purchased Products</ViewLabelMyProducts>
                </ProductLabelsContainer>
                <StyleProductsCount>
                  {productsData?.pagination && (
                    <StyleCount1>
                  <StyleCount>{productsData.pagination.totals}</StyleCount> products found
                    </StyleCount1>
                  )}
                </StyleProductsCount>
              </StyleLineCount>
            </Container>
            <RowStyle spacing={3}>
              {allProducts.length > 0 ? (
                allProducts.map((value) => (
                  <FormationsCard
                    key={value.id}
                    formation={{
                      id: value.id,
                      type: value.type,
                      title: value.title,
                      description: value.description,
                      image: value.image || value.thumbnail,
                      price: value.price,
                      skills: value.skills,
                    }}
                    animateDirection={animateDirection}
                    category={type}
                    onClick={() => handleNavigate(value.type,value.title ,value.display)}
                  />
                ))
              ) : (
                <div>No marketplace products available.</div>
              )}
            </RowStyle>
          </TopContainer>
        </ContainerWrapper>
      </Wrapper>
    ) : (
      <DashboardItGalaxyMarketplacePurchased setPurchasedProducts={setPurchasedProducts} />
    )
  );
};

export default MarketplaceComponents;

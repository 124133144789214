import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProductServerForm } from "../../../core/helpers/createProductServerForm.js";
import { getTokenFromLocalStorage } from "../../../core/helpers/storage.js";
import { PRODUCT_SERVER_ITEMS } from "../../../core/constants/productCloudForm.constants.js";
import useHandleSubmitItGalaxyProduct from "../../../hooks/useHandleSubmitItGalaxyProduct";

import {
  StyledBoldSubtitle,
  StyledBreadcrumb,
  StyledCircle,
  StyledContainer,
  StyledDepositCompanyHeader,
  StyledFormContainer,
  StyledNextLineOne,
  StyledGoBackContainer,
  StyledGoNextContainer,
  StyledStatusCard,
  BigContainer,
  StyledStep,
  StyledStepContentCard,
  StyledStepContentColumn,
  StyledStepSubtitle,
  StyledStepTitle,
  StyledStepTitleCard,
  StyledStepperCard,
  StyledStepsFormContainer,
  StyledSubtitle,
  StyledTitle,
  StyledTitleAndSubtitleContainer,
  StyledLineOne
} from "../style.js";

const ProductCreateServerForm = (props) => {

  const token = getTokenFromLocalStorage();
  const decodedToken = token ? jwtDecode(token) : null;
  const userId = decodedToken?.id;
  const userType  = decodedToken?.role;
  const items    = PRODUCT_SERVER_ITEMS;
  let version ;
  const [selectedElement, setSelectedElement] = useState(1);

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  switch(props.type) {
    case 'ubuntu' : version = '24.04.1 LTS' ; break ;
    case 'redhat' : version = 'Linux 9' ; break ;
    case 'debian' : version = '12' ; break ; 
  }

  let { handleServerSubmit, loading} = useHandleSubmitItGalaxyProduct(
    userId,
    userType,
    formMethods,
    props.type
  );

  return (
    <StyledContainer>
      <BigContainer>
        <StyledDepositCompanyHeader>
          <StyledBreadcrumb>
            <StyledTitle>Purchase {props.type} {version} Server</StyledTitle>
            <StyledSubtitle> ItGalaxy Cloud </StyledSubtitle>
          </StyledBreadcrumb>
        </StyledDepositCompanyHeader>
        <StyledFormContainer>
        <StyledLineOne> 
          {selectedElement > 2 && 
            <StyledGoBackContainer onClick={() => setSelectedElement(selectedElement - 1)}>
              <i className="fal fa-arrow-left"></i> {/* Left Arrow Icon */}
              <StyledBoldSubtitle> Back </StyledBoldSubtitle>
            </StyledGoBackContainer>
          }
          
          {selectedElement > 1 && selectedElement < 5 && (
           <StyledNextLineOne> 
            <StyledGoNextContainer onClick={() => setSelectedElement(selectedElement + 1)}>      
              <StyledBoldSubtitle> Next </StyledBoldSubtitle>
              <i className="fal fa-arrow-right"></i>
            </StyledGoNextContainer>
            </StyledNextLineOne> 
          )}
        </StyledLineOne>

          <StyledStepsFormContainer>
            <StyledStepContentColumn>
              <StyledStepTitleCard>
                <StyledBoldSubtitle style={{ color: "#A4ADB6" }}>
                  Step {selectedElement}:{" "}
                </StyledBoldSubtitle>
                <StyledBoldSubtitle>
                 {selectedElement && items[selectedElement - 1]?.title}
                </StyledBoldSubtitle>
              </StyledStepTitleCard>
              <StyledStepContentCard>              
                {createProductServerForm(
                  selectedElement,
                  setSelectedElement,
                  formMethods,
                  props.type,
                  handleServerSubmit
                )}            
              </StyledStepContentCard>
            </StyledStepContentColumn>
            <StyledStepperCard>
              {items.map((item) => (
                <StyledStep
                  key={item.id}
                  active={selectedElement === item.id}
                  checked={selectedElement > item.id}
                  onClick={() => setSelectedElement(item.id)}
                >
                  <StyledCircle> {item.id}</StyledCircle>
                  <StyledTitleAndSubtitleContainer >
                    <StyledStepTitle > {item.title}</StyledStepTitle>
                  </StyledTitleAndSubtitleContainer>
                </StyledStep>
              ))}
            </StyledStepperCard>
          </StyledStepsFormContainer>
        </StyledFormContainer>
      </BigContainer>
    </StyledContainer >
  );
};
export default ProductCreateServerForm;
import { CDBSidebarMenuItem } from "cdbreact";
import ShowModalVideo from "components/ModalITgalaxy/ShowModalVideo/ShowModalVideo";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormProvider } from "react-hook-form";
import {
  ContentFormContainer,
  FileInfo,
  PlusIcon,
  StyledDropzone,
  StyledGrid,
  VideoPreview,
  VideoWrapper,
} from "./VideoUpload.style";

/**
 * VideoUpload component
 *
 * Props:
 * - value: Array of video objects currently in the form (chapters[index].videos)
 * - onChange: Function to update the array of videos in the form
 * - inputObject: (Optional) object with fieldName or other metadata
 * - formMethods: form methods from react-hook-form's FormProvider
 */
const VideoUpload = ({ value = [], onChange, inputObject, formMethods }) => {
  const [formVideos, setFormVideos] = useState(value);
  const [fileName, setFileName] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  // Keep local state in sync with external value changes
  useEffect(() => {
    setFormVideos(value || []);
  }, [value]);

  const handleSaveVideo = (videoData) => {
    // videoData should contain { titleVideo, descriptionVideo, videoFile, videoUrl, base64URL } etc.
    const updatedVideos = [...formVideos];

    if (isEdited && selectedVideoIndex !== null) {
      // Update existing video
      updatedVideos[selectedVideoIndex] = videoData;
    } else {
      // Add new video
      updatedVideos.push(videoData);
    }

    setFormVideos(updatedVideos);
    onChange(updatedVideos); // update form data
    setShowModalVideo(false);
    setSelectedVideoIndex(null);
    setIsEdited(false);
  };

  const { isDragActive } = useDropzone({
    accept: "video/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onload = (e) => setVideoURL(e.target.result);
        reader.readAsDataURL(file);
        setFileName(file.name);
      } else {
        alert("Only video formats are allowed!");
      }
    },
  });

  return (
    <div>
      <FormProvider {...formMethods}>
        <small
          style={{ color: "#6c757d", marginBottom: "10px", display: "block" }}
        >
          Max 5 Videos per chapter, max size per video: 100MB.
        </small>
        <ContentFormContainer>
          <StyledGrid>
            {formVideos.map((video, index) => (
              <div key={index}>
                <VideoWrapper
                  className="ml-3"
                  onClick={() => {
                    setSelectedVideoIndex(index);
                    setShowModalVideo(true);
                    setIsEdited(true);
                  }}
                >
                  <VideoPreview src={video.videoUrl} controls />
                  <PlusIcon>
                    <CDBSidebarMenuItem icon="fa fa-edit"></CDBSidebarMenuItem>
                  </PlusIcon>
                </VideoWrapper>
              </div>
            ))}
            {formVideos.length < 5 && (
              <div className="col">
                <StyledDropzone
                  isActive={isDragActive}
                  onClick={() => {
                    setIsEdited(false);
                    setSelectedVideoIndex(null);
                    setShowModalVideo(true);
                  }}
                >
                  {videoURL ? (
                    <VideoPreview src={videoURL} controls />
                  ) : (
                    <CDBSidebarMenuItem icon="fa fa-plus"></CDBSidebarMenuItem>
                  )}
                </StyledDropzone>
              </div>
            )}

            {showModalVideo && (
              <ShowModalVideo
                isEdit={isEdited}
                selectedVideo={
                  isEdited && selectedVideoIndex !== null
                    ? formVideos[selectedVideoIndex]
                    : null
                }
                show={showModalVideo}
                setShow={setShowModalVideo}
                closeModal={() => setShowModalVideo(false)}
                onSave={handleSaveVideo} // Callback to save the video
              />
            )}
          </StyledGrid>
        </ContentFormContainer>
      </FormProvider>

      {fileName && <FileInfo>Uploaded: {fileName}</FileInfo>}
    </div>
  );
};

export default VideoUpload;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../core/constants/endpoints";
import { baseQueryConfig } from "../BaseQueryConfig";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ["payment"],
  endpoints: (builder) => ({
    // CREATE Payment method
    createPayment: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS.PAYMENT}create-payment`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["payment"],
    }),
    addPaymentWithWallet: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS.PAYMENT}create-payment`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["payment"],
    }),
    capturePaypalPayment: builder.mutation({
        query: (params) => ({
            url: `${ENDPOINTS.PAYMENT}${params}/capture`,
            method: 'POST'
          }),
    }),
    refundPaypalPayment: builder.mutation({
      query: ({ params, body }) => ({
        url: `${ENDPOINTS.PAYMENT}${params}/refund`,
        method: 'POST',
        body: body,
      }),
    }),
    refundWalletPayment: builder.mutation({
      query: (body) => ({
        url: `${ENDPOINTS.PAYMENT}/refundwithwallet`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCreatePaymentMutation,
  useRefundPaypalPaymentMutation,
  useRefundWalletPaymentMutation,
  useWithdrawalMethodMutation,
  useGetPaymentMethodsQuery,
  useCapturePaypalPaymentMutation,
  useWithdrawalMutation,
} = paymentApi;

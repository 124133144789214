import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formConfig } from "./DashboardTrainingPurchasedComponnents.config";
import {
  StyleBarSearch,
  Container,
  ContainerWrapper,
  RowStyle,
  SearchBar,
  StyleBarSearchSkills,
  Wrapper,
  StyleLineCount,
  ProductLabelsContainer,
  ViewLabelProducts,
  ViewLabelMyProducts,
  StyleProductsCount,
  NewProductButtonContainer,
  StyleCount1,
  StyleCount,
  TopContainer
} from "./DashboardTrainingPurchasedComponnents.style";
import { useGetTrainingsPurchasedQuery } from "../../../../redux/api/training/trainingApi";
import { getTokenFromLocalStorage } from "../../../../core/helpers/storage";
import { jwtDecode } from "jwt-decode";

const DashboardTrainingsPurchased = (props) => {

  const [animateDirection, setAnimateDirection] = useState("inRight");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const token =  getTokenFromLocalStorage();
  const decodeToken = token ? jwtDecode(token) : null;
  const role = token ? decodeToken.role : null;
  const id = token ? decodeToken.id : null;


  const {
    data: productsData,
    error: productsDataError,
    isLoading: productsDataLoading,
    refetch
  } = useGetTrainingsPurchasedQuery({
    id: id,
    role: role, 
    params: `&page=${currentPage}&limit=${recordsPerPage}${searchQuery}`
  }); 



  const handelAllProducts = () => {
     props.setPurchasedProducts(false);
  };

  const formMethods = useForm({});
  


  const handleNavigate = (token) => {
      window.location.href = `/videosTrainingPurchased/${token}` ;
    }


  return (

    <Wrapper>
      <ContainerWrapper className="container">
        <SearchBar>
          <FormProvider {...formMethods}>
              <StyleBarSearch>
              <GenericInput
                inputObject={{
                  ...formConfig.title,
                  label: "filterType"
                }}
                onChange={(e) => props.setType(e.target.value)}
              />
               </StyleBarSearch>
               <StyleBarSearchSkills>

              <GenericInput
                inputObject={{
                  ...formConfig.skills,
                  label: "filterType"
                }}
              />
              </StyleBarSearchSkills>

              {props.role === "CANDIDAT" && (
               
                 <NewProductButtonContainer onClick={handelAllProducts}>
                  {"Create a product"}
                  </NewProductButtonContainer>
              )}
          </FormProvider>
        </SearchBar>
        <TopContainer>
          <Container>
          <StyleLineCount>
            <ProductLabelsContainer>
              <ViewLabelProducts onClick={handelAllProducts} >Trainings</ViewLabelProducts>
              <ViewLabelMyProducts >Purchased Trainings</ViewLabelMyProducts>
              </ProductLabelsContainer>
               <StyleProductsCount>
                  {productsData && (
                   <StyleCount1>
                      <StyleCount>{productsData.pagination.totals}</StyleCount> Training found
                    </StyleCount1>
                  )}
             </StyleProductsCount>
            </StyleLineCount>
          </Container>
          <RowStyle spacing={3}>
            {props.data?.map((value) => (
              <FormationsCard
                key={value.id}
                formation={{
                  id: value.id,
                  title: value.title,
                  description: value.description,
                  image: value.image || value.thumbnail,
                  price: value.price,
                }}
                animateDirection={animateDirection}
                category={value.category}
                onClick={() => handleNavigate(value.display)}
              />
            ))}
          </RowStyle>
        </TopContainer>
      </ContainerWrapper>
    </Wrapper>

  );
};
export default DashboardTrainingsPurchased;

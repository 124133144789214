import SideBar from "components/SideBar/sideBar";
import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChapterDetails from 'components/ChapterDetails/ChapterDetails';
import ChaptersCard from 'components/ChaptersCard/ChaptersCard';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { BackButton, StyledApplicationDetailContainer, StyledApplicationDetailContentContainer } from './EditProductDetail.style';

const EditProductDetail = () => {
    const { id } = useParams();
    const [application, setApplication] = useState(null);
    const token = getAccessToken();
    const decodedToken = token ? jwtDecode(token) : null;

    useEffect(() => {
        // Here you would fetch the application data
        const fetchApplication = async () => {
            try {
                const response = await fetch(`/api/applications/${id}`);
                const data = await response.json();
                setApplication(data);
            } catch (error) {
                console.error("Failed to fetch application:", error);
            }
        };

        fetchApplication();
    }, [id]);

    const handleBackButton = () => {
        window.history.back();
    };

    if (!application) {
        return <p>Loading application details...</p>;
    }

    return (
        <StyledApplicationDetailContainer>
            <SideBar path={'/applications'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
            <StyledApplicationDetailContentContainer>
                <BackButton onClick={handleBackButton}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </BackButton>
                <div style={{ width: "100%", display: 'flex' }}>
                    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                        <VideoPlayer src={application?.videoUrl} />
                        <ChapterDetails />
                    </div>
                    <ChaptersCard chapters={application?.chapters} />
                </div>
            </StyledApplicationDetailContentContainer>
        </StyledApplicationDetailContainer>
    );
};

export default EditProductDetail; 
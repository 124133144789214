import SideBar from "components/SideBar/sideBar";
import { getTokenFromLocalStorage } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChapterDetails from 'components/ChapterDetails/ChapterDetails';
import ChaptersCard from 'components/ChaptersCard/ChaptersCard';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { BackButton, StyledApplicationDetailContainer, StyledApplicationDetailContentContainer } from './MyProductDetail.style';
import { useGetTrainingPurchasedQuery } from "../../../../redux/api/training/trainingApi";
import { getURL } from '../../../../redux/api/uploads/uploadSlice';
import { useDispatch } from "react-redux";

const MyProductDetail = () => {
    const { token } = useParams();
    const tokenUser = getTokenFromLocalStorage();
    const [loadingVideo , setLoadingVideo] = useState(false);
    const decodedToken = tokenUser ? jwtDecode(tokenUser) : null;
    const id = decodedToken ? decodedToken.id : null;
    const role = decodedToken ? decodedToken.role : null;
    const dispatch = useDispatch();

    const [videoDisplayUrl, setVideoDisplayUrl] = useState();
    const [chapter, setChapter] = useState();
    const [support, setSupport] = useState();


    const { data: product, error, isLoading } = useGetTrainingPurchasedQuery({ token, id, role });

    const handleBackButton = () => {
        window.history.back();
    };
    useEffect(() => {
        const fetchFirstVideoUrl = async () => {
            if (!isLoading && product) {
                try {
                    setLoadingVideo(true);
                    const chapters = product?.chapters ;
                    setChapter(chapters[1]);
                    const url = await dispatch(
                        getURL({
                            location: `products/applications/${product.id}/chapters/1/videos/1/`,
                        })
                    );  // Use unwrap() for handling errors more easily
                    if (url?.Contents && url.Contents.length > 0) {
                        setVideoDisplayUrl(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
                    } else {
                        console.error("No videos found in this chapter.");
                    }
                    const urlSupport = await dispatch(
                        getURL({
                            location: `/products/applications/${product?.id}/chapters/1/support/`,
                        })
                    );  // Use unwrap() for handling errors more easily
                    if (urlSupport?.Contents && urlSupport.Contents.length > 0) {
                        setSupport(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
                    } else {
                        console.error("No Support found in this chapter.");
                    }
                } catch (error) {
                    console.error("Error fetching video URL:", error);
                } finally {
                    setLoadingVideo(false);
                }
            }
        };
        fetchFirstVideoUrl();
   
    }, [product, isLoading]); 

    return (
        <StyledApplicationDetailContainer>
            <SideBar path={'/marketplace'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
            <StyledApplicationDetailContentContainer>
                <BackButton onClick={handleBackButton}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </BackButton>
                <div style={{ width: "100%", display: 'flex' }}>
                    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                        <VideoPlayer src={videoDisplayUrl} loading={loadingVideo} />
                        <ChapterDetails chapter={chapter} support={support}/>
                    </div>
                    <ChaptersCard data={product} setChapter={setChapter} setSupport={setSupport} setVideoDisplayUrl={setVideoDisplayUrl} />
                </div>
            </StyledApplicationDetailContentContainer>
        </StyledApplicationDetailContainer>
    );
};

export default MyProductDetail;
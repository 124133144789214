import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAccessToken } from "../core/helpers/storage";
import {
  useAddTrainingMutation,
  useUpdateTrainingMutation,
} from "../redux/api/training/trainingApi";
import { s3Upload } from "../redux/api/uploads/uploadSlice";
import { fileToBase64 } from "../utils/fileConvertion";

const convertJSONtoFile = (jsonFile) => {
  if (!jsonFile) return null;
  const content = new Blob([" ".repeat(jsonFile?.size)], {
    type: jsonFile?.type,
  });
  const file = new File([content], jsonFile?.name, {
    lastModified: jsonFile?.lastModified,
    type: jsonFile?.type,
    lastModifiedDate: jsonFile?.lastModifiedDate,
    webkitRelativePath: jsonFile?.webkitRelativePath,
    size: jsonFile?.size,
  });
  return file;
};

const useHandleSubmitTrainingCreation = (
  selectedElement,
  setSelectedElement,
  setConfirmShow,
  openModalRegister,
  setOpenModalRegister,
  isEdit,
  formMethods
) => {
  console.log(
    "formMethods.getValues() in useHandleSubmitTrainingCreation",
    formMethods.getValues()
  );
  const [saveTraining] = useAddTrainingMutation();
  const [updateTraining] = useUpdateTrainingMutation();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const token = getAccessToken();
  const decodeToken = token ? jwtDecode(token) : null;
  const navigate = useNavigate();

  const validateTrainingData = (data) => {
    const errors = [];
    if (!data.title?.trim()) errors.push("Title is required");
    if (!data.type?.trim()) errors.push("Type is required");
    if (!data.description?.trim()) errors.push("Description is required");
    if (!data.languages?.trim()) errors.push("Language is required");

    if (
      typeof data.price !== "number" ||
      isNaN(data.price) ||
      data.price <= 0
    ) {
      errors.push("Price must be a positive number");
    }

    if (!Array.isArray(data.category)) errors.push("Category must be an array");
    if (!Array.isArray(data.skills)) errors.push("Skills must be an array");

    if (typeof data.chapters !== "object" || data.chapters === null) {
      errors.push("Chapters must be an object");
    }

    return errors;
  };


const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
      if (!file || !(file instanceof Blob)) {
          return reject(new TypeError('Parameter must be a valid Blob or File.'));
      }

      const reader = new FileReader();
      reader.readAsDataURL(file); // This will read the file as a base64 string

      reader.onload = () => {
          // Resolve with the Base64 string
          resolve(reader.result); // Now reader.result contains the Base64 data URL
      };

      reader.onerror = (error) => {
          reject(error); // Reject if there's an error
      };
  });
};



  const handleFileUpload = async (formData, trainingId) => {
    console.log("formData in handleFileUpload", formData);

    try {
      const basePath = `products/videos_trainings/${trainingId}/`;

      // Upload training image if exists
        const imageResponse = await dispatch(
          s3Upload({
            file: formData.image.file,
            type: `/image/`,
            location: basePath,
            base64URL: formData.image.base64,

          })
        );

        // Upload chapter files
      if (formData.chapters && Array.isArray(formData.chapters)) {
        for (let i = 0; i < formData.chapters.length; i++) {
          const chapter = formData.chapters[i];
          const chapterNumber = i + 1;

          // Handle single support file
          if (chapter?.supportFile) {
            const base64Data = await convertFileToBase64(chapter.supportFile) ;
   
            const supportResponse = await dispatch(
              s3Upload({
                file: chapter.supportFile,
                type: `chapters/${chapterNumber}/support/`,
                location: basePath,
                base64URL: base64Data,
              })
            );
          }

          // Handle multiple videos per chapter
          if (chapter?.videos && Array.isArray(chapter.videos)) {
            for (let vIndex = 0; vIndex < chapter.videos.length; vIndex++) {
              const video = chapter.videos[vIndex];

              if (video?.videoFile || video?.file) {
                const vFile = video.videoFile || video.file;
              
                const videoResponse = await dispatch(
                  s3Upload({
                    file: vFile,
                    type: `chapters/${chapterNumber}/videos/${vIndex + 1}/`,
                    location: basePath,
                    base64URL: video.base64URL,
                    metadata: {
                      title: video.titleVideo  || "",
                    }
                  })
                );

                // Replace video entry with uploaded URL
                chapter.videos[vIndex] = {
                  titleVideo: video.titleVideo || "",
                  descriptionVideo: video.descriptionVideo || ""
                };
              }
            }
          }
        }
      }

      return formData;
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };


  const handleSubmit = async (orderID, sharesTotalPrice) => {
    setLoading(true);
    let response;
    let data = formMethods.getValues();
    try {
      // If no token, show register modal
      if (!token) {
        setOpenModalRegister(true);
        return { success: false, error: "Authentication required" };
      }

      const formData = {
        userId: decodeToken?.id,
        title: data.title || "",
        type: String(data.type_product || ""),
        description: data.description || "",
        languages: data.language || "en",
        category: Array.isArray(data.category) ? data.category : [],
        skills: Array.isArray(data.skills) ? data.skills : [],
        duration: data.duration ? Number(data.duration) : null,
        price: data.price ? Number(data.price) : 0,
        currency: "EUR",
        repo: data.repo || "",
        chapters: Array.isArray(data.chapters)
          ? data.chapters.reduce((obj, chapter, index) => {
              if (chapter && chapter.chapterTitle) {
                obj[`${index + 1}`] = {
                  title: chapter.chapterTitle,
                  description: chapter.chapterDescription || "",
                  duration: chapter.chapterDuration
                    ? Number(chapter.chapterDuration)
                    : null,
                };
              }
              return obj;
            }, {})
          : {},
        orderID: orderID,
        sharesTotalPrice: sharesTotalPrice,
      };

      // First create the training to get its ID
      const trainingResponse = await saveTraining(formData).unwrap();

      // Then upload files to S3 using the training ID
      if (trainingResponse?.id) {
        const uploadedFormData = await handleFileUpload(
          {
            ...formData,
            image: data.image,
            chapters: data.chapters, // Original chapter files (with videos as array and a single supportFile)
            support_courses: data.support_courses,
          },
          trainingResponse.id
        );

        // Update formData with new URLs
        formData.image = uploadedFormData.image;
        formData.chapters = uploadedFormData.chapters;
        formData.support_courses = uploadedFormData.support_courses;
      }

      // Validate the training data after upload
      const validationErrors = validateTrainingData(formData);
      if (validationErrors.length > 0) {
        console.error("Validation errors:", validationErrors);
        return { success: false, error: validationErrors.join(", ") };
      }

      try {
        if (isEdit && id) {
          response = await updateTraining({
            id,
            trainingData: formData,
          }).unwrap();
        } else {
          response = trainingResponse;
        }

        if (!response?.id) {
          throw new Error("No training ID received from API");
        }

        return {
          success: true,
          targetProductType: "TRAINING",
          targetProductId: response.id,
          invoicingDescription: formData.title || "Training Product",
          amount: formData.price,
          paymentRequired: true,
          url: "/trainer",
          type: "TRAINING",
        };
      } catch (error) {
        console.error("API Error:", error);
        return {
          success: false,
          targetProductType: "TRAINING",
          targetProductId: 0,
          invoicingDescription: "REFUND : error create training",
          type: "TRAINING",
        };
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      return {
        success: false,
        targetProductType: "TRAINING",
        targetProductId: 0,
        invoicingDescription: "REFUND : error create training",
        type: "TRAINING",
      };
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};

export default useHandleSubmitTrainingCreation;

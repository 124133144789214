import RegisterAccounting from "components/Authentification/modals/registerAccounting";
import businessIcon from "../../assets/images-webp/business.webp";
import individualIcon from "../../assets/images-webp/individual.webp";
import recruiterIcon from "../../assets/images-webp/recruiter.webp";
import AfterSignup from "../../components/AfterSignup/AfterSignup";
import { JoinUs } from "../../components/JoinUs/JoinUs";
import ModalForgotPassword from "../../components/ModalITgalaxy/ModalForgotPassword/ModalForgotPassword";
import ModalResetPassword from "../../components/ModalITgalaxy/ModalResetPassword/ModalResetPassword";
import ProductListEntreprise from "../../components/ProductListEntreprise/productListEntreprise";
import RecruitmentDash from "../../components/RecruitmentComponents/RecruitmentDash";
import ShareCandidat from "../../components/Sharing/shareCandidat";
import ShareJob from "../../components/Sharing/shareJobs";
import PrivacyPolicy from "../../components/TermsItGalaxy/PrivacyPolicy";
import TermsOfService from "../../components/TermsItGalaxy/TermsOfService";
import UserAgreement from "../../components/TermsItGalaxy/UserAgreement";
import VerifyEmail from "../../components/VerifyEmail/VerifyEmail";
import NotFound from "../../pages/404/notFound";
import ChatAccounting from "../../pages/Chat/ChatAccounting/ChatAccounting";
import ChatCandidat from "../../pages/Chat/ChatCandidat/ChatCandidat";
import ChatEntreprise from "../../pages/Chat/ChatEntreprise/ChatEntreprise";
import ChatRecruter from "../../pages/Chat/ChatRecruter/ChatRecruter";
import Company from "../../pages/Company/Company";
import DashboardHome from "../../pages/DashboardHomePage/DashboardHomePage";
import DashboardHomePageAccounting from "../../pages/DashboardHomePageAccounting/DashboardHomePageAccounting";
import DashboardITProducer from "../../pages/DashboardITProducer/DashboardITProducer";
import DashboardTraining from "../../pages/DashboardTraining/DashboardTraining";
import ItGalaxyContactor from "../../pages/ItGalaxyContactor/ItGalaxyContactor";
import MyWorkingFolders from "../../pages/MyWorkingFolders/MyWorkingFolders";
import Profil from "../../pages/Profil/Profil";
import Projects from "../../pages/projects/projects";
import Recruitment from "../../pages/Recruitment/Recruitment";
import Wallet from "../../pages/Wallet/Wallet";
import ControllerCandidat from "../../shared/guards/ControllerCandidat";
import ControllerCompany from "../../shared/guards/ControllerCompany";
import ControllerRecruter from "../../shared/guards/ControllerRecruter";
import {
  AuthRoutes,
  DashboardAccountingRoutes,
  DashboardCompanyRoutes,
  DashboardFreelancerRoutes,
  DashboardRecruterRoutes,
  RootRoute,
  TrainingRoutes,
  ITProducerRoutes, 
  ItGalaxyMarketplaceRoutes,
} from "../constants/routes.constants";
import ItGalaxyProductCreateServer from "../../components/CreateItGalaxyProductsComponnent/ItGalaxyProductCreateServer/ItGalaxyProductCreateServer";
import ItGalaxyProductPurchased from "../../pages/Marketplace/MarketplacePruchased/ItGalaxyProductPurchased/ItGalaxyProductPurchased";
import ItGalaxyProductDetail from "../../pages/Marketplace/ItGalaxyProductDetail/ItGalaxyProductDetail";
import ItGalaxyProductDatabase from "../../components/CreateItGalaxyProductsComponnent/ItGalaxyProductCreateDatabase/ItGalaxyProductCreateDatabase";
import Marketplace from "../../pages/Marketplace/Marketplace";
import MyProductEdit from "../../pages/DashboardITProducer/MyProductEdit";
import MyProductPurchased from "../../pages/Marketplace/MarketplacePruchased/MyProductPurchased/MyProductDetail";
import MyTrainingPurchased from "../../pages/DashboardTraining/MyTrainingPurchased/MyTrainingPurchased";
import TrainingDetail from "../../pages/DashboardTraining/TrainingDetail/TrainingDetail";


export const RoutesConfig = [
  {
    path: RootRoute,
    element: <DashboardHome />,
  },

  {
    path: AuthRoutes.verifyemail,
    element: <VerifyEmail />,
  },
  {
    path: AuthRoutes.resetpassword,
    element: <ModalResetPassword />,
  },

  {
    path: AuthRoutes.checkyouremailplz,
    element: <AfterSignup />,
  },

  {
    path: AuthRoutes.UserAgreement,
    element: <UserAgreement />,
  },

  {
    path: AuthRoutes.PrivacyPolicy,
    element: <PrivacyPolicy />,
  },

  {
    path: AuthRoutes.TermsOfService,
    element: <TermsOfService />,
  },

  {
    path: AuthRoutes.verifyemail,
    element: <VerifyEmail />,
  },

  // Freelancer Routes Config
  {
    path: DashboardFreelancerRoutes.projects,
    element: (
      <>
        <Projects />
      </>
    ),
  },

  // Company Routes Config
  {
    path: DashboardCompanyRoutes.chat,
    element: (
      <>
        <ControllerCompany />
        <ChatEntreprise />
      </>
    ),
  },

  {
    path: DashboardCompanyRoutes.profil,
    element: <Profil />,
  },
  {
    path: DashboardRecruterRoutes.itGalaxyContactor,
    element: <ItGalaxyContactor />,
  },

  {
    path: DashboardRecruterRoutes.itGalaxyRecruitment,
    element: <Recruitment />,
  },

  {
    path: DashboardRecruterRoutes.recruitmentDash,
    element: <RecruitmentDash />,
  },
  {
    path: DashboardRecruterRoutes.shareCandidat,
    element: <ShareCandidat />,
  },
  {
    path: DashboardRecruterRoutes.shareJob,
    element: <ShareJob />,
  },

  {
    path: DashboardCompanyRoutes.company,
    element: <Company />,
  },

  {
    path: DashboardFreelancerRoutes.chat,
    element: (
      <>
        <ControllerCandidat />
        <ChatCandidat />
      </>
    ),
  },
  {
    path: DashboardFreelancerRoutes.trainer,
    element: <DashboardITProducer />,
  },
  {
    path: DashboardFreelancerRoutes.myproduct,
    element: <MyProductEdit />,
  },
 
  {
    path: TrainingRoutes.videosTrainings,
    element: <DashboardTraining />,
  },

  {
    path: ItGalaxyMarketplaceRoutes.marketplace,
    element: <Marketplace />,

  },

  {
    path: DashboardCompanyRoutes.joinus,
    element: (
      <JoinUs
        items={[
          {
            id: 0,
            icon: businessIcon,
            label: "Business",
            description:
              "Tap into a large pool of skilled and experienced freelancers across various diciplines",
          },
          {
            id: 1,
            icon: individualIcon,
            label: "Individual",
            description:
              "Explore a broad range of well-defined, geniune projects that excite you and leverge your skillset",
          },
          {
            id: 2,
            icon: recruiterIcon,
            label: "Recruiter",
            description:
              "Connecting talented individuals with their dream careers",
          },
        ]}
        title={"Hello ✨ "}
        description={
          "Tell me what's on your mind or pick a suggestion.I have limitations and won't always get it right,but your feedback will help me to improve"
        }
      />
    ),
  },

  {
    path: AuthRoutes.ChangePassword,
    element: (
      <ModalForgotPassword
        show={true}
        onBack={"handleBacktoLogin"}
        onHide={"closeModal"}
      />
    ),
  },

  {
    path: DashboardFreelancerRoutes.wallet,
    element: <Wallet />,
  },

  {
    path: DashboardRecruterRoutes.chat,
    element: (
      <>
        <ControllerRecruter />
        <ChatRecruter />
      </>
    ),
  },

  {
    path: DashboardAccountingRoutes.chat,
    element: <ChatAccounting />,
  },
  {
    path: DashboardAccountingRoutes.signupAccounting,
    element: (
      <RegisterAccounting
        openModalRegister={true}
        setOpenModalRegister={true}
        switchBetweenModals={false}
        proxy={"dashboard"}
        back={false}
      />
    ),
  },
  {
    path: DashboardAccountingRoutes.homepageAccounting,
    element: <DashboardHomePageAccounting />,
  },
  {
    path: DashboardAccountingRoutes.myWorkingFolder,
    element: <MyWorkingFolders />,
  },
  
  {
    path : ItGalaxyMarketplaceRoutes.server,
    element: <ItGalaxyProductCreateServer />,
  },
  {
    path : ItGalaxyMarketplaceRoutes.database,
    element: <ItGalaxyProductDatabase />,
  },
  {
    path : ItGalaxyMarketplaceRoutes.github,
   // element: <ProductCreateReposGithubWithDash />,
  },
  {
    path : ItGalaxyMarketplaceRoutes.itgalaxyProductPurchased,
    element: <ItGalaxyProductPurchased />,
  },

  {
    path : ItGalaxyMarketplaceRoutes.productPurchased,
    element: <MyProductPurchased />,
  },
  {
    path : ItGalaxyMarketplaceRoutes.itgalaxyProductDetails,
    element: <ItGalaxyProductDetail />,
  },

  {
    path : TrainingRoutes.videosTrainings,
    element: <DashboardTraining />,
    
  },

  {
    path : TrainingRoutes.videosTraining,
    element: <TrainingDetail />,
    
  },

  {
    path : TrainingRoutes.videosTrainingPurchased,
    element: <MyTrainingPurchased />,
    
  },

  {
    path : ITProducerRoutes.itProducer,
    element: <DashboardITProducer />,
    
  },
  {
    path : ITProducerRoutes.editMyProduct,
    element: <MyProductEdit />,
    
  },
 
  {
    path: "*",
    element: <NotFound />,
  },


];

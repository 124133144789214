import { useState } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addDetailsTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice";
import { fileToBase64 } from "../../../../utils/fileConvertion";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import { StyledLabel } from "../TrainerForm/TraininerForm.style";
import { formConfig } from "./CreateTraining.constants";
import { ContentFormContainer } from "./CreateTraining.style";

export default function CreateTraining({ onValidate, onCancel }) {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
    control,
    getValues,
  } = useFormContext();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [skills, setSkills] = useState([]);
  const [image, setImage] = useState(null);

  const handleChange = (name, value) => {
    setValue(name, value, { shouldValidate: true });
  };

  const handleImageChange = (file) => {
    try {
      if (file instanceof File) {
        setImage(file);
        setValue("image", file, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger("image");
      } else {
        setImage(null);
        setValue("image", null, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger("image");
      }
    } catch (error) {
      console.error("Error in handleImageChange:", error);
    }
  };

  const handleChangeSkills = (selectedOptions) => {
    const selectedSkills = Array.isArray(selectedOptions)
      ? selectedOptions
      : [];
    setSkills(selectedSkills);
    setValue("skills", selectedSkills, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const validateForm = async () => {
    const currentValues = getValues();

    try {
      setValue("duration", currentValues.training);

      if (image instanceof File) {
        setValue("image", image, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        const imageValid = await trigger("image");
        if (!imageValid) return false;
      } else {
        setValue("image", null, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        await trigger("image");
        return false;
      }

      const isValid = await trigger(undefined, { shouldFocus: true });

      if (isValid) {
        const formData = {
          title: currentValues.title,
          description: currentValues.description,
          training: currentValues.training,
          skills: currentValues.skills,
          image: image,
        };
        let file = {};
        if (image instanceof File) {
          const base64Image = await fileToBase64(image);
          file.name = image.name;
    
          formData.image = {
            file: file,
            base64: base64Image,
          };
        }

        dispatch(addDetailsTraining(formData));
        onValidate();
        return true;
      } else {
        const firstErrorField = Object.keys(errors)[0];
        if (firstErrorField) {
          setValue(firstErrorField, getValues(firstErrorField), {
            shouldFocus: true,
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
        return false;
      }
    } catch (error) {
      console.error("Validation error:", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      const isValid = await validateForm();
      if (isValid) {
        onValidate();
      } else {
        await trigger();
      }
    } catch (error) {
      console.error("Form submission error:", error);
      await trigger();
    }
  };

  const getFieldError = (fieldName) => {
    return errors?.[fieldName]?.message || "";
  };

  return (
    <ContentFormContainer>
      <StyledLabel>1. Training title</StyledLabel>
      <GenericInput
        inputObject={formConfig.title}
        onChange={(e) => handleChange("title", e.target.value)}
        disabledForm={false}
        error={getFieldError("title")}
      />

      <StyledLabel>2. Training Description</StyledLabel>
      <GenericInput
        inputObject={formConfig.description}
        onChange={(e) => handleChange("description", e.target.value)}
        disabledForm={false}
        error={getFieldError("description")}
      />

      <StyledLabel>3. Training Duration (hours)</StyledLabel>
      <GenericInput
        inputObject={{
          ...formConfig.duration,
          fieldName: "duration",
        }}
        onChange={(e) => {
          handleChange("duration", e.target.value);
          handleChange("training", e.target.value);
        }}
        disabledForm={false}
        error={getFieldError("duration")}
      />

      <StyledLabel>4. Training Skills</StyledLabel>
      <GenericInput
        inputObject={formConfig.skills}
        onChange={handleChangeSkills}
        disabledForm={false}
        error={getFieldError("skills")}
      />

      <div>
        <StyledLabel>5. Training Image</StyledLabel>
        <GenericInput
          inputObject={{
            ...formConfig.image,
            fieldName: "image",
            config: {
              ...formConfig.image.config,
            },
          }}
          onChange={(file) => {
            handleImageChange(file);
          }}
          disabledForm={false}
        />
      </div>

      <div className="d-flex justify-content-end gap-3 mt-4">
        <Button variant="outline-primary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => validateForm()}>
          Confirm
        </Button>
      </div>
    </ContentFormContainer>
  );
}

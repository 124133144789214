import { validationSchemas } from "../validationSchemas";

export const validateStep = async (formMethods, currentStep) => {
  const currentSchema = validationSchemas[currentStep];
  if (!currentSchema) {
    return true;
  }

  const isValid = await formMethods.trigger();
  return isValid;
};

export const validateChapterFields = async (formMethods, chapterIndex) => {
  const isValid = await formMethods.trigger([
    `chapters.${chapterIndex}.chapterTitle`,
    `chapters.${chapterIndex}.chapterDuration`
  ]);
  return isValid;
};

export const validateTrainingType = async (formMethods) => {
  const isValid = await formMethods.trigger('productType');
  return isValid;
};

import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetTrainingByTokenQuery } from "../../../../redux/api/training/trainingApi.js";
import { addPriceTraining } from "../../../../redux/slice/TrainingCreation/trainingCreationSlice.js";
import GenericInput from "../../../Inputs/GenericInput/GenericInput.jsx";
import { formConfig } from "./AddPrice.constants";
import { ContentFormContainer, StyledLabel } from "./AddPrice.style.js";

export default function AddPrice({ onValidate, onCancel }) {
  const { token } = useParams();
  const training = useSelector((state) => state.trainingCreation);
  const dispatch = useDispatch();
  const formMethods = useFormContext({
    mode: "onChange",
    shouldFocusError: true,
  });
  const { data, error, isLoading, refetch } = useGetTrainingByTokenQuery(token);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    formMethods.setValue(name, value);
    setInputValue(value);
  };
  const handleSubmit = (e) => {
    onValidate();
    dispatch(
      addPriceTraining({
        price: formMethods.getValues("price"),
        currency: formMethods.getValues("currency"),
      })
    );
  };
  useEffect(() => {
    if (token) {
      setInputValue(data?.data?.price);
    }
  }, []);

  return (
    <>
      <ContentFormContainer>
       <StyledLabel>1. Add Price for your Product (euros)</StyledLabel>
        <GenericInput
          inputObject={{
            ...formConfig.price,
            defaultValue: data?.data?.price,
          }}
          onChange={(e) => handleChange(e)}
          disabledForm={false}
        />
        <div style={{ float: "right", marginTop: "10%" }}>
          <Button variant="light" onClick={onCancel}>
            Annuler
          </Button>
          <Button
            disabled={!inputValue}
            style={{ marginLeft: "15px" }}
            variant="primary"
            onClick={handleSubmit}
          >
            Valider
          </Button>
        </div>
      </ContentFormContainer>
    </>
  );
}

import SideBar from "components/SideBar/sideBar";
import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { useParams } from "react-router-dom";
import CourseIntro from '../../../components/CourseIntro/CourseIntro';
import CourseModules from '../../../components/CourseModules/CourseModules';
import CourseReviews from '../../../components/CourseReviews/CourseReviews';
import { useGetTrainingByTokenQuery } from "../../../redux/api/training/trainingApi";
import { BackButton, StyledApplicationDetailContainer, StyledApplicationDetailContentContainer , MessageContainer , Spinner} from './TrainingDetail.style';
import { getTokenFromLocalStorage } from "../../../core/helpers/storage";

const TrainingDetail = () => {
  const { token } = useParams();
  const tokenUser = getTokenFromLocalStorage();
  const decodedToken = tokenUser ? jwtDecode(tokenUser) : null;
  console.log("token", token);
  // Use the RTK Query hook to fetch the training by ID
  const { data: trainings, error, isLoading } = useGetTrainingByTokenQuery({ token, type: "VIDEOSTRAINING" });

  const handleBackButton = () => {
    window.history.back();
};


return (
<div style={{ display: "flex", overflowY: 'hidden', height: '100vh', overflowX: 'hidden' }} >
  <SideBar path={'/videosTrainings'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />

  {isLoading && (
    <MessageContainer>
      <Spinner animation="border" role="status" style={{ marginBottom: "10px" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <span>Loading...</span>
    </MessageContainer>
  )}

  {/* Error State */}
  {error && (
    <MessageContainer>
      <span>Error loading product data. Please try again later.</span>
    </MessageContainer>
  )}

{!isLoading && !error && (!trainings || !trainings.data) && (
    <MessageContainer>
      <span>No product data available.</span>
    </MessageContainer>
  )}
  {!isLoading && trainings && trainings.data && (
    <StyledApplicationDetailContainer>
        <StyledApplicationDetailContentContainer>
            <BackButton onClick={handleBackButton}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </BackButton>
            <CourseIntro data={trainings.data} />
            <CourseModules data={trainings.data} />
            <CourseReviews data={trainings.data} />
        </StyledApplicationDetailContentContainer>
    </StyledApplicationDetailContainer>
     )}
 </div>   
);
};

export default TrainingDetail;

import { useCallback } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import GenericInput from "../../../Inputs/GenericInput/GenericInput";
import AddFolderApplication from "../AddFolderApplication/AddFolderApplication";
import { ChapterTitle, StyledLabel } from "../TrainerForm/TraininerForm.style";
import VideoUpload from "../VideoUpload/VideoUpload";
import { formConfig } from "./ChapterContent.constants";
import { ContentFormContainer } from "./ChapterContent.style";

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-top: 20px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &.secondary {
    background-color: #6c757d;
    &:hover {
      background-color: #5a6268;
    }
  }
`;

const ChapterContent = ({
  index,
  onUpdate,
  onValidate,
  onCancel,
  onConfirmChapter,
}) => {
  const { control, watch, getValues, setValue } = useFormContext();

  // Helper to update fields
  const handleFieldUpdate = useCallback(
    (fieldName, value) => {
      const actualValue = value?.target?.value ?? value;
      const fieldPath = `chapters.${index}.${fieldName}`;

      setValue(fieldPath, actualValue, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      const chapterData = getValues(`chapters.${index}`);
      onUpdate?.(chapterData);
    },
    [index, setValue, getValues, onUpdate]
  );

  return (
    <>
      <ChapterTitle>Chapter {index + 1}</ChapterTitle>
      <ContentFormContainer>
        <StyledLabel>1. Chapter Title</StyledLabel>
        <GenericInput
          control={control}
          inputObject={{
            ...formConfig.chapterTitle,
            fieldName: `chapters.${index}.chapterTitle`,
          }}
          value={watch(`chapters.${index}.chapterTitle`)}
          onChange={(e) => handleFieldUpdate("chapterTitle", e)}
        />

        <StyledLabel>2. Chapter Description</StyledLabel>
        <GenericInput
          control={control}
          inputObject={{
            ...formConfig.chapterDescription,
            fieldName: `chapters.${index}.chapterDescription`,
          }}
          value={watch(`chapters.${index}.chapterDescription`)}
          onChange={(e) => handleFieldUpdate("chapterDescription", e)}
        />

        <StyledLabel>3. Chapter Duration (hours)</StyledLabel>
        <GenericInput
          control={control}
          inputObject={{
            ...formConfig.chapterDuration,
            fieldName: `chapters.${index}.chapterDuration`,
          }}
          value={watch(`chapters.${index}.chapterDuration`)}
          onChange={(e) => handleFieldUpdate("chapterDuration", e)}
        />

        <StyledLabel>4. Chapter Videos</StyledLabel>
        {/* VideoUpload now should handle an array of videos 
            and return the array of videos to onChange callback */}
        <VideoUpload
          inputObject={{
            fieldName: `chapters.${index}.videos`,
          }}
          value={watch(`chapters.${index}.videos`)}
          onChange={(videosArray) => handleFieldUpdate("videos", videosArray)}
        />

        <StyledLabel>5. Chapter Support File</StyledLabel>
        {/* AddFolderApplication now should handle a single file 
            and return that single file object to onChange callback */}
        <AddFolderApplication
          inputObject={{
            fieldName: `chapters.${index}.supportFile`,
          }}
          disabled={false}
          value={watch(`chapters.${index}.supportFile`)}
          onChange={(file) => handleFieldUpdate("supportFile", file)}
        />

        <ButtonContainer>
          <StyledButton className="secondary" onClick={onCancel}>
            Back
          </StyledButton>
          <StyledButton
            onClick={() => {
              const chapterData = getValues(`chapters.${index}`);
              onConfirmChapter?.(chapterData);
            }}
          >
            Confirm & Next Chapter
          </StyledButton>
        </ButtonContainer>
      </ContentFormContainer>
    </>
  );
};

export default ChapterContent;

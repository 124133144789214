import styled from "styled-components";
import { Button } from "react-bootstrap";

export const AddBankAccountButton = styled.button`
  background-color: black;
  color: white;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  grap: 15px;

  &:hover {
      background-color: green;
  }
`;

export const TitleStyle = styled.div`
  font-weight: bold;
  font-size: 30px;
`;
export const StyledFormText = styled.div`
font-family : Inter ;
padding-top: 30px;
padding-bottom: 20px;
right: 0;
font-size: 16px;
font-weight: 600;
line-height: 30.86px;
`;

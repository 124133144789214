import {  useEffect, useState } from "react";
import styled from "styled-components";
import { getTitleVideo, getURL } from '../../redux/api/uploads/uploadSlice';
import { useDispatch } from "react-redux";

const ContainerCard = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 5px 5px 0px 0px black;
`;

const CardContainer = styled.div`
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
`;

const ChapterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 5px;
`;

const ChapterTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

const Duration = styled.span`
  font-size: 0.9rem;
  color: #666;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
`;

const ModuleList = styled.div`
  padding-left: 15px;
  display: ${(props) => (props.expanded ? "block" : "none")};
`;

const ModuleItem = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: ${(props) => (props.completed ? "#666" : "#000")};
  border: none;
  background: none;
`;

const ModuleCheckbox = styled.input`
  margin-right: 10px;
`;
const ChaptersCard = (props) => {
  const dispatch = useDispatch();
  const [expandedModule, setExpandedModule] = useState(null);
  const [modules, setModules] = useState({});

  // Ensure props.data is defined before accessing its properties
  const chapters = props.data?.chapters || {}; // Fallback to an empty object
  const length = Object.keys(chapters).length;

  const toggleChapter = (chapterId) => {
    setExpandedModule(expandedModule === chapterId ? null : chapterId);
    props.setChapter(chapters[chapterId]);
    props.setSupport(`${process.env.REACT_APP_CDN_ITGALAXY}/products/applications/${props.data.id}/chapters/${chapterId}/support/`);

  };

  const videoSelected = (path) => {
    props.setVideoDisplayUrl(`${process.env.REACT_APP_CDN_ITGALAXY}/${path}`);
  };



  useEffect(() => {
    const fetchModules = async () => {
      const newModules = {}; // Create a new modules object

      // Iterating through chapter IDs to get counts
      for (const chapterId of Object.keys(chapters)) {
        try {
          const chapterModules = await setUpModules(chapterId);
          // Map over the chapter modules to get titles
          newModules[chapterId] = await Promise.all(chapterModules.map(async (obj, index) => {
            try {
              const tags = await getTitleVideo(obj.Key);
              const title = tags || `Videos ${index}`; // Use optional chaining for safety
              return { ...obj, Title: title }; // Add the title property
            } catch (err) {
              console.error(`Error fetching tags for ${obj.Key}:`, err);
              return { ...obj, Title: `Videos ${index}` }; // Fallback title on error
            }
          }));
        } catch (error) {
          console.error(`Error setting up modules for chapter ${chapterId}:`, error);
        }
      }

      setModules(newModules); 
    };

    if (length > 0) {
      fetchModules();
    }
  }, [chapters, dispatch, length]); // Dependencies array controls effect execution

  const setUpModules = async (chapterId) => {
    const pathVideos = `products/applications/${props.data?.id}/chapters/${chapterId}/videos/`;
    try {
      const listModules = await dispatch(getURL({ location: pathVideos }));
      return listModules.Contents || []; // Make sure to return an empty array if no contents are found
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return an empty array on error
    }
  };

  // Render logic
  return (
    <ContainerCard>
      {length === 0 ? (
        <p>No chapters available</p> // Show a message if no chapters are found
      ) : (
        Object.keys(chapters).map((chapterId) => {
          const chapter = chapters[chapterId];
  
          return (
            <CardContainer key={chapterId || 1}>
              <ChapterHeader onClick={() => toggleChapter(chapterId)}>
                <div>
                  <ChapterTitle>{chapter.title || "Untitled Chapter"}</ChapterTitle>
                </div>
                <ArrowButton>
                  {expandedModule === chapterId ? '∧' : '∨'}
                </ArrowButton>
              </ChapterHeader>
              <ModuleList expanded={expandedModule === chapterId}>
                {modules[chapterId] && modules[chapterId].length > 0 ? ( 
                  modules[chapterId].map((module) => (
                    <ModuleItem key={module.id} onClick={() => videoSelected(module.Key)} >
                      {module.Title} 
                    </ModuleItem>
                  ))
                ) : (
                  <ModuleItem>No modules available</ModuleItem> 
                )}
              </ModuleList>
            </CardContainer>
          );
        })
      )}
    </ContainerCard>
  );
}
export default ChaptersCard;
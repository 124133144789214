import { CDBSidebarMenuItem } from "cdbreact";
import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formConfig } from "./DashboardItGalaxyMarketplacePurchased.config";
import {
  StyleBarSearch,
  Container,
  ContainerWrapper,
  RowStyle,
  SearchBar,
  StyleBarSearchSkills,
  Wrapper,
  StyleLineCount,
  ProductLabelsContainer,
  ViewLabelProducts,
  ViewLabelMyProducts,
  StyleProductsCount,
  NewProductButtonContainer,
  StyleCount1,
  StyleCount,
  TopContainer
} from "./DashboardItGalaxyMarketplacePurchased.style";
import { useGetMarketplaceItgalaxysPurchasedQuery } from "../../../redux/api/marketplaceItgalaxy/marketplaceItgalaxyApi";
import { getTokenFromLocalStorage } from "../../../core/helpers/storage";
import { jwtDecode } from "jwt-decode";

const DashboardItGalaxyMarketplacePurchased = (props) => {

  const [animateDirection, setAnimateDirection] = useState("inRight");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const token =  getTokenFromLocalStorage();
  const decodeToken = token ? jwtDecode(token) : null;
  const role = token ? decodeToken.role : null;
  const id = token ? decodeToken.id : null;
  const [purchasedProductsData, setPurchasedProductsData] = useState();


  const {
    data: productsData,
    error: productsDataError,
    isLoading: productsDataLoading,
    refetch
  } = useGetMarketplaceItgalaxysPurchasedQuery({
   id: id,
   role: role, 
   params: `&page=${currentPage}&limit=${recordsPerPage}${searchQuery}`,
}); 

useEffect(() => {
  if (!productsDataLoading && !productsDataError && productsData) {
    const marketplaceProducts = productsData.data.marketplaceProducts || [];
    const applicationProducts = productsData.data.applicationProducts || [];
    setPurchasedProductsData([...marketplaceProducts, ...applicationProducts]); // Spread syntax combines both arrays
  }
}, [productsData, productsDataLoading, productsDataError]);


  const handelAllProducts = () => {
     props.setPurchasedProducts(false);
  };

  const formMethods = useForm({});
  const type = formMethods.watch("type");
  
  useEffect(() => {
    if (type && type !== props.type) {
      props.setType(type);
    } else if (props.type) {
      formMethods.setValue("type", props.type);
    }
  }, [type]);

  const handleNavigate = (token , type)  => {

    if(type === 'SOLDEDPRODUCT') {

      window.location.href = `/productPurchased/${token}` ;

    } else if (type === 'MARKETPLACEPRODUCT') {
      window.location.href = `/itgalaxyProductPurchased/${token}` ;
    }
  };

  if (productsDataLoading) {
    return <div>Loading...</div>;
  }

  if (productsDataError) {
    return <div>Error loading data: {productsDataError.message}</div>;
  }


  return (

    <Wrapper>
      <ContainerWrapper className="container">
        <SearchBar>
          <FormProvider {...formMethods}>
              <StyleBarSearch>
              <GenericInput
                inputObject={{
                  ...formConfig.title,
                  label: "filterType"
                }}
                onChange={(e) => props.setType(e.target.value)}
              />
               </StyleBarSearch>
               <StyleBarSearchSkills>

              <GenericInput
                inputObject={{
                  ...formConfig.skills,
                  label: "filterType"
                }}
              />
              </StyleBarSearchSkills>

              {props.role === "CANDIDAT" && (    
                 <NewProductButtonContainer onClick={handelAllProducts}>
                  {"Create a product"}
                  </NewProductButtonContainer>
              )}
          </FormProvider>
        </SearchBar>
        <TopContainer>
          <Container>
          <StyleLineCount>
            <ProductLabelsContainer>
              <ViewLabelProducts onClick={handelAllProducts} >Marketplace Products</ViewLabelProducts>
              <ViewLabelMyProducts >Purchased Products</ViewLabelMyProducts>
              </ProductLabelsContainer>
               <StyleProductsCount>
                  {productsData && (
                   <StyleCount1>
                      <StyleCount>{productsData.pagination.totals}</StyleCount> products found
                    </StyleCount1>
                  )}
             </StyleProductsCount>
            </StyleLineCount>
          </Container>
          <RowStyle spacing={3}>
            {purchasedProductsData?.map((value) => (
              value.type === 'SOLDEDPRODUCT' ? ( 
                <FormationsCard
                  key={value.product.id}  
                  formation={{
                    id: value.product.id,
                    title: value.product.title,
                    description: value.product.description,
                    skills: value.product.skills,
                    price: value.product.price,
                  }}
                  animateDirection={animateDirection}
                  category={type}
                  onClick={() => handleNavigate(value.product.display,value.type)}
                />
              ) : ( 
                <FormationsCard
                  key={value.product.id} 
                  formation={{
                    id: value.product.id,
                    title: value.product.title,
                    description: value.product.description,
                    skills: value.product.skills,
                    price: value.price,
                  }}
                  animateDirection={animateDirection}
                  category={type}
                  onClick={() => handleNavigate(value.product.display,value.type)}
                />
              )
            ))}
          </RowStyle>
        </TopContainer>
      </ContainerWrapper>
    </Wrapper>

  );
};
export default DashboardItGalaxyMarketplacePurchased;

import { jwtDecode } from "jwt-decode";
import VideosTrainingsComponnents from "components/DashboardTrainingComponnents/TrainingsComponnents/VideosTrainingsComponnents/VideosTrainingsComponnents.jsx";
import SideBar from "components/SideBar/sideBar";
import { getTokenFromLocalStorage } from "../../core/helpers/storage.js";


const DashboardTraining = () => {
  const token = getTokenFromLocalStorage();
  const decodedToken = token ? jwtDecode(token) : null;
  const role = decodedToken ? decodedToken.role : null;
  const id = decodedToken ? decodedToken.id : null;


  return (
    <div style={{ display: "flex", overflowY: 'hidden', height: '100vh', overflowX: 'hidden' }} >
      <SideBar path={'/videosTrainings'} isLoggedIn={true} role={decodedToken?.role} id={decodedToken?.id} />
      <VideosTrainingsComponnents role={role} id={id} />
    </div>
  );
};

export default DashboardTraining;

// CoursePreviewCard.jsx
import { CardContainer, CardImage, CardSubtitle, CardTitle, CourseModulesLink, DurationBadge, ImageWrapper, PlayButton, StyledHorizontalLine } from './CoursePreviewCard.style';
const CoursePreviewCard = ({ imageSrc, duration, onModulesClick }) => {
  return (
    <CardContainer>
      <ImageWrapper>
        <CardImage src={imageSrc} alt="Course details" />
        <DurationBadge>{duration} hours</DurationBadge>
      </ImageWrapper>
    </CardContainer>
  );
};

export default CoursePreviewCard;

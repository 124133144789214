import { Button } from "react-bootstrap";
import styled from "styled-components";
import { StyledCircle, StyledStep, StyledStepTitle, StyledStepperCard, StyledTitleAndSubtitleContainer } from "../TraininerForm.style";

export const StepperNavigation = ({
    items,
    selectedElement,
    currentSubModule,
    subModules,
    onStepClick,
    onAddChapter
}) => {
    return (
        <StyledStepperCard>
            {items.map((item) => (
                <div key={item.id}>
                    <StyledStep
                        active={selectedElement === item.id && !currentSubModule}
                        checked={selectedElement > item.id || (selectedElement === item.id && currentSubModule)}
                        onClick={() => onStepClick(item.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <StyledCircle>{item.id}</StyledCircle>
                        <StyledTitleAndSubtitleContainer>
                            <StyledStepTitle>{item.title}</StyledStepTitle>
                        </StyledTitleAndSubtitleContainer>
                    </StyledStep>

                    {item.id === 3 && (
                        <SubModuleContainer>
                            {subModules.map((subModule) => (
                                <StyledStep
                                    key={subModule.id}
                                    style={{
                                        marginLeft: '20px',
                                        cursor: 'pointer',
                                        backgroundColor: currentSubModule?.id === subModule.id ? '#e8f0fe' : 'transparent'
                                    }}
                                    isSubModule
                                    active={currentSubModule?.id === subModule.id}
                                    onClick={() => onStepClick(3, subModule.id)}
                                >
                                    <StyledCircle isSubModule>
                                        {subModule.index + 1}
                                    </StyledCircle>
                                    <StyledTitleAndSubtitleContainer>
                                        <StyledStepTitle>{subModule.title}</StyledStepTitle>
                                    </StyledTitleAndSubtitleContainer>
                                </StyledStep>
                            ))}
                            {selectedElement === 3 && (
                                <AddChapterButton onClick={onAddChapter}>
                                    + Add Chapter
                                </AddChapterButton>
                            )}
                        </SubModuleContainer>
                    )}
                </div>
            ))}
        </StyledStepperCard>
    );
};

const SubModuleContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddChapterButton = styled(Button)`
  margin-left: 20px;
  margin-top: 10px;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;